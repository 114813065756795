import './grid-reporte.css';
import { Grid, GridColumn, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from 'react-materialize';
import React from 'react';
import imageNotAvailable from './../../../assets/images/imagenotavailable.png';

class getImage extends React.Component{

  render()
  {
    return <td>
      <img 
        width="70px" 
        title={this.props.dataItem['id']} 
        src={this.formImage(this.props.dataItem)}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.onError = null;
          currentTarget.src = imageNotAvailable;
        }}
      />
      <br/>
      <a href={this.props.dataItem['noDelete']} target="_blank">Ver Imagen</a>
    </td>;
  }

  formImage = (data) => 
  {
    return data.fullImage;
  }
}

class getPantone extends React.Component{

  render()
  {
    return <td>
      {this.createPantone(this.props.dataItem)}
    </td>;
  }

  createPantone = (data) => 
  {
    //console.log(data.pantones.filter( (i) => i !== "" && i != undefined ).map( (i) =>  ( i ) ? i.id + " (" + i.hexadecimal + ")" : "" ).join(', '));
    return ( data.pantones.filter( (i) => i !== "" && i != undefined ).map( (i) =>  ( i ) ? i.id + " (" + i.hexadecimal + ")" : "" ).join(', \n') );
  }
}

class GridReporteProducts extends React.Component {
  _export;

  export = () => {
    this._export.save(this.props.data);
  };

  componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        <ExcelExport ref={(exporter) => (this._export = exporter)} fileName="Products.xlsx">
          <Grid
            data={this.props.data}
            resizable
          >
            <GridNoRecords>No se encontraron registros</GridNoRecords>
            <GridToolbar>
              <div className="toolbar">
                <Button node="button" onClick={this.export}>
                  <span className="k-icon k-i-excel"></span> Exportar a Excel
                </Button>
                <section>
                  {this.props.filtros === 0 ? (
                    <p>
                      Ultimos <strong>{this.props.data.length}</strong>{' '}
                      registros
                    </p>
                  ) : (
                    <p>
                      Registros encontrados:{' '}
                      <strong>{this.props.data.length}</strong>
                    </p>
                  )}
                </section>
              </div>
            </GridToolbar>
            <GridColumn
              field="article"
              title="Nombre"
            ></GridColumn>
            <GridColumn
              field="categoryField"
              title="Familia"
            ></GridColumn>
            <GridColumn
              field="brandField"
              title="Marca"
            ></GridColumn>
            <GridColumn
              field="genderField"
              title="Genero"
            ></GridColumn>
            <GridColumn
              field="styleField"
              title="Estilo"
            ></GridColumn>
            <GridColumn
              field="modelField"
              title="Modelo"
            ></GridColumn>
            <GridColumn
              field="id"
              title="Código"
            ></GridColumn>
            <GridColumn
              field="fullImage"
              title="Imagen"
              cell={getImage}
            ></GridColumn>
            <GridColumn
              field="colorLabel"
              title="Color"
            ></GridColumn>
            <GridColumn
              field="colorPantone"
              cell={getPantone}
              title="Pantone"
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }
}
export default GridReporteProducts;
