import { formatResponse, get, post, getApiEndpoint } from '../helpers/index.js';

class InventoryService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'inventories';
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '' },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id,
      },
    });
    return formatResponse(getResponse);
  }

  async getExistencia (cveProducto, cseProducto, color, sucursal, new_med ) {
    let data = {
      cve_prod: cveProducto,
      cse_prod: cseProducto,
      lugar: sucursal,
    };
    if (new_med) {
      data.new_med = new_med;
    }

    if (color !== 'SC') {
      data.color = color;
    }

    try {
      let response = await post(`${this.apiEndpoint}existencia/`, { data });

      // removiendo espacios en propiedad lugar
      response.data.data.existencias = response.data.data.existencias.map(
        (response) => ({
          ...response,
          cse_prod: response.cse_prod.trim(' '),
          cve_prod: response.cve_prod.trim(' '),
          lugar: response.lugar.trim(' '),
        })
      );
      return response.data.data;
    } catch (error) {
      return { status: 200, existencias: 0 };
    }
  };
}

export { InventoryService as default };
