import GridReporte from './grid-cancel-report';
import Modal from '../modals/modal';
import React from 'react';
import ReporteFiltros from './modal-cancel-filtros';
import { ErpConnect as qad } from '../../../services';
import Swal from 'sweetalert2';

// services


class ModalCancel extends React.Component {
  
  state = {
    dataGrid: [],
    loading: false,
  };

  handleBuscar = async () => {
    // ejecucion de busqueda
    this.setState({
      loading: true,
    });
    let datos = [];
    if (true) {
      try 
      {
        datos = await qad.catalog.salesOrder.listForDelete();
      } catch (e) {
        console.log(e);
      }
    } 
    this.setState({
      dataGrid: datos,
      loading: false,
    });
  };

  handleToDelete = async () => {
    // ejecucion de busqueda
    this.setState({
      loading: true,
    });
    const statusChangedArray = this.state.dataGrid.filter((item)=> item["haveChanged"] && item["haveChanged"] );
    if ( statusChangedArray.length > 0 )
    {
      await qad.catalog.salesOrder.notToDelete( { 
        "data" : statusChangedArray.map((item) => { 
          return{ "salesOrderId" : item.salesOrderId, "notDelete" : item.noDelete } 
        } ) } );
    }
    else
    {
      Swal.fire({
          title: 'No se selecciono ningúna Remision',
          html: 'Nada que Guardar'
        })
    }
    this.setState({
      loading: false,
    });
  };

  

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalReporte2IsOpen}
          onClose={() => {
            this.props.onClose();
          }}
        >
          <ReporteFiltros
            onBuscar={this.handleBuscar}
            toDelete={this.handleToDelete}
            disableToDelete={this.state.loading || this.state.dataGrid.length < 1}
          />
          <br />
          {this.state.loading ? (
            <div className="loader"></div>
          ) : (
            <GridReporte
              data={this.state.dataGrid}
              onBuscar={this.handleBuscar}
            />
            
          )}
          
          
    
        </Modal>
      </React.Fragment>
    );
  }
}
export default ModalCancel;
