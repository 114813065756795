import { formatResponse, get, post, put, getApiEndpoint, getWebsiteEndpoint, transformApiUrl, getResultResponse, printError } from '../helpers/index.js';
import axios from 'axios';

class WebsiteService {

  constructor(client) {
    /*this.client = axios.create({
      baseURL: getWebsiteEndpoint(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    });
    this.apiEndpoint = getWebsiteEndpoint()+'ajax';*/
  }

  async getGeography()
  {
    console.log("getGeography");
    const getResponse = await fetch("/api/geography")
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getClients(active = true)
  {
    console.log("getClients");
    const getResponse = await fetch("/api/clients?" + new URLSearchParams({
        active: active
      }).toString())
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getProducts(active = true)
  {
    console.log("getProducts");
    const getResponse = await fetch("/api/products")
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getColors(active = true)
  {
    console.log("getColors");
    const getResponse = await fetch("/api/colors")
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getParcels(active = true)
  {
    console.log("getParcels");
    const getResponse = await fetch("/api/parcels")
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getLocations(active = true)
  {
    console.log("getLocations");
    const getResponse = await fetch("/api/locations")
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getPriceLists(active = true)
  {
    console.log("getPriceLists");
    const getResponse = await fetch("/api/pricelists")
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  async getNeighborhoods( cityId )
  {
    const getResponse = await fetch("/api/cities?" + new URLSearchParams({
        cityId: cityId
      }).toString())
    .then( (data) => data.json() )
    .catch( (error) => error )
    return this.fetchResponse(getResponse);
  }

  async getCheckinsByStatus()
  {
    const getResponse = await fetch("/api/checkin")
    .then( (data) => data.json() )
    .catch( (error) => error )
    return this.fetchResponse(getResponse);
  }

  async changeCheckinStatus(data)
  {
    const getResponse = await fetch("/checkin/"+data.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    .then( (data) => data.json() )
    .catch( (error) => error )
    return this.fetchResponse(getResponse);
  }

  async getStatuses()
  {
    const getResponse = await fetch("/api/status")
    .then( (data) => data.json() )
    .catch( (error) => error )
    return this.fetchResponse(getResponse);
  }

  async sendMail(email, salesOrderId)
  {
    const getResponse = await fetch("/api/sendmail?" + new URLSearchParams({
        email: email,
        id: salesOrderId
      }).toString()
    )
    .then( (data) => data.json() )
    .catch( (error) => error );
    return this.fetchResponse(getResponse);
  }

  fetchResponse(response)
  {
    try
    {
      const result = response["result"][0]["data"];
      if ( response["result"][0]["status"].toLowerCase() === "error" )
      {
        printError(JSON.stringify(response), "Api Error: website fetch");
      }
      return result;
    }
    catch ( error )
    {
      try {
        const result = response["read"][0]["data"];
        if ( response["read"][0]["status"].toLowerCase() === "error" )
        {
          printError(JSON.stringify(response), "Api Error: website fetch");
        }
        return result;
      }
      catch (err)
      {
        console.log(err);
        printError(JSON.stringify(err), "Api Error: website")
        return [];
      }
    }
  }

}

export { WebsiteService as default };
