import { Button, Col, Container, Row, TextInput, Switch } from 'react-materialize';

import LoadingView from '../loading/loading.view';
import M from 'materialize-css';
import Modal from './modal';
import ModalInvoiceItem from './modal-invoice.view';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad } from '../../../services';
import InvoiceReprintView from './../modal-reporte/pedido-reprint';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import printJS from 'print-js';
import AsyncSelect from 'react-select/async';
import { useInsertionEffect } from 'react';



// servicios

class ModalOC extends React.Component {
  state = {
    loading: false,
    numInvoice: '',
    itemsInvoice: null,
    btnBuscarEstado: false,
    lastSearch: '',
    brand: null,
    patterns: [],
    loadingPatterns: false,
    pattern: null,
    withProviderCodeRack: false,
    withProviderCode: true,
    products: {
      colorKeys : [],
      colorItems : []
    },
    header: {},
    date: null,
    excelExport: null
  };
  user = JSON.parse(localStorage.getItem('user'));

  _export;

  async componentDidMount() {
    this.setState({
      numInvoice: '',
    });
  }

  handleChange = (e) => {
    this.setState({
      numInvoice: e.target.value,
    });
  };

  handleSearchInvoice = async () => {
    let numInvoice = this.state.numInvoice.trim();
    if (this.state.lastSearch === numInvoice) 
    {
      M.toast({
        html: 'Es la misma Factura',
        classes: 'message-success',
      });
      return;
    }
    this.setState({
      loading: true,
      lastSearch: numInvoice
    });
    try {
      let result = await qad.catalog.salesOrder.findInvoiceById(numInvoice);
      console.log(result);
      this.setState({
        itemsInvoice: [result]
      });
      if (!result) {
        M.toast({ html: 'No se encontro numero de Factura' });
        this.setState({
          itemsInvoice: null,
        });
      }
    } catch (error) {
      this.setState({
        itemsInvoice: null,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      numInvoice: null,
      itemsInvoice: null,
      itemsInvoiceDet: null,
      lastSearch: ''
    });
    this.props.onClose();
  };
  // muestra Factura
  showInvoice = () => {
    if (this.state.itemsInvoice && this.state.itemsInvoice.length > 0) {
      return null;
    } else {
      return null;
    }
  };

  setBrand = async (brand) => {
    let today = new Date();
    let strDate = today.getFullYear()+"-"+today.getMonth()+"-"+today.getDate();  
    this.setState({
      brand: brand,
      strDate: strDate,
      loadingPatterns: true
    });
    if (brand)
    {
      let data = JSON.parse(localStorage.getItem( "OC_model_"+brand.value+"_"+strDate ));
      if ( !data )
      {
        data = {};
      }
      localStorage.setItem( "OC_model_"+brand.value+"_"+strDate, JSON.stringify(data));
      let resultPatterns = await qad.catalog.pattern.relatedBy({brandId: brand.value});
      try
      {
        if (resultPatterns)
        {
          this.setState({
            patterns: resultPatterns,
            loadingPatterns: false
          });
          this.getProducts(resultPatterns[0]);
        }
      }
      catch( error )
      {
        console.error(error);
      }
    }
  }

  getProducts = async (pattern) => {
    this.setState({
      pattern: pattern,
      loading: true
    });
    if (pattern)
    {
      const currentData = JSON.parse(localStorage.getItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate ));
      const resultProducts = await qad.catalog.product.relatedBy({brandId: this.state.brand.value, modelId: pattern.id});
      try
      {
        if (resultProducts)
        {
          let header = {};
          let productsByColor = {
            colorKeys : [],
            colorItems : []
          };
          resultProducts.map( (product) => {
            if ( currentData[product.id] )
            {
              product.qty = currentData[product.id].qty;
              product.ubication = currentData[product.id].ubication;
            }
            else
            {
              product.qty = 0;
              product.ubication = [];
            }
            if ( productsByColor.colorKeys.indexOf(product.colorId) === -1 )
            {
              let backLinear = 'linear-gradient(90deg';
              let colors = product.colorPantoneHexa.split(',');
              let backPercent = ( 100 / colors.length );
              colors.map( (color) => {
                backLinear += ", "+color+' '+backPercent +'%' 
              });
              if ( colors.length == 1 )
              {
                backLinear += ", #fff";
              }
              backLinear += ")";
              productsByColor.colorKeys.push(product.colorId);
              productsByColor.colorItems.push({
                colorName: product.colorLabel,
                colorId: product.colorId,
                colorRGB: backLinear,
                genderKeys: [product.genderId],
                genderItems: [{
                  genderName: product.genderName,
                  genderId: product.genderId,
                  sizeKeys: [product.sizeId],
                  sizeItems: [{
                    sizeName: product.sizeName,
                    sizeId: product.sizeId,
                    sizeOrder: parseInt(product.sizeOrder),
                    products: [product]
                  }]
                }]
              });
            }
            else
            {
              const colorPosition = productsByColor.colorKeys.indexOf(product.colorId);
              let genderPosition = 0;
              let sizePosition = 0;
              if( productsByColor.colorItems[colorPosition].genderKeys.indexOf(product.genderId) === -1 )
              {
                productsByColor.colorItems[colorPosition].genderKeys.push(product.genderId);
                productsByColor.colorItems[colorPosition].genderItems.push({
                  genderName: product.genderName,
                  genderId: product.genderId,
                  sizeKeys: [product.sizeId],
                  sizeItems: [{
                    sizeName: product.sizeName,
                    sizeId: product.sizeId,
                    sizeOrder: parseInt(product.sizeOrder),
                    products: [product]
                  }]
                });
                genderPosition = productsByColor.colorItems[colorPosition].genderKeys.length -1;
              }
              else
              {
                genderPosition = productsByColor.colorItems[colorPosition].genderKeys.indexOf(product.genderId);
                if ( productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.indexOf(product.sizeId) === -1 )
                {
                  productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.push(product.sizeId);
                  productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeItems.push({
                      sizeName: product.sizeName,
                      sizeId: product.sizeId,
                      sizeOrder: parseInt(product.sizeOrder),
                      products: [product]
                  });
                  sizePosition = productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.length - 1 ;
                }
                else
                {
                  sizePosition = productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeKeys.indexOf(product.sizeId);
                  productsByColor.colorItems[colorPosition].genderItems[genderPosition].sizeItems[sizePosition].products.push(product);
                }
              }
              
            }
            if( !header[product.genderId] )
            {
              header[product.genderId] = {
                name: product.genderName,
                id:  product.genderId,
                sizesName : [product.sizeName],
                sizes: [{ name:product.sizeName, id:product.sizeId, order: parseInt(product.sizeOrder) }]
              }
            }
            else
            {
              if ( header[product.genderId].sizesName.indexOf(product.sizeName) === -1 )
              {
                header[product.genderId].sizesName.push(product.sizeName);
                header[product.genderId].sizes.push({ name:product.sizeName, id:product.sizeId, order: parseInt(product.sizeOrder) });
              }
            }
          });
          this.setState({
            header: header,
            products: productsByColor,
            loading: false
          });
        }
      }
      catch( error )
      {
        console.error(error);
      }
    }
  }

  modifyProduct = async (edit, value, valueToChange) => {
    let products = this.state.products;
    let currentVal = value;
    if ( !value )
    {
      currentVal = 0;
    }
    let product = products.colorItems[edit.cIndex].genderItems[edit.gIndex].sizeItems[edit.sIndex].products[edit.pIndex];
    console.log(value);
    if(valueToChange == 'qty')
    {
      product.qty = currentVal;  
    } else{
      product.ubication = value;
    }
    
    let items = JSON.parse(localStorage.getItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate));
    if ( product.qty > 0 )
    {
      items[product.id] = product;
    }
    else
    {
      delete items[product.id];
    }
    localStorage.setItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate, JSON.stringify(items))
    this.setState({
      products: products
    });
  }

  /**
 *
 * @param headers
 * @param items
 * @param fileName
 */
  exportCSVFile = (headers, items, fileName) => {
    if (headers) {
      items.unshift(headers);
    }
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject);
    const exportName = fileName + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
     if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportName);
     } else {
      const link = document.createElement("a");
      if (link.download !== undefined)
      {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  convertToCSV = (objArray) => 
  {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }
  

  generateOC = async () => {
    if ( this.state.brand )
    {
      
      const fileName  = 'Orden_'+this.state.brand.value+"_"+this.state.strDate+'.xlsx';
      const data      = JSON.parse(localStorage.getItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate ));
      const  user = JSON.parse(localStorage.getItem('user'));
      const excelData = Object.values(data).map((item) => { return { 
        id: this.state.withProviderCode ? item.ean : item.id, 
        qty: item.qty,
        ubication: (item.ubication?.length > 0) ? item.ubication.map( (ubi)=> ubi.label).join(',') : '' , 
        thisuser: user.name
      }});
      this.setState( { excelExport: this.renderExcel(excelData, fileName, this.props.isRack ) }, () => { this.export(excelData) } )
      console.log(data, excelData, fileName, user);
    }
    else
    {
      Swal.fire({title:"No se ha seleccionado la Marca"});
    }
  };

  deleteOC = async () => {
    if ( this.state.brand )
    {
      Swal.fire({
        title: '¿Estas seguro?',
        text: 'Vas a eliminar lo que capturaste el dia de hoy..',
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Salir',
        confirmButtonText: 'Si, Eliminar',
      }).then(async (result) => {
        if (result.value) {
          localStorage.removeItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate );
          localStorage.setItem( "OC_model_"+this.state.brand.value+"_"+this.state.strDate, JSON.stringify({}));
          this.setBrand(this.state.brand);
        }
      });
    }
    else
    {
      Swal.fire({title:"No se ha seleccionado la Marca"});
    }
  };


  filterOptions = (inputValue) => {
    if ( inputValue.length > 0 )
    {
      this.filtredRacks = this.ubications.filter((i) =>{
          return i.label.toUpperCase().includes(inputValue.toUpperCase())
        }
      );
    }
    else
    {
      this.filtredCustomers = this.ubications;
    }
    return this.filtredRacks;
  }

  promiseOptions = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterOptions(inputValue));
    });
  }
  

  render() {
    this.brands = this.props.catalog.brands.map((b) => { return { label: b.name, value:b.id} });
    this.filtredRacks = this.ubications = [{ label: 'P1-R1-A', value: 'P1-R1-A' },
      { label: 'P1-R1-B', value: 'P1-R1-B' },
      { label: 'P1-R1-C', value: 'P1-R1-C' },
      { label: 'P1-R1-D', value: 'P1-R1-D' },
      { label: 'P1-R1-E', value: 'P1-R1-E' },
      { label: 'P1-R2-A', value: 'P1-R2-A' },
      { label: 'P1-R2-B', value: 'P1-R2-B' },
      { label: 'P1-R2-C', value: 'P1-R2-C' },
      { label: 'P1-R2-D', value: 'P1-R2-D' },
      { label: 'P1-R2-E', value: 'P1-R2-E' },
      { label: 'P1-R3-A', value: 'P1-R3-A' },
      { label: 'P1-R3-B', value: 'P1-R3-B' },
      { label: 'P1-R3-C', value: 'P1-R3-C' },
      { label: 'P1-R3-D', value: 'P1-R3-D' },
      { label: 'P1-R3-E', value: 'P1-R3-E' },
      { label: 'P1-R4-A', value: 'P1-R4-A' },
      { label: 'P1-R4-B', value: 'P1-R4-B' },
      { label: 'P1-R4-C', value: 'P1-R4-C' },
      { label: 'P1-R4-D', value: 'P1-R4-D' },
      { label: 'P1-R4-E', value: 'P1-R4-E' },
      { label: 'P1-R7-A', value: 'P1-R7-A' },
      { label: 'P1-R7-B', value: 'P1-R7-B' },
      { label: 'P1-R7-C', value: 'P1-R7-C' },
      { label: 'P1-R7-D', value: 'P1-R7-D' },
      { label: 'P1-R7-E', value: 'P1-R7-E' },
      { label: 'P1-R7-A', value: 'P1-R7-A' },
      { label: 'P1-R7-B', value: 'P1-R7-B' },
      { label: 'P1-R7-C', value: 'P1-R7-C' },
      { label: 'P1-R7-D', value: 'P1-R7-D' },
      { label: 'P1-R7-E', value: 'P1-R7-E' },
      { label: 'P1-R8-A', value: 'P1-R8-A' },
      { label: 'P1-R8-B', value: 'P1-R8-B' },
      { label: 'P1-R8-C', value: 'P1-R8-C' },
      { label: 'P1-R8-D', value: 'P1-R8-D' },
      { label: 'P1-R8-E', value: 'P1-R8-E' },
      { label: 'P1-R8-F', value: 'P1-R8-F' },
      { label: 'P1-R9-A', value: 'P1-R9-A' },
      { label: 'P1-R9-B', value: 'P1-R9-B' },
      { label: 'P1-R9-C', value: 'P1-R9-C' },
      { label: 'P1-R9-D', value: 'P1-R9-D' },
      { label: 'P1-R9-E', value: 'P1-R9-E' },
      { label: 'P1-R9-F', value: 'P1-R9-F' },
      { label: 'P1-R10-A', value: 'P1-R10-A' },
      { label: 'P1-R10-B', value: 'P1-R10-B' },
      { label: 'P1-R10-C', value: 'P1-R10-C' },
      { label: 'P1-R10-D', value: 'P1-R10-D' },
      { label: 'P1-R10-E', value: 'P1-R10-E' },
      { label: 'P1-R10-F', value: 'P1-R10-F' },
      { label: 'P1-R11-A', value: 'P1-R11-A' },
      { label: 'P1-R11-B', value: 'P1-R11-B' },
      { label: 'P1-R11-C', value: 'P1-R11-C' },
      { label: 'P1-R11-D', value: 'P1-R11-D' },
      { label: 'P1-R11-E', value: 'P1-R11-E' },
      { label: 'P1-R11-F', value: 'P1-R11-F' },
      { label: 'P1-R12-A', value: 'P1-R12-A' },
      { label: 'P1-R12-B', value: 'P1-R12-B' },
      { label: 'P1-R12-C', value: 'P1-R12-C' },
      { label: 'P1-R12-D', value: 'P1-R12-D' },
      { label: 'P1-R12-E', value: 'P1-R12-E' },
      { label: 'B1-R1-A', value: 'B1-R1-A' },
      { label: 'B1-R1-B', value: 'B1-R1-B' },
      { label: 'B1-R1-C', value: 'B1-R1-C' },
      { label: 'B1-R1-D', value: 'B1-R1-D' },
      { label: 'B1-R1-E', value: 'B1-R1-E' },
      { label: 'B1-R1-F', value: 'B1-R1-F' },
      { label: 'B1-R1-G', value: 'B1-R1-G' },
      { label: 'B1-R1-H', value: 'B1-R1-H' },
      { label: 'B1-R2-A', value: 'B1-R2-A' },
      { label: 'B1-R2-B', value: 'B1-R2-B' },
      { label: 'B1-R2-C', value: 'B1-R2-C' },
      { label: 'B1-R2-D', value: 'B1-R2-D' },
      { label: 'B1-R2-E', value: 'B1-R2-E' },
      { label: 'B1-R3-A', value: 'B1-R3-A' },
      { label: 'B1-R3-B', value: 'B1-R3-B' },
      { label: 'B1-R3-C', value: 'B1-R3-C' },
      { label: 'B1-R3-D', value: 'B1-R3-D' },
      { label: 'B1-R3-E', value: 'B1-R3-E' },
      { label: 'B1-R3-F', value: 'B1-R3-F' },
      { label: 'B1-R3-G', value: 'B1-R3-G' },
      { label: 'B1-R3-H', value: 'B1-R3-H' },
      { label: 'B1-R4-A', value: 'B1-R4-A' },
      { label: 'B1-R4-B', value: 'B1-R4-B' },
      { label: 'B1-R4-C', value: 'B1-R4-C' },
      { label: 'B1-R4-D', value: 'B1-R4-D' },
      { label: 'B1-R4-E', value: 'B1-R4-E' },
      { label: 'B1-R4-F', value: 'B1-R4-F' },
      { label: 'B1-R4-G', value: 'B1-R4-G' },
      { label: 'B1-R4-H', value: 'B1-R4-H' },
      { label: 'B1-R4-I', value: 'B1-R4-I' },
      { label: 'B1-R4-J', value: 'B1-R4-J' },
      { label: 'B1-R4-K', value: 'B1-R4-K' },
      { label: 'B1-R5-A', value: 'B1-R5-A' },
      { label: 'B1-R5-B', value: 'B1-R5-B' },
      { label: 'B1-R5-C', value: 'B1-R5-C' },
      { label: 'B1-R5-D', value: 'B1-R5-D' },
      { label: 'B1-R5-E', value: 'B1-R5-E' },
      { label: 'B1-R5-F', value: 'B1-R5-F' },
      { label: 'B1-R5-G', value: 'B1-R5-G' },
      { label: 'B1-R5-H', value: 'B1-R5-H' },
      { label: 'B1-R5-I', value: 'B1-R5-I' },
      { label: 'B1-R5-J', value: 'B1-R5-J' },
      { label: 'B1-R5-K', value: 'B1-R5-K' },
      { label: 'B1-R5-L', value: 'B1-R5-L' },
      { label: 'B1-R5-M', value: 'B1-R5-M' },
      { label: 'B1-R7-A', value: 'B1-R7-A' },
      { label: 'B1-R7-B', value: 'B1-R7-B' },
      { label: 'B1-R7-C', value: 'B1-R7-C' },
      { label: 'B1-R7-D', value: 'B1-R7-D' },
      { label: 'B1-R7-E', value: 'B1-R7-E' },
      { label: 'B1-R7-F', value: 'B1-R7-F' },
      { label: 'B1-R7-G', value: 'B1-R7-G' },
      { label: 'B1-R7-H', value: 'B1-R7-H' },
      { label: 'B1-R7-I', value: 'B1-R7-I' },
      { label: 'B1-R7-J', value: 'B1-R7-J' },
      { label: 'B1-R7-K', value: 'B1-R7-K' },
      { label: 'P2-R1-A', value: 'P2-R1-A' },
      { label: 'P2-R1-B', value: 'P2-R1-B' },
      { label: 'P2-R2-A', value: 'P2-R2-A' },
      { label: 'P2-R2-B', value: 'P2-R2-B' },
      { label: 'P2-R2-C', value: 'P2-R2-C' },
      { label: 'P2-R3-A', value: 'P2-R3-A' },
      { label: 'P2-R3-B', value: 'P2-R3-B' },
      { label: 'P2-R3-C', value: 'P2-R3-C' },
      { label: 'P2-R4-A', value: 'P2-R4-A' },
      { label: 'P2-R4-B', value: 'P2-R4-B' },
      { label: 'P2-R4-C', value: 'P2-R4-C' },
      { label: 'P2-R5-A', value: 'P2-R5-A' },
      { label: 'P2-R5-B', value: 'P2-R5-B' },
      { label: 'P2-R5-C', value: 'P2-R5-C' },
      { label: 'P2-R5-D', value: 'P2-R5-D' },
      { label: 'P2-R5-E', value: 'P2-R5-E' },
      { label: 'P2-R5-F', value: 'P2-R5-F' },
      { label: 'P2-R5-G', value: 'P2-R5-G' },
      { label: 'P2-R7-A', value: 'P2-R7-A' },
      { label: 'P2-R7-B', value: 'P2-R7-B' },
      { label: 'P2-R7-C', value: 'P2-R7-C' },
      { label: 'P2-R7-D', value: 'P2-R7-D' },
      { label: 'P2-R7-E', value: 'P2-R7-E' },
      { label: 'P2-R7-F', value: 'P2-R7-F' },
      { label: 'P2-R7-G', value: 'P2-R7-G' },
      { label: 'P2-R8-A', value: 'P2-R8-A' },
      { label: 'P2-R8-B', value: 'P2-R8-B' },
      { label: 'P2-R8-C', value: 'P2-R8-C' },
      { label: 'P2-R8-D', value: 'P2-R8-D' },
      { label: 'P2-R8-E', value: 'P2-R8-E' },
      { label: 'P2-R8-F', value: 'P2-R8-F' },
      { label: 'P2-R8-G', value: 'P2-R8-G' },
      { label: 'P2-R9-A', value: 'P2-R9-A' },
      { label: 'P2-R9-B', value: 'P2-R9-B' },
      { label: 'P2-R9-C', value: 'P2-R9-C' },
      { label: 'P2-R9-D', value: 'P2-R9-D' },
      { label: 'P2-R9-E', value: 'P2-R9-E' },
      { label: 'P2-R9-F', value: 'P2-R9-F' },
      { label: 'P2-R9-G', value: 'P2-R9-G' },
      { label: 'P2-R10-A', value: 'P2-R10-A' },
      { label: 'P2-R10-B', value: 'P2-R10-B' },
      { label: 'P2-R10-C', value: 'P2-R10-C' },
      { label: 'P2-R10-D', value: 'P2-R10-D' },
      { label: 'P2-R10-E', value: 'P2-R10-E' },
      { label: 'P2-R10-F', value: 'P2-R10-F' },
      { label: 'P2-R10-G', value: 'P2-R10-G' },
      { label: 'P2-R10-H', value: 'P2-R10-H' },
      { label: 'P2-R11-A', value: 'P2-R11-A' },
      { label: 'P2-R11-B', value: 'P2-R11-B' },
      { label: 'P2-R11-C', value: 'P2-R11-C' },
      { label: 'P2-R11-D', value: 'P2-R11-D' },
      { label: 'P2-R11-E', value: 'P2-R11-E' },
      { label: 'P2-R11-F', value: 'P2-R11-F' },
      { label: 'P2-R11-G', value: 'P2-R11-G' },
      { label: 'P2-R11-H', value: 'P2-R11-H' },
      { label: 'P2-R12-A', value: 'P2-R12-A' },
      { label: 'P2-R12-B', value: 'P2-R12-B' },
      { label: 'P2-R12-C', value: 'P2-R12-C' },
      { label: 'P2-R12-D', value: 'P2-R12-D' },
      { label: 'P2-R12-E', value: 'P2-R12-E' },
      { label: 'P2-R12-F', value: 'P2-R12-F' },
      { label: 'P2-R12-G', value: 'P2-R12-G' },
      { label: 'P2-R12-H', value: 'P2-R12-H' },
      { label: 'P2-R13-A', value: 'P2-R13-A' },
      { label: 'P2-R13-B', value: 'P2-R13-B' },
      { label: 'P2-R13-C', value: 'P2-R13-C' },
      { label: 'P2-R13-D', value: 'P2-R13-D' },
      { label: 'P2-R13-E', value: 'P2-R13-E' },
      { label: 'P2-R13-F', value: 'P2-R13-F' },
      { label: 'P2-R13-G', value: 'P2-R13-G' },
      { label: 'P2-R13-H', value: 'P2-R13-H' },
      { label: 'P2-R14-A', value: 'P2-R14-A' },
      { label: 'P2-R14-B', value: 'P2-R14-B' },
      { label: 'P2-R14-C', value: 'P2-R14-C' },
      { label: 'P2-R14-D', value: 'P2-R14-D' },
      { label: 'P2-R14-E', value: 'P2-R14-E' },
      { label: 'P2-R14-F', value: 'P2-R14-F' },
      { label: 'P2-R14-G', value: 'P2-R14-G' },
      { label: 'P2-R14-H', value: 'P2-R14-H' },
      { label: 'P2-R15-A', value: 'P2-R15-A' },
      { label: 'P2-R15-B', value: 'P2-R15-B' },
      { label: 'P2-R15-C', value: 'P2-R15-C' },
      { label: 'P2-R15-D', value: 'P2-R15-D' },
      { label: 'P2-R15-E', value: 'P2-R15-E' },
      { label: 'P2-R15-F', value: 'P2-R15-F' },
      { label: 'P2-R15-G', value: 'P2-R15-G' },
      { label: 'P2-R15-H', value: 'P2-R15-H' },
      { label: 'P2-R16-A', value: 'P2-R16-A' },
      { label: 'P2-R16-B', value: 'P2-R16-B' },
      { label: 'P2-R16-C', value: 'P2-R16-C' },
      { label: 'P2-R16-D', value: 'P2-R16-D' },
      { label: 'P2-R16-E', value: 'P2-R16-E' },
      { label: 'P2-R17-A', value: 'P2-R17-A' },
      { label: 'P2-R17-B', value: 'P2-R17-B' },
      { label: 'P2-R17-C', value: 'P2-R17-C' },
      { label: 'P2-R17-D', value: 'P2-R17-D' },
      { label: 'P2-R17-E', value: 'P2-R17-E' },
      { label: 'P2-R17-F', value: 'P2-R17-F' },
      { label: 'P2-R17-G', value: 'P2-R17-G' },
      { label: 'P2-R17-H', value: 'P2-R17-H' },
      { label: 'P2-R17-I', value: 'P2-R17-I' },
      { label: 'P2-R17-J', value: 'P2-R17-J' },
      { label: 'P2-R17-K', value: 'P2-R17-K' },
      { label: 'P2-R18-A', value: 'P2-R18-A' },
      { label: 'P2-R18-B', value: 'P2-R18-B' },
      { label: 'P2-R19-A', value: 'P2-R19-A' },
      { label: 'P2-R19-B', value: 'P2-R19-B' },
      { label: 'P2-R20-A', value: 'P2-R20-A' },
      { label: 'P2-R20-B', value: 'P2-R20-B' },
      { label: 'P2-R21-A', value: 'P2-R21-A' },
      { label: 'P2-R21-B', value: 'P2-R21-B' },
      { label: 'B2-R1-A', value: 'B2-R1-A' },
      { label: 'B2-R1-B', value: 'B2-R1-B' },
      { label: 'B2-R1-C', value: 'B2-R1-C' },
      { label: 'B2-R2-A', value: 'B2-R2-A' },
      { label: 'B2-R2-B', value: 'B2-R2-B' },
      { label: 'B2-R2-C', value: 'B2-R2-C' },
      { label: 'B2-R3-A', value: 'B2-R3-A' },
      { label: 'B2-R3-B', value: 'B2-R3-B' },
      { label: 'B2-R3-C', value: 'B2-R3-C' },
      { label: 'B2-R3-D', value: 'B2-R3-D' },
      { label: 'B2-R3-E', value: 'B2-R3-E' },
      { label: 'B2-R3-F', value: 'B2-R3-F' },
      { label: 'B2-R3-G', value: 'B2-R3-G' },
      { label: 'B2-R3-H', value: 'B2-R3-H' },
      { label: 'B2-R3-I', value: 'B2-R3-I' },
      { label: 'B2-R3-J', value: 'B2-R3-J' },
      { label: 'B2-R3-K', value: 'B2-R3-K' },
      { label: 'B2-R3-L', value: 'B2-R3-L' },
      { label: 'B2-R3-M', value: 'B2-R3-M' },
      { label: 'B2-R3-N', value: 'B2-R3-N' },
      { label: 'B2-R4-A', value: 'B2-R4-A' },
      { label: 'B2-R4-B', value: 'B2-R4-B' },
      { label: 'B2-R4-C', value: 'B2-R4-C' },
      { label: 'B2-R4-D', value: 'B2-R4-D' },
      { label: 'B2-R4-E', value: 'B2-R4-E' },
      { label: 'B2-R4-F', value: 'B2-R4-F' },
      { label: 'B2-R4-G', value: 'B2-R4-G' },
      { label: 'B2-R4-H', value: 'B2-R4-H' },
      { label: 'B2-R4-I', value: 'B2-R4-I' },
      { label: 'B2-R4-J', value: 'B2-R4-J' },
      { label: 'B2-R4-K', value: 'B2-R4-K' },
      { label: 'B2-R4-L', value: 'B2-R4-L' },
      { label: 'B2-R4-M', value: 'B2-R4-M' },
      { label: 'B2-R5-A', value: 'B2-R5-A' },
      { label: 'B2-R5-B', value: 'B2-R5-B' },
      { label: 'B2-R5-C', value: 'B2-R5-C' },
      { label: 'B2-R5-D', value: 'B2-R5-D' },
      { label: 'B2-R5-E', value: 'B2-R5-E' },
      { label: 'B2-R5-F', value: 'B2-R5-F' },
      { label: 'B2-R5-G', value: 'B2-R5-G' },
      { label: 'B2-R5-H', value: 'B2-R5-H' },
      { label: 'B2-R5-I', value: 'B2-R5-I' },
      { label: 'B2-R5-J', value: 'B2-R5-J' },
      { label: 'B2-R5-K', value: 'B2-R5-K' },
      { label: 'B2-R5-L', value: 'B2-R5-L' },
      { label: 'B2-R5-M', value: 'B2-R5-M' },
      { label: 'B2-R7-A', value: 'B2-R7-A' },
      { label: 'B2-R7-B', value: 'B2-R7-B' },
      { label: 'B2-R7-C', value: 'B2-R7-C' },
      { label: 'B2-R7-D', value: 'B2-R7-D' },
      { label: 'B2-R7-E', value: 'B2-R7-E' },
      { label: 'B2-R7-F', value: 'B2-R7-F' },
      { label: 'B2-R7-G', value: 'B2-R7-G' },
      { label: 'B2-R7-H', value: 'B2-R7-H' },
      { label: 'B2-R7-I', value: 'B2-R7-I' },
      { label: 'B2-R7-J', value: 'B2-R7-J' },
      { label: 'B2-R7-K', value: 'B2-R7-K' },
      { label: 'B2-R7-L', value: 'B2-R7-L' },
      { label: 'B2-EST-1', value: 'B2-EST-1' },
      { label: 'B2-EST-2', value: 'B2-EST-2' },
      { label: 'B2-EST-3', value: 'B2-EST-3' },
      { label: 'B2-EST-4', value: 'B2-EST-4' },
      { label: 'B2-EST-5', value: 'B2-EST-5' },
      { label: 'B2-EST-6', value: 'B2-EST-6' },
      { label: 'B2-EST-7', value: 'B2-EST-7' },
      { label: 'B2-EST-8', value: 'B2-EST-8' },
      { label: 'B2-EST-9', value: 'B2-EST-9' },
      { label: 'B2-EST-10', value: 'B2-EST-10' },
      { label: 'B2-EST-11', value: 'B2-EST-11' },
      { label: 'B2-EST-12', value: 'B2-EST-12' },
      { label: 'B2-EST-13', value: 'B2-EST-13' },
      { label: 'B2-EST-14', value: 'B2-EST-14' },
      { label: 'B2-EST-15', value: 'B2-EST-15' },
      { label: 'B2-EST-16', value: 'B2-EST-16' },
      { label: 'P3-R1-A', value: 'P3-R1-A' },
      { label: 'P3-R1-B', value: 'P3-R1-B' },
      { label: 'P3-R1-C', value: 'P3-R1-C' },
      { label: 'P3-R1-D', value: 'P3-R1-D' },
      { label: 'P3-R1-E', value: 'P3-R1-E' },
      { label: 'P3-R1-F', value: 'P3-R1-F' },
      { label: 'P3-R1-G', value: 'P3-R1-G' },
      { label: 'P3-R1-H', value: 'P3-R1-H' },
      { label: 'P3-R2-A', value: 'P3-R2-A' },
      { label: 'P3-R2-B', value: 'P3-R2-B' },
      { label: 'P3-R2-C', value: 'P3-R2-C' },
      { label: 'P3-R2-D', value: 'P3-R2-D' },
      { label: 'P3-R2-E', value: 'P3-R2-E' },
      { label: 'P3-R2-F', value: 'P3-R2-F' },
      { label: 'P3-R2-G', value: 'P3-R2-G' },
      { label: 'P3-R2-H', value: 'P3-R2-H' },
      { label: 'P3-R3-A', value: 'P3-R3-A' },
      { label: 'P3-R3-B', value: 'P3-R3-B' },
      { label: 'P3-R3-C', value: 'P3-R3-C' },
      { label: 'P3-R3-D', value: 'P3-R3-D' },
      { label: 'P3-R3-E', value: 'P3-R3-E' },
      { label: 'P3-R3-F', value: 'P3-R3-F' },
      { label: 'P3-R3-G', value: 'P3-R3-G' },
      { label: 'P3-R3-H', value: 'P3-R3-H' },
      { label: 'P3-R4-A', value: 'P3-R4-A' },
      { label: 'P3-R4-B', value: 'P3-R4-B' },
      { label: 'P3-R4-C', value: 'P3-R4-C' },
      { label: 'P3-R4-D', value: 'P3-R4-D' },
      { label: 'P3-R4-E', value: 'P3-R4-E' },
      { label: 'P3-R4-F', value: 'P3-R4-F' },
      { label: 'P3-R4-G', value: 'P3-R4-G' },
      { label: 'P3-R4-H', value: 'P3-R4-H' },
      { label: 'P3-R5-A', value: 'P3-R5-A' },
      { label: 'P3-R5-B', value: 'P3-R5-B' },
      { label: 'P3-R5-C', value: 'P3-R5-C' },
      { label: 'P3-R5-D', value: 'P3-R5-D' },
      { label: 'P3-R5-E', value: 'P3-R5-E' },
      { label: 'P3-R5-F', value: 'P3-R5-F' },
      { label: 'P3-R5-G', value: 'P3-R5-G' },
      { label: 'P3-R5-H', value: 'P3-R5-H' },
      { label: 'P3-R6-A', value: 'P3-R6-A' },
      { label: 'P3-R6-B', value: 'P3-R6-B' },
      { label: 'P3-R6-C', value: 'P3-R6-C' },
      { label: 'P3-R6-D', value: 'P3-R6-D' },
      { label: 'P3-R6-E', value: 'P3-R6-E' },
      { label: 'P3-R6-F', value: 'P3-R6-F' },
      { label: 'P3-R6-G', value: 'P3-R6-G' },
      { label: 'P3-R6-H', value: 'P3-R6-H' },
      { label: 'P3-R7-A', value: 'P3-R7-A' },
      { label: 'P3-R7-B', value: 'P3-R7-B' },
      { label: 'P3-R7-C', value: 'P3-R7-C' },
      { label: 'P3-R7-D', value: 'P3-R7-D' },
      { label: 'P3-R7-E', value: 'P3-R7-E' },
      { label: 'P3-R7-F', value: 'P3-R7-F' },
      { label: 'P3-R7-G', value: 'P3-R7-G' },
      { label: 'P3-R7-H', value: 'P3-R7-H' },
      { label: 'P3-R8-A', value: 'P3-R8-A' },
      { label: 'P3-R8-B', value: 'P3-R8-B' },
      { label: 'P3-R8-C', value: 'P3-R8-C' },
      { label: 'P3-R8-D', value: 'P3-R8-D' },
      { label: 'P3-R8-E', value: 'P3-R8-E' },
      { label: 'P3-R8-F', value: 'P3-R8-F' },
      { label: 'P3-R8-G', value: 'P3-R8-G' },
      { label: 'P3-R8-H', value: 'P3-R8-H' },
      { label: 'P3-R9-A', value: 'P3-R9-A' },
      { label: 'P3-R9-B', value: 'P3-R9-B' },
      { label: 'P3-R9-C', value: 'P3-R9-C' },
      { label: 'P3-R9-D', value: 'P3-R9-D' },
      { label: 'P3-R9-E', value: 'P3-R9-E' },
      { label: 'P3-R9-F', value: 'P3-R9-F' },
      { label: 'P3-R9-G', value: 'P3-R9-G' },
      { label: 'P3-R9-H', value: 'P3-R9-H' },
      { label: 'P3-R10-A', value: 'P3-R10-A' },
      { label: 'P3-R10-B', value: 'P3-R10-B' },
      { label: 'P3-R10-C', value: 'P3-R10-C' },
      { label: 'P3-R10-D', value: 'P3-R10-D' },
      { label: 'P3-R10-E', value: 'P3-R10-E' },
      { label: 'P3-R10-F', value: 'P3-R10-F' },
      { label: 'P3-R10-G', value: 'P3-R10-G' },
      { label: 'P3-R10-H', value: 'P3-R10-H' },
      { label: 'P3-R11-A', value: 'P3-R11-A' },
      { label: 'P3-R11-B', value: 'P3-R11-B' },
      { label: 'P3-R11-C', value: 'P3-R11-C' },
      { label: 'P3-R11-D', value: 'P3-R11-D' },
      { label: 'P3-R11-E', value: 'P3-R11-E' },
      { label: 'P3-R11-F', value: 'P3-R11-F' },
      { label: 'P3-R11-G', value: 'P3-R11-G' },
      { label: 'P3-R11-H', value: 'P3-R11-H' },
      { label: 'P3-R12-A', value: 'P3-R12-A' },
      { label: 'P3-R12-B', value: 'P3-R12-B' },
      { label: 'P3-R12-C', value: 'P3-R12-C' },
      { label: 'P3-R12-D', value: 'P3-R12-D' },
      { label: 'P3-R12-E', value: 'P3-R12-E' },
      { label: 'P3-R12-F', value: 'P3-R12-F' },
      { label: 'P3-R12-G', value: 'P3-R12-G' },
      { label: 'P3-R12-H', value: 'P3-R12-H' },
      { label: 'P3-R13-A', value: 'P3-R13-A' },
      { label: 'P3-R13-B', value: 'P3-R13-B' },
      { label: 'P3-R13-C', value: 'P3-R13-C' },
      { label: 'P3-R13-D', value: 'P3-R13-D' },
      { label: 'P3-R13-E', value: 'P3-R13-E' },
      { label: 'P3-R13-F', value: 'P3-R13-F' },
      { label: 'P3-R13-G', value: 'P3-R13-G' },
      { label: 'P3-R13-H', value: 'P3-R13-H' },
      { label: 'P3-R14-A', value: 'P3-R14-A' },
      { label: 'P3-R14-B', value: 'P3-R14-B' },
      { label: 'P3-R14-C', value: 'P3-R14-C' },
      { label: 'P3-R14-D', value: 'P3-R14-D' },
      { label: 'P3-R14-E', value: 'P3-R14-E' },
      { label: 'P3-R14-F', value: 'P3-R14-F' },
      { label: 'P3-R14-G', value: 'P3-R14-G' },
      { label: 'P3-R14-H', value: 'P3-R14-H' },
      { label: 'P3-R15-A', value: 'P3-R15-A' },
      { label: 'P3-R15-B', value: 'P3-R15-B' },
      { label: 'P3-R15-C', value: 'P3-R15-C' }
    ]
    let pathByGender = {} ;


  

  
    return (
      

      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          isRack={this.props.isRack}
          onClose={this.handleCloseModal}
        >
          <Container className="OCContainer">
            <Row>
              <Col s={12} m={12} l={12} xl={12}>
                <h4 className="m3">Marca</h4>
                <AsyncSelect
                  id="brands"
                  placeholder="Proveedor"
                  cacheOptions
                  isClearable
                  value={this.state.brand}
                  onChange={(value) => this.setBrand(value) }
                  defaultOptions={this.brands} />
              </Col>
            </Row>
            <br/>
            { 
              this.state.loadingPatterns 
              ? 
                <LoadingView /> 
              : 
                <Row>
                  <Col s={12}>
                  {
                    this.state.patterns.map( (pattern) => {
                      return (
                        <Button onClick={()=> this.getProducts(pattern) } key={pattern.id}>{pattern.name}</Button>
                      )
                    })
                  }
                  </Col>
                </Row>
            }
            <h6 className="username">
              USUARIO:&nbsp;{this.user.name}
            </h6>
            { 
              this.state.loading 
              ? 
                <LoadingView /> 
              : 
                <Row>
                  <Col s={12}>
                  <table className="table bordered table-bordered">
                    <thead style={{position: "sticky",top: 0, background: "#fff"}}>
                      <tr>
                        <td rowSpan={2}>Color</td>
                        {
                          Object.values(this.state.header).map((headG)=>{
                            return (
                              <td key={headG.id} colSpan={headG.sizes.length}>{headG.name}</td>
                            )
                          })
                        }
                      </tr>
                      <tr>
                        {

                          Object.values(this.state.header).map((headG)=>{
                            pathByGender[headG.id] = [];
                            return headG.sizes.sort( (a, b) => {
                                if (a.order > b.order) {
                                  return 1;
                                }
                                if (a.order < b.order) {
                                  return -1;
                                }
                                return 0;
                              }).map( (size) => {
                                pathByGender[headG.id].push(size.id);
                                return (<td key={"cel_"+size.name}>{size.name}</td>)
                              } )
                          })
                        }
                      </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.products.colorItems.map( (color, cIndex) => {
                        let idex = 0;
                        return (
                          <tr key={color.colorId}>
                            <td key={"cell_"+color.colorId} className="colorRow">
                              <div className="colorContainer">
                                <div key={"name_"+color.colorId} className="colorName">{color.colorName}</div>
                                <div key={"rgb_"+color.colorId} className="colorRGB" style={{background: color.colorRGB}}>&nbsp;</div>
                              </div>
                            </td>
                            {
                              color.genderItems.map( (genderItem, gIndex) => {
                                let currentSizeIndex = 0;
                                return genderItem.sizeItems.sort( (a, b) => {
                                  if (a.sizeOrder > b.sizeOrder) {
                                    return 1;
                                  }
                                  if (a.sizeOrder < b.sizeOrder) {
                                    return -1;
                                  }
                                  return 0;
                                }).map((sizeItem, sIndex)=>{
                                  let emptyElements = [];
                                  let currentSize = null;
                                  do
                                  {
                                    idex += 1;
                                    if ( pathByGender[genderItem.genderId][currentSizeIndex] === sizeItem.sizeId )
                                    {
                                      currentSize = pathByGender[genderItem.genderId][currentSizeIndex];
                                      currentSizeIndex += 1;
                                    }
                                    else
                                    {
                                      emptyElements.push((<td key={idex+"_"+sizeItem.sizeName} className="product" id={idex+"_"+sizeItem.sizeName}>&nbsp;</td>));
                                      currentSizeIndex += 1;
                                    }
                                  } while ( currentSize === null && currentSizeIndex <= genderItem.sizeItems.length );
                                  return [ 
                                    ...emptyElements,
                                    ...sizeItem.products.map( (product, pIndex) => {
                                      console.log(product);
                                      return (

                                        
                                          <td key={product.id} className="product" id={product.id} title={product.sizeName}>
                                            <input 
                                              key={"input_"+product.id}
                                              title={product.sizeLabel} 
                                              value={product.qty} 
                                              type="number"
                                              onChange={(e) => this.modifyProduct({cIndex, gIndex, sIndex, pIndex}, e.target.value, 'qty') } />
                                            {
                                              (this.props.isRack)
                                              ?
                                                <AsyncSelect
                                                  id="racks"
                                                  placeholder="Ubicacion"
                                                  cacheOptions
                                                  isMulti
                                                  isClearable
                                                  loadOptions={this.promiseOptions}
                                                  value={product.ubication}
                                                  onChange={(value) => this.modifyProduct({cIndex, gIndex, sIndex, pIndex}, value, 'ubication') }
                                                  options={[this.ubications, {label:"piso 2", value:1}]}
                                                  defaultOptions={this.ubications} 
                                                />
                                              :
                                                null
                                            }
                                          </td>
                                        )
                                      }
                                    )
                                  ];
                                })
                              })
                            }
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                </Col>
                <Col
                 s={12} className="p-bottom">
                    <label> ¿Con el Código de Proveedor? </label>
                    <Switch
                      id="withProviderCode"
                      onLabel={'Si'}
                      offLabel={'No'}
                      checked={this.state.withProviderCode}
                      onClick={(e) =>  this.setState({withProviderCode: e.target.checked}) }
                    />
                  </Col>
                <Col className="p-bottom s8">
                  <Button disabled={!this.state.brand} onClick={()=> this.generateOC() }>Generar OC</Button>
                </Col>
                <Col className="p-bottom s4 l3 offset-l1">
                  <Button className="delete" disabled={!this.state.brand} onClick={()=> this.deleteOC() }>Borrar Datos</Button>
                </Col>
              </Row>
          }
            <div className="hide">
              {
                this.state.excelExport
              } 
            </div>
          </Container>
        </Modal>
      </React.Fragment>
    );
  }
  
  export = (data) => {
    this._export.save(data);
  };

  renderExcel(data, fileName, isRack) {
    return (
      <ExcelExport ref={(exporter) => (this._export = exporter)} fileName={fileName}>
        <Grid
          data={data}
        >
          <GridColumn
            width="100px"
            field="id"
            title="Identificador"
          ></GridColumn>
          <GridColumn
            width="120px"
            field="qty"
            title="Cantidad"
          ></GridColumn>
          {
            ( isRack )
            ? 
              [       
                <GridColumn
                  key="ubication"
                  width="100px"
                  field='ubication'
                  title='ubicación'
                  ></GridColumn>
                ,
                <GridColumn
                  key="user"
                  width="100px"
                  field='thisuser'
                  title='Usuario'
                ></GridColumn>
              ]
            :
              null
          }
        </Grid>
      </ExcelExport>
    );
  }
}
export { ModalOC as default };

