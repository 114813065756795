import { Button, Col, Container, Row, TextInput } from 'react-materialize';
import Modal from './modal';
import React from 'react';



class ModalFaq extends React.Component {
  async componentDidMount() {
  }

  handleCloseModal = () => {
    this.props.onClose();
  };

  render() {
    let image1 = `${this.props.url}FAQ%2FERROR1.png${this.props.urlPass}`;
    let image2 = `${this.props.url}FAQ%2FERROR2-1.png${this.props.urlPass}`;
    let image3 = `${this.props.url}FAQ%2FERROR3.png${this.props.urlfaq1}`;
    let image4 = `${this.props.url}FAQ%2FERROR4.png${this.props.urlPass}`;
    let image5 = `${this.props.url}FAQ%2FERROR5.png${this.props.urlPass}`;
    let image6 = `${this.props.url}FAQ%2FERROR6.png${this.props.urlPass}`;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.handleCloseModal}
        >
          <Container>
            <Row>
              <Col>
                <h3 className="m3">PREGUNTAS FRECUENTES</h3>
                  <img src={image1} />
                    <p style={{color:'blue', fontSize:'30px'}}>
                      NO CARGAN ARTICULOS, CLIENTES, IMAGENES, COLORES.                     
                    </p>
                  <p style={{color:'red', fontSize:'30px'}}>
                    ACCION: ACTUALIZAR PAGINA O SALIR Y ENTRARA AL PV
                  </p>
                      <img src={image2} />
                      <p style={{color:'blue', fontSize:'30px'}}>
                        USUARIO Y PASSWORD INCORRECTOS .                     
                      </p>
                    <p style={{color:'red', fontSize:'30px'}}>
                      ACCION: VERIFICAR QUE LA RUTA SEA "HTTP" Y NO "HTTPS"
                       EJEMPLO: http://pos.miplayera.com.mx/#</p>
                       <img src={image3} />
                      <p style={{color:'blue', fontSize:'30px'}}>
                        ERROR AL DAR DE ALTA A UN CLIENTE .                     
                      </p>
                    <p style={{color:'red', fontSize:'30px'}}>
                      ACCION: ACTUALIZAR PAGINA O SALIR Y ENTRARA AL PV
                      </p>
                      <img src={image4} />
                      <p style={{color:'blue', fontSize:'30px'}}>
                        ERROR AL CANCELAR PEDIDOS.                     
                      </p>
                    <p style={{color:'red', fontSize:'30px'}}>
                      ACCION: NO TIENE LOS PERMISOS, NO TIENE REMISION O YA ESTA FACTURADO</p>
                      <img src={image5} />
                      <p style={{color:'blue', fontSize:'30px'}}>
                        ERROR AL CREAR REMISION.                     
                      </p>
                    <p style={{color:'red', fontSize:'30px'}}>
                      ACCION: VERIFICAR LA EXISTENCIA DE LOS PRODUCTOS CON ALMACEN
                      YA QUE NO SE CREA REMISION POR INV. INSUFICIENTE (INVENTARIO INSUFICIENTE)</p>
                      <img src={image6} />
                      <p style={{color:'blue', fontSize:'30px'}}>
                        NO SE RECIBE RESPUESTA DEL SERVIDOR                      
                      </p>
                    <p style={{color:'red', fontSize:'30px'}}>
                      ACCION: REINICIAR, DE PERSISTIR EL ERROR REPORTAR AL AREA DE SISTEMAS</p>
              </Col>
            </Row>
          </Container>
        </Modal>
      </React.Fragment>
    );
  }

}
export { ModalFaq as default };
