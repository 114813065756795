import { del, formatResponse, put, get, post, getApiEndpoint } from '../helpers/index.js';

class SalesOrderService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'SalesOrder';
  }

  /**
   * Gets sales orders.
   * @param {Object} [data={}]
   * @param {Object} [data.customerId]
   * @param {Object} [data.fromDate]
   * @param {Object} [data.salesOrderId]
   * @param {Object} [data.salesPersonId]
   * @param {Object} [data.toDate]
   * @return {Object[]}
   */
  async all(data = {}) {
    const action = 'readh';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const { customerId, fromDate, salesOrderId, salesPersonId, toDate } = data;
    const getResponse = await get(this.client, endpoint, {
      params: {
        salesOrderId: salesOrderId || '',
        customerId: customerId || '',
        salesPersonId: salesPersonId || '',
        fromDate: fromDate || '',
        toDate: toDate || '',
      },
    });
    return formatResponse(getResponse);
  }

  async getDetailById(orderId) {
    const action = 'readd';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        salesOrderId: orderId,
      },
    });
    return formatResponse(getResponse);
  }

  async findById(orderId) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        salesOrderId: orderId,
        customerId: '',
        salesPersonId: '',
        fromDate: '',
        toDate: '',
      },
    });
    const [salesOrder] = formatResponse(getResponse);
    return salesOrder;
  }

  async findByCustomerId(customerId) {
    const action = 'readh';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        salesOrderId: '',
        customerId,
        salesPersonId: '',
        fromDate: '',
        toDate: '',
      },
    });
    return formatResponse(getResponse);
  }

  async findBySalesPersonId(salesPersonId) {
    const action = 'readh';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        salesOrderId: '',
        customerId: '',
        salesPersonId,
        fromDate: '',
        toDate: '',
      },
    });
    return formatResponse(getResponse);
  }

  async findByDateRange(fromDate, toDate) {
    const action = 'readh';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        salesOrderId: '',
        customerId: '',
        salesPersonId: '',
        fromDate,
        toDate,
      },
    });
    return formatResponse(getResponse);
  }

  async create(data) {
    const action = 'create';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const postResponse = await post(this.client, endpoint, formattedData);
    const [salesOrderCreated] = formatResponse(postResponse);
    return salesOrderCreated;
  }

  async cancel(orderId) {
    const action = 'delete';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const deleteResponse = await del(this.client, endpoint, {
      params: { order: orderId },
    });
    const [salesOrderCanceled] = formatResponse(deleteResponse);
    return salesOrderCanceled;
  }

  async toRemision(orderId) {
    const action = 'create';
    const apiEndpoint = 'remision';
    const endpoint = `${apiEndpoint}/${action}`;
    const postResponse = await post(this.client, endpoint, null, {
      params: { salesOrderId: orderId },
    });
    const [salesOrderRemision] = formatResponse(postResponse);
    return salesOrderRemision;
  }

  async getRemision(data) {
    const action = 'get';
    const apiEndpoint = 'remision';
    const endpoint = `${apiEndpoint}/${action}`;
    const postResponse = await post(this.client, endpoint, null, {
      params: data,
    });
    const [salesOrderRemision] = formatResponse(postResponse);
    return salesOrderRemision;
  }

  async findRemisionById(remisionId) {
    const action = 'remision';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id: remisionId,
      },
    });
    const [salesOrder] = formatResponse(getResponse);
    return salesOrder;
  }

  async cancelRemision(salesOrderId, salesPersonId) {
    const action = 'delete';
    const endpoint = getApiEndpoint() + `remision/${action}`;
    const getResponse = await del(this.client, endpoint, {
      params: {
        id: salesOrderId,
        salesPersonId: salesPersonId
      },
    });
    const [salesOrder] = formatResponse(getResponse);
    return salesOrder;
  }

  async listForDelete() {
    const action = 'fordelete';
    const endpoint = getApiEndpoint() + `remision/${action}`;
    const getResponse = await get(this.client, endpoint);
    return formatResponse(getResponse);
  }

  async notToDelete(data) {
    const action = 'noDelete';
    const endpoint = getApiEndpoint() + `remision/${action}`;
    const postResponse = await put(this.client, endpoint, data);
    const [salesOrderRemision] = formatResponse(postResponse);
    return salesOrderRemision;
  }

  async update(data) {
    const action = 'update';
    const endpoint = `${this.apiEndpoint}/${action}`;;
    const postResponse = await put(this.client, endpoint, data);
    const [salesOrderRemision] = formatResponse(postResponse);
    return salesOrderRemision;
  }

  async findInvoiceById(invoiceId) {
    const action = 'invoice';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        invoice: invoiceId,
      },
    });
    const [salesOrder] = formatResponse(getResponse);
    return salesOrder;
  }

}

export { SalesOrderService as default };
