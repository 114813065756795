import './sidebar.css';

import { Button, Icon } from 'react-materialize';
import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import ModalPedido from '../modals/modalPedidos';
import ModalExistences from '../modals/modalExistences';
import ModalNewCustomer from '../modals/modalNewCustomer';
import ModalInvoices from '../modals/modalInvoices';
import ModalOC from '../modals/modalOC';
import ModalRemision from '../modals/modalRemision';
import ModalCancel from '../modal-cancel-report/modalCancel';
import ModalReporte from '../modal-reporte/modal-reporte.view';
import ModalProducts from '../modal-products/modal-products.view';
import ModalReporte2 from '../modal-reporte2/modal-reporte.view';
import ModalFaq from '../modals/modalFaq';
import ModalCheckin from '../modals/modalCheckin';
import React from 'react';

// modals

function SideBarView(props) {
  let reporte = JSON.parse(localStorage.getItem('user')).reporte_ventas;
  const canCancel = JSON.parse(localStorage.getItem('user')).orderCanc;
  return (
    <div className="sidebar">
      <SideNav onToggle={props.onToggleDetail}>
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected="home">
          {props.open ? (
            props.productosLocalStorage.length >= 1 ? (
              <div>
                <div className="title">PEDIDO</div>
                <div className="pedido-margin-button">
                  <Button onClick={props.openPedido} large>
                    IR AL PEDIDO
                  </Button>
                </div>
                <div className="detail-table">
                  <table className="responsive-table striped centered"
                  >
                    <thead>
                      <tr>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Talla</th>
                        <th>Color</th>
                        <th>Precio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.productosLocalStorage.map((producto, index) => {
                        return (<tr key={index}>
                          <td>{producto.descripcion}</td>
                          <td>{producto.cantidad}</td>
                          <td>{producto.talla}</td>
                          <td>{producto.color}</td>
                          <td>$ {producto.prec_prod}</td>
                        </tr>);
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="title">{`SUB TOTAL: $ ${props.total}`}</div>
                <div className="title">{`IVA: $ ${(props.total * 0.16).toFixed(
                  2
                )}`}</div>
                <div className="title">{`TOTAL: $ ${(
                  Number(props.total) + Number(props.total * 0.16)
                ).toFixed(2)}`}</div>
              </div>
            ) : (
              <div>
                <h5 className="sidebar-titile whtie">
                  Selecciona al menos un articulo!
                </h5>
              </div>
            )
          ) : (
            <React.Fragment>
              {
                (canCancel === "1") 
                ? 
                (
                  <NavItem title={"Cancelar Pedidos Masivos"}>
                    <NavIcon onClick={props.modalCancelOpen}>
                      <Icon large>auto_delete</Icon>
                    </NavIcon>
                  </NavItem>
                ) : (
                null
                )
              }
              <NavItem title={"Pedidos"}>
                <NavIcon onClick={props.openModalPedidos}>
                  <Icon large>local_shipping</Icon>
                </NavIcon>
              </NavItem>
              <NavItem title={"Remision"}>
                <NavIcon onClick={props.openModalRemision}>
                  <Icon large>inventory_2</Icon>
                </NavIcon>
              </NavItem>
              <NavItem title={"Factura"}>
                <NavIcon onClick={props.modalInvoicesOpen}>
                  <Icon large>folder</Icon>
                </NavIcon>
              </NavItem>
              <NavItem title={"Articulos"}>
                <NavIcon onClick={props.openModalArticulos}>
                  <Icon large>local_offer</Icon>
                </NavIcon>
              </NavItem>
              <NavItem title={"Existencias"}>
                <NavIcon onClick={props.openModalExistences}>
                  <Icon large>find_in_page</Icon>
                </NavIcon>
              </NavItem>
              <NavItem title={"Agregar Clientes"}>
                <NavIcon onClick={props.modalNewCustomerOpen}>
                  <Icon large>person_add</Icon>
                </NavIcon>
              </NavItem>
              
              <NavItem title={"Crear OC"}>
                <NavIcon onClick={props.modalOCOpen}>
                  <Icon large>assignment_returned</Icon>
                </NavIcon>
              </NavItem>
              
              <NavItem title={"Rack"}>
                <NavIcon onClick={props.modalRackOpen}>
                  <Icon large>computer</Icon>
                </NavIcon>
              </NavItem>
              
              <NavItem title={"Reportes"}>
                <NavIcon>
                  <Icon large>assignment</Icon>
                </NavIcon>
                <NavItem>
                  <NavText onClick={props.openModalReporte}>
                    Reporte de pedidos
                  </NavText>
                </NavItem>
                {reporte === 1 && (
                  <NavItem>
                    <NavText onClick={props.openModalReporte2}>
                      Ventas x canal x agente x clase
                    </NavText>
                  </NavItem>
                  
                )}
              </NavItem>
              <NavItem title={"Checkin"}>
                <NavIcon onClick={props.modalCheckinOpen}>
                  <Icon large>tag</Icon>
                </NavIcon>
              </NavItem>
              <NavItem title={"Ayuda"}>
                <NavIcon onClick={props.modalFaqOpen}>
                  <Icon large>help</Icon>
                </NavIcon>
              </NavItem>
              <ModalPedido
                catalog={props.catalog}
                modalPedidosIsOpen={props.modalPedidosIsOpen}
                onClose={props.closeModalPedidos}
                makeRemission={props.makeRemission}
              />
              <ModalRemision
                catalog={props.catalog}
                modalRemisionIsOpen={props.modalRemisionIsOpen}
                getInventoryAndPrices={props.getInventoryAndPrices}
                insertIntoLocalStorage={props.insertIntoLocalStorage}
                onClose={props.closeModalRemision}
              />
              <ModalProducts
                allProducts={props.allProducts}
                catalog={props.catalog}
                url={props.url}
                urlPass={props.urlPass}
                modalArticulosIsOpen={props.modalArticulosIsOpen}
                onClose={props.closeModalArticulos}
              />
              <ModalReporte
                modalReporteIsOpen={props.modalReporteIsOpen}
                onClose={props.closeModalReporte}
                catalog={props.catalog}
              />
              <ModalExistences
                catalog={props.catalog}
                isOpen={props.modalExistencesIsOpen}
                onClose={props.closeModalExistences}
              />
              <ModalNewCustomer
                catalog={props.catalog}
                isOpen={props.modalNewCustomerIsOpen}
                onClose={props.modalNewCustomerClose}
              />
              <ModalInvoices
                isOpen={props.modalInvoicesIsOpen}
                onClose={props.modalInvoicesClose}
              />
              <ModalOC
                catalog={props.catalog}
                isOpen={props.modalOCIsOpen}
                isRack={props.isRack}
                onClose={props.modalOCClose}
              />
              <ModalCancel
                modalReporte2IsOpen={props.modalCancelIsOpen}
                onClose={props.modalCancelClose}
                catalog={props.catalog}
              />
              <ModalReporte2
                modalReporte2IsOpen={props.modalReporte2IsOpen}
                onClose={props.closeModalReporte2}
                catalog={props.catalog}
              />
              <ModalFaq
                isOpen={props.modalFaqIsOpen}
                onClose={props.modalFaqClose}
                url={props.url}
                urlPass={props.urlOnlyPass}
                urlfaq={props.urlfaq}               
                urlfaq1={props.urlfaq1}
              />
              <ModalCheckin
                catalog={props.catalog}
                isOpen={props.modalCheckinIsOpen}
                onClose={props.modalCheckinClose}
              />
            </React.Fragment>
          )}
        </SideNav.Nav>
      </SideNav>
    </div>
  );
}

export { SideBarView as default };
