import React from 'react';
import './grid-cancel-report.css';
import { Grid, GridColumn, GridNoRecords, GridToolbar} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button, Switch } from 'react-materialize';

class GridReporte extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOrders: this.props.data,
    };
  }

  _export;
  export = () => {
    if (this.props.data.length > 0) {
      this._export.save(this.props.data);
    } else {
      alert('No hay datos para exportar.');
    }
  };


  render() {
    //const { data, filtros } = this.props;
    //const { selectedOrders } = this.state;

    return (
      <React.Fragment>
        <ExcelExport ref={(exporter) => (this._export = exporter)}>
          <Grid data={this.state.selectedOrders} resizable>
            <GridNoRecords>No se encontraron registros</GridNoRecords>
            <GridToolbar>
              <div className="toolbar">
                <Button node="button" onClick={this.export}>
                  <span className="k-icon k-i-excel"></span> Exportar a Excel
                </Button>
                <section>
                  <p>
                    Registros encontrados: <strong>{this.state.selectedOrders.length}</strong>
                  </p>
                </section>
              </div>
            </GridToolbar>
            <GridColumn
              field="noDelete"
              title="Seleccionar"
              cell={(item) => {
                let itemIndex = item.dataIndex;
                return (
                <td>
                  <Switch
                    id={"select_"+itemIndex}
                    onLabel={''}
                    offLabel={''}
                    checked={this.state.selectedOrders[itemIndex]["noDelete"]}
                    onChange={(e) =>  {
                        let current = this.state.selectedOrders;
                        current[itemIndex]["noDelete"] = e.target.checked;
                        current[itemIndex]["haveChanged"] = true; 
                        this.setState({ selectedOrders: current});
                      }
                    }
                  />
                </td>
              );
              }}
              width="100px"
            />
            <GridColumn field="salesOrderId" title="Pedido" />
            <GridColumn field="remissionId" title="Remisión" />
            <GridColumn field="salesOrderDate" title="Fecha" />
            <GridColumn field="customerName" title="Vendedor" />
            <GridColumn field="salesPersonName" title="Cliente" />
            <GridColumn field="quantity" title="Cantidad" />
            <GridColumn field="remarks" title="Comentarios" />
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }
}

export default GridReporte;
