import {
  ExistenciaService,
  FileService,
  NavigationService,
  PedidoService,
  ProductoService,
} from '../../services';

import AppView from '../views/app/app.view';
import M from 'materialize-css';
import React from 'react';
import Swal from 'sweetalert2';
import config from '../../config';
import imageNotFoundStart from '../../assets/images/imagenotavailable.png';
import printJS from 'print-js';
import { ErpConnect as qad } from '../../services/index.js';


const SOURCE_KEY_DEFAULT = 'PV';

class AppContainer extends React.Component {
  state = {
    url: 'https://owncloud.miplayera.com.mx/index.php/apps/files_sharing/ajax/publicpreview.php?file=',
    urlPass: '&c=d2784ea855c9b773ecb2f28cf4470979&x=450&y=600&t=fIBOKXu7vDsKcUY',
    urlPassSquare: '&c=d2784ea855c9b773ecb2f28cf4470979&t=fIBOKXu7vDsKcUY&x=250&y=250',
    loading: false,
    urlOnlyPass: '&c=d2784ea855c9b773ecb2f28cf4470979&t=fIBOKXu7vDsKcUY&x=1200&y=600',
    urlfaq:     '&c=d2784ea855c9b773ecb2f28cf4470979&t=fIBOKXu7vDsKcUY&x=300&y=600',
    urlfaq1:     '&c=d2784ea855c9b773ecb2f28cf4470979&t=fIBOKXu7vDsKcUY&x=1350&y=300',
    mapUrl:'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json',
    open: false,
    print: false,
    keyboarDimensionClass: '',
    showKeyboard: false,
    showProduct: false,
    showPedido: false,
    selectCategory: false,
    familyId: 0,
    brandId: 0,
    genderId: 0,
    styleId: 0,
    modelId: 0,
    modelAux: null,
    productId: 0,
    existencia: 0,
    existencia2: 0,
    existencia3: 0,
    existencia4: 0,
    existenciaTallas: [],
    brandsClass: '',
    gendersClass: '',
    stylesClass: '',
    modelsClass: '',
    productsClass: '',
    familyName: '',
    brandsName: '',
    gendersName: '',
    stylesName: '',
    modelsName: '',
    productsName: '',
    product: {},
    productos: [],
    tallas: [],
    coloresServicio: [],
    colores: [],
    elements: [],
    navigations: [],
    envios: [],
    colorId: '',
    tallaId: 0,
    footer: 'footer-bottom',
    productosLocalStorage: [],
    tallaSeleccionada: '',
    exceptColorNewMedList: ['HILO MADEIRA POLYSTITCH'],
    envioSeleccionado: 0,
    cantidadSeleccionada: '',
    botonBloqueado: true,
    currentNewMed: null,
    currentColor: null,
    existenColores: false,
    colorHidden: true,
    numeroPedido: "",
    numeroRemision: 0,
    descuentoPedido: '',
    comentarioPedido: '',
    colorSeleccionado: '',
    total: 0,
    cantidad: 0,
    image: imageNotFoundStart,
    imageNotFound: imageNotFoundStart,
    imageRute: '',
    countOtros: 0,
    user: '',
    formatDate: '',
    formatTime: '',
    keyboard: {
      name: '',
      layout: {},
      display: {},
      data: {},
      value: '',
      component: '',
    },
    scroll: 0,
    cliente: '',
    clientesServicio: [],
    clienteSeleccionado: {},
    currentClient: null,
    modalPedidosIsOpen: false,
    modalExistencesIsOpen: false,
    modalRemisionIsOpen: false,
    modalArticulosIsOpen: false,
    modalInvoicesIsOpen: false,
    modalNewCustomerIsOpen: false,
    modalFaqIsOpen: false,
    modalOCIsOpen: false,
    modalReporteIsOpen: false,
    modalReporte2IsOpen: false,
    modalRackOpen: false,
    modalCancelIsOpen: false,
    modalCheckinIsOpen: false,
    newAgente: null,
    clientes: {},
    clienteCompleto: null,
    inventories: [],
    catalog: {
      brands: [],
      categories: [],
      colors: [],
      customers: [],
      formattedCategories: [],
      genders: [],
      locations: [],
      locations_available: [],
      pantones: [],
      parcels: [],
      patterns: [],
      sizes: [],
      styles: [],
      agents: [],
      products: [],
      pricesList: [],
      geography: { countries: [], states: [], cities: [] },
      customerTypes: [],
      inactiveCustomers: [],
      hasInactiveCustomers: false
    },
    allProducts: []
  };
  navigationService = new NavigationService();
  fileService = new FileService();
  imageRutaBase = config.filesUrl;
  userSucursal = JSON.parse(localStorage.getItem('user')).sucursal;

  keyboard = {
    layouts: {
      spanishLayout: [
        "1 2 3 4 5 6 7 8 9 0 ' ¿ {bksp} {clear2}",
        'q w e r t y u i o p {enter2}',
        'a s d f g h j k l ñ {enter}',
        'z x c v b n m , . -',
        '.com @ {space}',
      ],
      numericLayout: [
        '1 2 3',
        '4 5 6',
        '7 8 9',
        ' 0 {clear}',
        '{bksp} {enter}',
      ],
    },
    display: {
      '{clear}': 'C',
      '{clear2}': 'Borrar Todo',
      '{bksp}': 'Borrar',
      '{enter}': 'Salir del Teclado',
      '{space}': 'Espacio',
      '{enter2}': 'Enter',
    },
  };

  async componentDidMount() {
    this.initView();
  }

  makeRemission = async (numPedido) => 
  {
    Swal.fire({
      title: "Creando Remisión",
      showCloseButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onOpen: () => {
        Swal.showLoading();
      }
    });
    const remission = await qad.catalog.salesOrder.toRemision(
      numPedido
    );
    if ( remission && remission.salesOrdeId )
    {
      Swal.close();
      M.toast({
        html: 'Se genero la remisión correctamente!',
        classes: 'message-success',
      });
    }
    else
    {
      let message = "No especificado";
      if ( remission && remission.message )
      {
        message = remission.message;
      }
      M.toast({
        html: 'Error al generar la remisión!',
        classes: 'message-error',
      });
    }

  }

  showSendMail = () => {
    const link = qad.catalog.customer.getWhatsappLink();
    const href = "http://wa.me/?text=Te%20envio%20tu%20pedido%20"+link+this.state.numeroPedido.trim();
    Swal.fire({
        title: "A qué correo quieres enviarlo?",
        showCancelButton: true,
        text: "No. Pedido: "+this.state.numeroPedido+"    No. Remision: "+this.state.numeroRemision,
        input: "text",
        showDenyButton: true,
        inputLabel: "Ingresa tu E-mail",
        inputPlaceholder: "Ingresa tu E-mail",
        confirmButtonText: "Por Mail",
        cancelButtonText: "Cancelar",
        showLoaderOnConfirm: true,
        onOpen : () => {
          //console.log(this.state.clienteSeleccionado);
          if (this.state.clienteSeleccionado.email) 
          {
            //console.log( Swal.getPopup().querySelector(".swal2-input"), this.state.clienteSeleccionado.email);
            Swal.getPopup().querySelector(".swal2-input").value = this.state.clienteSeleccionado.email;
          }
        },
        footer: `<a href='${href}' target='_blank'>Enviar por Whatsapp</a>`,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async (login) => {
          try
          {
            Swal.disableButtons();
            const mails = login.split(',');
            const regex = RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
            const validatedMails = mails.map((mail) => {
              return regex.exec(mail) != null
            });
            if ( validatedMails.indexOf(false) !== -1 )
            {
              Swal.enableButtons();
              Swal.showValidationMessage(`E-mail invalido`);
            }
            else
            {
              const response = await qad.catalog.website.sendMail(login, this.state.numeroPedido.trim());
              Swal.enableButtons();
              return response;
            }
          } catch (error) {
            //Habilitar cuando se quite el HTTP
            Swal.enableButtons();
            Swal.showValidationMessage(`Request failed: ${error}`);
            //return {"error":false};
          }
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let timerInterval;
          Swal.fire({
            title: "Se envio el mail correctamente!",
            html: "Cierre automatico en <b></b> milisegundos.",
            timer: 2000,
            timerProgressBar: true,
            onOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          });
        }
        else
        {
          try {
            await navigator.clipboard.writeText(link+this.state.numeroPedido.trim());
            M.toast({
              html: 'Se copio el link',
              classes: 'message-success',
            });
          } 
          catch (e) 
          {
            M.toast({
              html: e.message,
              classes: 'message-error',
            });
            throw e;
          }
        }
      });
  }

  fromLocalStorage = (catalog) => {
    return JSON.parse(localStorage.getItem("cat_"+catalog))
  }

  syncCatalogs = (catalog) => {
    let syncAll = false;
    const lastSync = localStorage.getItem("last_date_"+catalog);
    if (lastSync !== null )
    {
      const today = new Date();
      const lastSyncDate = Date.parse(lastSync);
      const lastSyncOnHours = (today.getTime() - lastSyncDate) / (1000*60*60*24);
      if ( lastSyncOnHours > 1 )
      {
        syncAll = true;
      }
    }
    else
    {
      syncAll = true;
    }
    console.log("last_date_"+catalog, lastSync, syncAll);
    return syncAll;
  }

  getPriceLists = async ( qtyOfTimes = 0 ) => {
    let priceList = [];
    if ( this.syncCatalogs("price_list") )
    {
      priceList = await qad.catalog.website.getPriceLists();
      localStorage.setItem("cat_price_list", JSON.stringify(priceList));
      localStorage.setItem("last_date_price_list", new Date());
    }
    else
    {
      priceList = this.fromLocalStorage("price_list");
    }
    if ( priceList.length == 0 )
    {
      if ( qtyOfTimes < 10 )
      {
        Swal.fire({title:'Lista de precios Cargados!'});
        return this.getPriceLists( qtyOfTimes + 1 );
      }
      else
      {
        Swal.fire({title:'PriceListError'});
        return [];
      }
    }
    return priceList;
  }

  hasPricelistReady = async () =>
  {
    let state = this.state;
    console.log(state);
    return new Promise( (resolve) => {
      while ( !this.state.catalog 
        && !this.state.catalog.locationsAvailable 
        && !this.state.catalog.priceList
        && !this.state.productosTallas
        && !(this.state.catalog.priceList.length > 0)
        && !(Object.values(this.state.catalog.locationsAvailable).length > 0)
        && !(this.state.productosTallas.length > 0) 
      )
      {
        console.log(this.state, new Date());
        console.log("sleeped", new Date());
        this.sleep(1000);
        console.log("sleepedEnd", new Date());
      }
      resolve(true);
    });
  }

  sleep = async (time) =>
  {
    const promise =  new Promise( (resolve) => {
      setTimeout( resolve, time)
    });
    const sleepReturn = await promise;
    return sleepReturn;
  }

  getGeography = async ( qtyOfTimes = 0 ) => {
    let priceList = [];
    if ( this.syncCatalogs("geography") )
    {
      priceList = await qad.catalog.website.getGeography();
      localStorage.setItem("cat_geography", JSON.stringify(priceList));
      localStorage.setItem("last_date_geography", new Date());
    }
    else
    {
      priceList = this.fromLocalStorage("geography");
    }
    if ( priceList.length === 0 )
    {
      localStorage.removeItem("last_date_geography");
      if ( qtyOfTimes < 10 )
      {
        return this.getGeography( qtyOfTimes + 1 );
      }
      else
      {
        Swal.fire({title:'GeographyError', text: "Se esta intentando otra vez"});
        return [];
      }
    }
    return priceList;
  }
  
  getParcels = async () => {
    let priceList = [];
    if ( this.syncCatalogs("parcels") )
    {
      priceList = await qad.catalog.website.getParcels();
      localStorage.setItem("cat_parcels", JSON.stringify(priceList));
      localStorage.setItem("last_date_parcels", new Date());
    }
    else
    {
      priceList = this.fromLocalStorage("parcels");
    }
    return priceList;
  }

  getProducts = async () => {
    return qad.catalog.website.getProducts();
  }

  getClients = async () => {
    return await qad.catalog.website.getClients(true);
  }

  getInactiveClients = async () => {
    return await qad.catalog.website.getClients(false);
  }

  // Get All catalogs
  getAllCatalogs = async () => {
    const catalog = {
          brands: [],
          categories: [],
          colors: [],
          formattedCategories: JSON.parse(localStorage.getItem('formattedCategories')),
          genders: [],
          locations: [],
          locations_available: [],
          pantones: [],
          patterns: [],
          sizes: [],
          styles: [],
          agents: [],
          customerTypes: [],
          inactiveCustomers: [],
          hasInactiveCustomers: false
    };
    if ( this.syncCatalogs("full_catalogs") )
    {
          catalog.brands = await qad.catalog.brand.all();
          catalog.styles = await qad.catalog.style.all();
          catalog.patterns = await qad.catalog.pattern.all();
          catalog.genders = await qad.catalog.gender.all();
          catalog.categories = await qad.catalog.category.all();
          localStorage.setItem("cat_categories", JSON.stringify(catalog.categories));
          //catalog.colors = await qad.catalog.color.all();
          catalog.colors = await qad.catalog.website.getColors();
          localStorage.setItem("cat_colors", JSON.stringify(catalog.colors));
          //catalog.locations = await qad.catalog.location.all();
          catalog.locations = await qad.catalog.website.getLocations();
          localStorage.setItem("cat_locations", JSON.stringify(catalog.locations));
          catalog.locations_available = qad.catalog.location.LOCATIONS_AVAILABLE;
          localStorage.setItem("cat_locations_available", JSON.stringify(catalog.locations_available));
          catalog.pantones = await qad.catalog.pantone.all();
          localStorage.setItem("cat_pantones", JSON.stringify(catalog.pantones));
          catalog.sizes = await qad.catalog.size.all();
          localStorage.setItem("cat_sizes", JSON.stringify(catalog.sizes));
          catalog.agents = await qad.catalog.salesPerson.all();
          localStorage.setItem("cat_agents", JSON.stringify(catalog.agents));
          catalog.customerTypes = await qad.catalog.customer.getTypes();
          localStorage.setItem("cat_customer_types", JSON.stringify(catalog.customerTypes));
          localStorage.setItem("last_date_agents", new Date());
          // Sorting
          catalog.brands = catalog.brands.sort((a, b) => a.order - b.order);
          localStorage.setItem("cat_brands", JSON.stringify(catalog.brands));
          catalog.genders = catalog.genders.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 1;
          });
          localStorage.setItem("cat_genders", JSON.stringify(catalog.genders));
          catalog.patterns = catalog.patterns.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 1;
          });
          localStorage.setItem("cat_patterns", JSON.stringify(catalog.patterns));
          catalog.styles = catalog.styles.sort((a, b) => a.order - b.order);
          localStorage.setItem("cat_styles", JSON.stringify(catalog.styles));
          localStorage.setItem("last_date_full_catalogs", new Date());
    }
    else
    {
          catalog.agents = this.fromLocalStorage("agents");
          catalog.brands = this.fromLocalStorage('brands');
          catalog.categories = this.fromLocalStorage('categories');
          catalog.colors = this.fromLocalStorage('colors');
          catalog.genders = this.fromLocalStorage('genders');
          catalog.locations = this.fromLocalStorage('locations');
          catalog.locations_available = this.fromLocalStorage('locations_available');
          catalog.pantones = this.fromLocalStorage('pantones');
          catalog.patterns = this.fromLocalStorage('patterns');
          catalog.sizes = this.fromLocalStorage('sizes');
          catalog.styles = this.fromLocalStorage('styles');
          catalog.customerTypes = this.fromLocalStorage('customer_types');
    }
    return catalog;
  }

  getInventories = () => {
    return qad.catalog.inventory.all();
  }

  initView = async () => {
    this.setState({
      loading: true,
    });
    try {
      let elements = JSON.parse(localStorage.getItem('formattedCategories'));
      
      this.getClients().then( (clients) => {
        const allCatalog = {
          ...this.state.catalog,
          customers: clients
        };
        this.setState({ catalog: allCatalog });
      });

      this.getInactiveClients().then( (clients) => {
        const allCatalog = {
          ...this.state.catalog,
          inactiveCustomers: clients,
          hasInactiveCustomers: true
        };
        this.setState({ catalog: allCatalog });
      });

      this.getPriceLists().then((priceLists) => {
        const catalog = { ...this.state.catalog, priceList: priceLists };
        const priceListType = [...new Set(priceLists.map(({ id }) => id))];
        catalog.priceListType = Object.fromEntries(
          priceListType.map((key) => {
            return [key, key];
          })
        );
        this.setState({
          catalog,
        });
      });

      this.getParcels().then((parcels) => {
        const catalog = { ...this.state.catalog, parcels };
        this.setState({
          catalog,
        });
      });

      this.getAllCatalogs().then((catalog) => {
        const allCatalog = {
          ...this.state.catalog,
          ...catalog,
        };
        this.setState({ catalog: allCatalog });
      });

      /*this.getInventories().then((inventories) => {
        this.setState({ inventories });
      });*/

      this.getProducts().then( (products) => {
        const allCatalog = {
          ...this.state.catalog,
          products: products
        };
        this.setState({ catalog: allCatalog });
      });

      this.getGeography().then( (geography) => {
        if ( geography.countries )
        {
          const allCatalog = {
            ...this.state.catalog,
            geography: geography
          };
          this.setState({ catalog: allCatalog });
        }
      });


      let navigations = await this.navigationService.getNavigations();
      let { productosLocalStorage, total, cantidad } = this.getProductos();

      this.setState({
        elements,
        navigations,
        productosLocalStorage,
        total,
        cantidad,
      });

      M.AutoInit();
    } catch (error) {
      console.log(`An error has ocurred: ${error}`);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <div>
        <AppView
          {...this.state}
          product={this.state.product || {}}
          allProducts={this.state.allProducts}
          productos={this.state.productos}
          pantones={this.state.catalog.pantones}
          locations={this.state.catalog.locations}
          locationsAvailable={this.state.catalog.locations_available}
          inventories={this.state.inventories}
          componentRef={this.componentRef}
          setActiveInput={this.setActiveInput}
          setNuevoAgente={this.setNuevoAgente}
          insertIntoLocalStorage={this.insertIntoLocalStorage}
          setPriceProductosLocalStorage={this.setPriceProductosLocalStorage}
          openKeyBoard={this.openKeyBoard}
          openPedido={this.openPedido}
          cerrarSesion={this.cerrarSesion}
          getCategoryData={this.getCategoryData}
          getArticulosRelacionados={this.getArticulosRelacionados}
          getMarcasRelacionadas={this.getMarcasRelacionadas}
          changeProductClassEmpty={this.changeProductClassEmpty}
          changeProductViewIds={this.changeProductViewIds}
          brandId={this.state.brandId}
          regresarPedido={this.regresarPedido}
          onChangeDescuento={this.onChangeDescuento}
          hiddenPedido={this.hiddenPedido}
          colocarPedido={this.colocarPedido}
          onToggleDetail={this.onToggleDetail}
          eliminarPartida={this.eliminarPartida}
          cambiarCantidad={this.cambiarCantidad}
          setPriceByProduct={this.setPriceByProduct}
          agregarEnvio={this.agregarEnvio}
          onChangeComentarioPedido={this.onChangeComentarioPedido}
          seleccionarCliente={this.seleccionarCliente}
          regresarArticulos={this.regresarArticulos}
          onSelectEnvio={this.onSelectEnvio}
          onSelectAgente={this.onSelectAgente}
          scrolloToBottom={this.scrolloToBottom}
          scrolloToTop={this.scrolloToTop}
          onChangeSelectedCliente={this.onChangeSelectedCliente}
          onSelectCliente={this.onSelectCliente}
          onClickElement={this.onClickElement}
          keyboardPress={this.keyboardPress}
          onChangeColor={this.onChangeColor}
          onChangeColorTallas={this.onChangeColorTallas}
          getTallasExistencias={this.getTallasExistencias}
          getInventoryAndPrices={this.getInventoryAndPrices}
          onChangeTalla={this.onChangeTalla}
          onChangeCantidad={this.onChangeCantidad}
          openModalPedidos={this.modalPedidosOpen}
          closeModalPedidos={this.modalPedidosClose}
          closeModalExistences={this.modalExistencesClose}
          openModalRemision={this.modalRemisionOpen}
          closeModalRemision={this.modalRemisionClose}
          openModalArticulos={this.modalArticulosOpen}
          openModalExistences={this.modalExistencesOpen}
          closeModalArticulos={this.modalArticulosClose}
          modalNewCustomerOpen={this.modalNewCustomerOpen}
          modalNewCustomerClose={this.modalNewCustomerClose}
          modalInvoicesOpen={this.modalInvoicesOpen}
          modalInvoicesClose={this.modalInvoicesClose}
          modalOCOpen={this.modalOCOpen}
          modalOCClose={this.modalOCClose}
          openModalReporte={this.modalReporteOpen}
          closeModalReporte={this.modalReporteClose}
          openModalReporte2={this.modalReporte2Open}
          closeModalReporte2={this.modalReporte2Close}
          changeImageRute={this.changeImageRute}
          getPriceByPriceListKey={this.getPriceByPriceListKey}
          getProductURLImage={this.getProductURLImage}
          makeRemission={this.makeRemission}
          getPriceLists={this.getPriceLists}
          modalFaqClose={this.modalFaqClose}
          modalFaqOpen={this.modalFaqOpen}
          modalRackClose={this.modalOCClose}
          modalRackOpen={this.modalOCOpen}
          modalCancelOpen={this.modalCancelOpen}
          modalCancelClose={this.modalCancelClose}
          modalCheckinOpen={this.modalCheckinOpen}
          modalCheckinClose={this.modalCheckinClose}
        />
      </div>
    );
  }

  cerrarSesion = () => {
    localStorage.clear();
    this.props.logout();
  };

  componentDidUpdate() {
    let select = document.getElementById('autocomplete-cliente');

    if (select) {
      select.value = this.state.cliente;
    }
  }

  onToggleDetail = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  openKeyBoard = () => {
    this.setState({
      showKeyboard: !this.state.showKeyboard,
    });
  };

  openPedido = async () => {
    let envios = this.state.catalog.parcels.map((parcel) => {
      return {
        id: parcel.id,
        cse_prod: 'SERVICIOS',
        cve_prod: parcel.productId,
        desc_prod: `${parcel.label} (${parcel.id})`,
        precio: parcel.shippingGuidePrice,
        priceList: Object.values(this.state.catalog.priceListType).map(
          (priceListId) => {
            return {
              id: priceListId,
              price: parcel.shippingGuidePrice,
              productId: '',
            };
          }
        ),
        new_med: null,
        desc_med: null,
      };
    });
    let productosData = this.getProductos();
    let footer = 'footer-height';

    let { productosLocalStorage, total, cantidad } = productosData;

    if (productosLocalStorage.length <= 0) {
      footer = 'footer-bottom';
    }
    //this.onToggleDetail();
    this.setState({
      showPedido: true,
      showKeyboard: false,
      footer,
      clienteSeleccionado: {},
      descuentoPedido: '',
      comentarioPedido: '',
      productosLocalStorage,
      envios,
      total,
      cliente: '',
      cantidad,
    });
  };

  getPriceByPriceListKey(
    key = qad.catalog.priceList.priceListKey?.MENUDEO,
    productPricesList
  ) {
    if (!key) {
      return 0;
    }
    const foundPriceList = productPricesList.find(
      ({ id: priceListKey }) => priceListKey === key
    );
    if (!foundPriceList) {
      return 0;
    }
    return parseFloat(foundPriceList.price);
  }

  getProductos = () => {
    let total = 0;
    let cantidad = 0;
    let productosLocalStorage = JSON.parse(
      localStorage.getItem('productosLocalStorage')
    );
    if (productosLocalStorage) {
      productosLocalStorage.forEach((element) => {
        cantidad = cantidad + 1;
        let precio =
          element.prec_set ??
          this.getPriceByPriceListKey(
            this.state.clienteSeleccionado.pricesList,
            element.priceList
          );

        total = total + Number(precio) * element.cantidad;
      });

      total = total.toFixed(2);
    } else {
      productosLocalStorage = [];
    }

    return {
      productosLocalStorage,
      total,
      cantidad,
    };
  };

  regresarPedido = async () => {
    this.setState({
      showPedido: !this.state.showPedido,
      showProduct: !this.state.showProduct,
      tallaSeleccionada: '',
    });
  };

  changeImageRute = (categoria, marca, genero, modelo, modelo_anterior) => {
    let imgPath = this.state.imageRute.split('/');
    imgPath = imgPath.filter((response) => response !== modelo_anterior);
    categoria = String(categoria).toLowerCase();
    if (imgPath.length >= 6) {
      let estilo = imgPath[3];
      this.setState({
        imageRute: `${categoria}/${marca}/${genero}/${estilo}/${modelo}/`,
      });
    } else {
      this.setState({
        imageRute: `${categoria}/${marca}/${genero}/${modelo}/`,
      });
    }
  };

  onChangeTallaValue = async (talla, elements = {}) => {
    let colores = [];
    let footer;
    let imageFile = this.state?.product?.imageCloud;
    console.log("producto", this.state.product);
    if (imageFile) {
      footer = 'footer-height';
    } else {
      footer = 'footer-bottom';
      imageFile = this.state.imageNotFound;
    }
    let coloresServicio =
      this.state.coloresServicio?.length > 0
        ? this.state.coloresServicio
        : elements?.colores;
    let productos =
      this.state.productos?.length > 0
        ? this.state.productos
        : elements?.productos;
    if (talla === 'UNITALLA') {
      if (coloresServicio.length === 1 && coloresServicio[0].id === 'SC') {
        colores.push(coloresServicio[0]);
        this.onChangeColor(coloresServicio[0], 0, productos[0], talla);
      } else {
        for (var l = 0; l < coloresServicio.length; l++) {
          if (coloresServicio[l]) {
            colores.push({
              id: productos[l].color,
              pantone: productos[l].pantone ? productos[l].pantone : '',
            });
          }
        }
      }

      footer = colores.length > 14 ? 'footer-height' : 'footer-bottom';
      let existenColores = colores.length > 0 ? true : false;
      console.log("images4", this.state, imageFile);
      return this.setState({
        tallaSeleccionada: talla,
        colorHidden: false,
        colores,
        existenColores,
        footer,
        colorId: '',
        botonBloqueado: false,
        cantidadSeleccionada: '',
        //image: !imageFile.includes('0.jpg') ? imageFile : this.state.image,
      });
    } else {
      for (let i = 0; i < productos.length; i++) {
        let elements = productos[i].codigo.split('-');
        let tallaProducto = elements[elements.length - 1];

        if (tallaProducto === talla && productos[i].color) {
          if (colores.length === 0) {
            colores.push({
              id: productos[i].color,
              pantone: productos[i].pantone ? productos[i].pantone : '',
            });
          } else {
            var insertar = true;

            for (let index = 0; index < colores.length; index++) {
              if (colores[index].id === productos[i].color) {
                insertar = false;
                break;
              }
            }

            if (insertar && productos[i].color !== ' ') {
              colores.push({
                id: productos[i].color,
                pantone: productos[i].pantone ? productos[i].pantone : '',
              });
            }
          }
        }
      }

      let existenColores = colores.length > 0 ? true : false;
      console.log("images5", this.state, imageFile);
      if (!existenColores) {
        return this.setState({
          image: imageFile,
          cantidadSeleccionada: '',
          tallaSeleccionada: talla,
          colorHidden: false,
          colores,
          existenColores,
          botonBloqueado: false,
          footer: 'footer-bottom',
        });
      }

      let footer = colores.length > 14 ? 'footer-height' : 'footer-bottom';
      console.log("images6", this.state, imageFile);
      return this.setState({
        image: imageFile,
        cantidadSeleccionada: '',
        tallaSeleccionada: talla,
        colorHidden: false,
        colores,
        existenColores,
        footer,
        colorId: '',
        botonBloqueado: false,
      });
    }
  };

  onChangeTalla = async (event) => {
    let product = this.state.product;
    let colores = [];
    let talla = event.target.value;
    let footer;
    let fileService = new FileService();
    await this.getColoresHex();
    let imageFile = `${this.imageRutaBase}${this.state.imageRute}/${this.state.modelId}.jpg`;
    // let imageFile = `${this.imageRutaBase}${this.state.modelId}/${this.state.modelId}.jpg`

    let response = await fileService.checkFile(imageFile);
    if (response.status === 200) {
      footer = 'footer-height';
    } else {
      footer = 'footer-bottom';
      imageFile = this.state.imageNotFound;
    }

    if (talla === 'UNITALLA') {
      if (
        this.state.coloresServicio.length === 1 &&
        this.state.coloresServicio[0].id === 'SC'
      ) {
        colores.push(this.state.coloresServicio[0]);

        this.onChangeColor(
          this.state.coloresServicio[0],
          0,
          this.state.productos[0],
          talla
        );
      } else {
        for (var l = 0; l < this.state.coloresServicio.length; l++) {
          if (this.state.coloresServicio[l]) {
            colores.push({
              id: this.state.productos[l].color,
              pantone: this.state.productos[l].pantone
                ? this.state.productos[l].pantone
                : '',
              hex: this.state.coloresServicio[l].hex,
            });
            // let splitColor = (this.state.coloresServicio[l].includes('-')) ? this.state.coloresServicio[l].split('-') : this.state.coloresServicio[l];
            // if(splitColor.length>2) {
            //     for(let i = 2;i<splitColor.length;i++) {
            //         splitColor[1] = splitColor[1].concat('-', splitColor[i]);
            //     }
            // }
            // colores.push({
            //     id: splitColor[0],
            //     pantone: (splitColor.length > 1) ? splitColor[1] : ''
            // })
            // colores.push({
            //     id: this.state.coloresServicio[l],
            //     class: this.state.coloresServicio[l].toLowerCase().split(' ').join('_').split('/').join('_')
            // })
          }
        }
      }

      footer = colores.length > 14 ? 'footer-height' : 'footer-bottom';
      let existenColores = colores.length > 0 ? true : false;
      console.log("images7", this.state, imageFile);
      return this.setState({
        tallaSeleccionada: talla,
        colorHidden: false,
        colores,
        existenColores,
        footer,
        colorId: '',
        botonBloqueado: true,
        cantidadSeleccionada: '',
        image: imageFile,
      });
    } else {
      for (let i = 0; i < this.state.productos.length; i++) {
        let elements = this.state.productos[i].codigo.split('-');
        let tallaProducto = elements[elements.length - 1];

        if (tallaProducto === talla && this.state.productos[i].color) {
          product.desc_prod = this.state.productos[i].desc_prod;
          product.prec_prod = this.state.productos[i].prec_prod;

          if (colores.length === 0) {
            colores.push({
              id: this.state.productos[i].color,
              pantone: this.state.productos[i].pantone
                ? this.state.productos[i].pantone
                : '',
            });
            // colores.push({
            //     id: this.state.productos[i].color,
            //     class: this.state.productos[i].color.toLowerCase().split(' ').join('_').split('/').join('_')
            // })
          } else {
            var insertar = true;

            for (let index = 0; index < colores.length; index++) {
              if (colores[index].id === this.state.productos[i].color) {
                insertar = false;
                break;
              }
            }

            if (insertar && this.state.productos[i].color !== ' ') {
              colores.push({
                id: this.state.productos[i].color,
                pantone: this.state.productos[i].pantone
                  ? this.state.productos[i].pantone
                  : '',
              });
              // colores.push({
              //     id: this.state.productos[i].color,
              //     class: this.state.productos[i].color.toLowerCase().split(' ').join('_').split('/').join('_')
              // })
            }
          }
        }
      }

      let existenColores = colores.length > 0 ? true : false;

      if (!existenColores) {
        console.log("images8", this.state, imageFile);
        return this.setState({
          product,
          image: imageFile,
          cantidadSeleccionada: '',
          tallaSeleccionada: talla,
          colorHidden: false,
          colores,
          existenColores,
          botonBloqueado: true,
          footer: 'footer-bottom',
        });
      }

      let footer = colores.length > 14 ? 'footer-height' : 'footer-bottom';
      console.log("images9", this.state, imageFile);
      return this.setState({
        product,
        image: imageFile,
        cantidadSeleccionada: '',
        tallaSeleccionada: talla,
        colorHidden: false,
        colores,
        existenColores,
        footer,
        colorId: '',
        botonBloqueado: true,
      });
    }
  };

  getColoresHex = () => {
    return new Promise(async (resolve) => {
      // obtencion hexadecimal de pantones
      let colores = this.state.colores;
      try {
        for (let i = 0; i < colores.length; i++) {
          let _pantones = null;
          let backColor = [];
          // if(colores[i].pantones != undefined) {
          if (colores[i].pantone.includes(',')) {
            _pantones = colores[i].pantone.split(',');
            for (let z = 0; z < _pantones.length; z++) {
              let hexColor = this.state.catalog.pantones.filter(
                (response) => response.label === _pantones[z]
              );
              backColor = backColor.concat(hexColor);
            }
            let backLinear = 'linear-gradient(90deg, ';
            let backPorcent = 100 / backColor.length;
            for (let y = 0; y < backColor.length; y++) {
              if (y === backColor.length - 1) {
                backLinear = `${backLinear}${backColor[y].hex} ${backPorcent}%)`;
              } else {
                backLinear = `${backLinear}${backColor[y].hex} ${backPorcent}%,`;
              }
            }
            backColor = backLinear;
            colores[i].hex = backColor;
          } else {
            backColor = this.state.catalog.pantones.filter(
              (response) => response.label === colores[i].pantone
            );
            // backColor = (backColor.length==1) ? backColor[0].hex : '#000';
            if (backColor.length === 1) {
              backColor = backColor[0].hex;
            } else {
              // buscar imagen en caso de encontrar pantones hex
              if (!colores[i].pantone) {
                let imgName = this.state.productos.filter(
                  (response) => response.color === colores[i].id
                );
                if (imgName.length > 0) {
                  imgName = imgName[0].image;
                  let rutaImg = `${config.filesUrlEspeciales}${imgName}.jpg`;
                  let response = await this.fileService.checkFile(rutaImg);
                  if (response.status !== -1) {
                    backColor = `url('${rutaImg}')`;
                  } else {
                    backColor = '#000';
                  }
                } else {
                  backColor = '#000';
                }
              } else {
                backColor = '#000';
              }
            }
            colores[i].hex = backColor;
          }
          // }
        }
      } catch (e) {
        console.log(e);
      } finally {
        resolve(colores);
      }
    });
  };

  onChangeColor = async (color, index, producto, tallaSeleccionada) => {
    await this.getColoresHex();
    let element;

    for (let index = 0; index < this.state.productos.length; index++) {
      element = this.state.productos[index];
    }

    let { existencia, existencia2 } = await this.getExistencias(
      element.cve_prod,
      element.cse_prod,
      color.id
    );

    let fileService = new FileService();
    let rutaImagen = this.state.imageRute;
    // let imageFile = `${this.imageRutaBase}${this.state.modelId}/${color.id}.jpg`
    while (rutaImagen.includes(' ')) {
      rutaImagen = rutaImagen.replace(' ', '%20');
    }
    let imageFile = `${this.imageRutaBase}${rutaImagen}/${color.id}.jpg`;
    let response = await fileService.checkFile(imageFile);
    if (response.status === -1) {
      if (!producto.color) {
        imageFile = `${this.imageRutaBase}${rutaImagen}/${this.state.modelAux}.jpg`;
        response = await fileService.checkFile(imageFile);
      }
    }
    imageFile = response.status === 200 ? imageFile : this.state.imageNotFound;
    try {
      console.log("images0", this.state, imageFile);
      this.setState({
        cantidadSeleccionada: '',
        colorId: color.id,
        // colores,
        botonBloqueado: false,
        existencia: existencia,
        existencia2: existencia2,
        image: imageFile,
      });
    } catch (e) {}
    this.scrolloToBottom();
  };

  onChangeColorTallas = async (color, index, producto, tallaSeleccionada) => {
    await this.getColoresHex();
    let element;

    for (let index = 0; index < this.state.productos.length; index++) {
      element = this.state.productos[index];
    }

    let { existencia, existencia2 } = await this.getExistencias(
      element.cve_prod,
      element.cse_prod,
      color.id
    );

    let fileService = new FileService();
    let rutaImagen = this.state.imageRute;
    // let imageFile = `${this.imageRutaBase}${this.state.modelId}/${color.id}.jpg`
    while (rutaImagen.includes(' ')) {
      rutaImagen = rutaImagen.replace(' ', '%20');
    }
    let imageFile = `${this.imageRutaBase}${rutaImagen}/${color.id}.jpg`;
    let response = await fileService.checkFile(imageFile);
    if (response.status === -1) {
      if (!producto.color) {
        imageFile = `${this.imageRutaBase}${rutaImagen}/${this.state.modelAux}.jpg`;
        response = await fileService.checkFile(imageFile);
      }
    }
    imageFile = response.status === 200 ? imageFile : this.state.imageNotFound;
    try {
      console.log("images1", this.state, imageFile);
      this.setState({
        cantidadSeleccionada: '',
        colorId: color.id,
        // colores,
        botonBloqueado: false,
        existencia: existencia,
        existencia2: existencia2,
        image: imageFile,
      });
    } catch (e) {}
    this.scrolloToBottom();
  };

  getTallasExistencias = async (color, productId = 0 ) => {
    return new Promise(async (resolve) => {
      let existenciaTallas = [];
      let productosTallas = [];
      let new_med = null;
      if ( this.state.product.cve_prod   )
      {
      // busqueda de color por excepcion
        for (let i = 0; i < this.state.exceptColorNewMedList.length; i++) {
          if (
            this.state.product.desc_prod === this.state.exceptColorNewMedList[i]
          ) {
            new_med = color;
            // eslint-disable-next-line no-loop-func
            color = this.state.productos.filter((item) => item.new_med === color);
            if (color.length > 0) {
              color = color[0].color;
            }
          }
        }
        for (let i = 0; i < this.state.productos.length; i++) {
          let element = this.state.productos[i];
          if (element.color === color || color === 'SC') {
            // or -> para excepcion cuando es unitalla
            element.existencias = await this.getInventoryByProductId(
              element.cve_prod,
              false
            );
            existenciaTallas.push(element);
          }
        }

        // ordenamiento por talla
        for (let i = 0; i < this.state.tallas.length; i++) {
          let valorTalla = this.state.tallas[i].value;
          let productoTalla = existenciaTallas.filter(
            (response) => response.talla === valorTalla
          );
          if (productoTalla.length === 1) {
            productosTallas = productosTallas.concat(productoTalla);
          }
        }

        // ordenamiento por talla
        productosTallas = existenciaTallas.sort((a, b) => {
          console.log(this.state.catalog.sizes, a, b);
          try {
            const { order: aOrderSize } = this.state.catalog.sizes.find(
              ({ name: nameSize }) => nameSize === a.name
            );
            const { order: bOrderSize } = this.state.catalog.sizes.find(
              ({ name: nameSize }) => nameSize === b.name
            );
            return aOrderSize - bOrderSize;
          }
          catch (e)
          {
            return 0;
            console.error(e);
          }
        });

        const [firtProduct] = productosTallas || [];
        const route = this.state.imageRute.toUpperCase().replaceAll("/", "%2F").replaceAll(" ", "-");
        //console.log(this.state, firtProduct, `${this.state.url}${route}${firtProduct.cve_prod}.png${this.state.urlPass}`);

        let currentState = {
          colorSeleccionado: new_med ? new_med + ' - ' + color : color,
          colorHidden: false,
          existenColores: this.state.colores.length > 0 ? true : false,
          botonBloqueado: false,
          currentNewMed: new_med,
          currentColor: color
        }
        console.log("current", this.state.currentColor,this.state.currentNewMed,"color-newmed", new_med, color);
        if ( (new_med && this.state.currentNewMed !== new_med) || this.state.currentColor !== color )
        {
          currentState.image = `${this.state.url}${route}${firtProduct.cve_prod}.png${this.state.urlPass}`;
        }
        console.log(currentState);
        this.setState(currentState);
        resolve(productosTallas);
      }
      else
      {
        if ( productId != 0 )
        {
          const inventory = await this.getInventoryByProductId(
            productId,
            false
          );
          console.log(inventory);
          resolve(inventory);
        }
      }
      resolve([]);
    });
  };

  getInventoryAndPrices = async (productId) => {
    return new Promise(async (resolve) => {
      const existencias = await qad.catalog.inventory.findById(productId);
      const priceList = await qad.catalog.priceList.findByProductId(productId);
      resolve({existencias, priceList});
    });
  };

  getProductURLImage = (imageFile) => {
    const iRoute = this.state.imageRute.split("/");
    console.log(imageFile, iRoute);
    let route = "";
    iRoute.forEach(function(element){
      if (element !== "")
      {
        route += "%2F"+element.replaceAll(" ", "-").toUpperCase(); 
      }
    });
    return `${this.state.url}${route}%2F${imageFile}.png${this.state.urlPass}`;
  }

  onChangeCantidad = (event) => {
    let cantidadSeleccionada = event.target.value;

    this.setState({ cantidadSeleccionada });
  };

  hiddenPedido = () => {
    this.setState({ showPedido: false });
  };

  getArticulosRelacionados = async (models, cse_prod) => {
    let articulos = [];
    if (models === undefined)
    {
      models = "";
    }
    const modelsSplitted = models.split(',').map((item) => item.trim());
    const patternIds = [];
    for (const modelName of modelsSplitted) {
      const pattern = this.state.catalog.patterns.find(
        ({ name }) => name === modelName
      );
      if (pattern) {
        patternIds.push(pattern.id);
      }
    }
    const patterns = patternIds.join(', ');

    try {
      const relatedProductsByModels = await qad.catalog.product.relatedBy({
        modelId: patterns,
      });

      articulos = relatedProductsByModels
        .reduce(
          (unique, item) =>
            unique.map(({ modelId }) => modelId).includes(item.modelId)
              ? unique
              : [...unique, item],
          []
        )
        .map(
          ({ categoryId, brandId, genderId, styleId, modelId, isActive }) => {
            return {
              cse_prod: null,
              is_active: isActive,
              id_categoria: categoryId,
              id_estilo: styleId,
              id_genero: genderId,
              id_marca: brandId,
              modelo: modelId,
              nombre_modelo: this.state.catalog.patterns.find(
                (pattern) => pattern.id === modelId
              )?.name,
              nombre_categoria: this.state.catalog.categories.find(
                (category) => category.id === categoryId
              )?.name,
              nombre_genero: this.state.catalog.genders.find(
                (gender) => gender.id === genderId
              )?.name,
              nombre_marca: this.state.catalog.brands.find(
                (brand) => brand.id === brandId
              )?.name,
              nombre_estilo: this.state.catalog.styles.find(
                (style) => style.id === styleId
              )?.name,
            };
          }
        )
        .filter((item) => item.nombre_modelo && item.is_active === 'SI');
    } catch (exception) {
      articulos = [];
    } finally {
      return articulos;
    }
  };

  getMarcasRelacionadas = async (marca) => {
    let articulos = [];
    try {
      const relatedProductsByBrand = await qad.catalog.product.relatedBy({
        brandId: marca,
      });
      articulos = relatedProductsByBrand
        .reduce(
          (unique, item) =>
            unique.map(({ modelId }) => modelId).includes(item.modelId)
              ? unique
              : [...unique, item],
          []
        )
        .map(
          ({ categoryId, brandId, genderId, styleId, modelId, isActive }) => {
            return {
              cse_prod: null,
              is_active: isActive,
              id_categoria: categoryId,
              id_estilo: styleId,
              id_genero: genderId,
              id_marca: brandId,
              modelo: modelId,
              nombre_modelo: this.state.catalog.patterns.find(
                (pattern) => pattern.id === modelId
              )?.name,
              nombre_categoria: this.state.catalog.categories.find(
                (category) => category.id === categoryId
              )?.name,
              nombre_genero: this.state.catalog.genders.find(
                (gender) => gender.id === genderId
              )?.name,
              nombre_marca: this.state.catalog.brands.find(
                (brand) => brand.id === brandId
              )?.name,
              nombre_estilo: this.state.catalog.styles.find(
                (style) => style.id === styleId
              )?.name,
            };
          }
        )
        .filter((item) => item.nombre_modelo && item.is_active === 'SI');
    } catch (exception) {
      console.log(exception);
    } finally {
      return articulos;
    }
  };

  onClickElement = async (element) => {
    this.setState({ selectCategory: true, loading: true });
    let id = element.id || element.modelo;
    let nombre = element.nombre || element.nombre_modelo;
    let data = await this.getCategoryData(element.tipo, id, nombre);

    return data;
  };

  getCategoryData = async (category, id, name) => {
    // despliegue de imagenes en menu principal
    switch (category) {
      case 'familia':
        if (name && name !== 'Otros') {
          this.setState({
            imageRute: this.state.imageRute + `${name}/`,
          });
        } else {
          this.setState({
            imageRute: '',
          });
        }
        this.setState({
          countOtros: 0,
        });
        break;
      case 'marca':
        if (name && name !== 'Otros') {
          this.setState({
            imageRute: this.state.imageRute + `${name}/`,
          });
        } else {
          // con marca undefined
          let newRute = this.state.imageRute.split('/');
          newRute = newRute[0] + '/';
          this.setState({
            imageRute: newRute,
          });
        }
        break;
      case 'genero':
        if (name && name !== 'Otros') {
          this.setState({
            imageRute: this.state.imageRute + `${name}/`,
          });
        } else {
          // con genero undefined
          let newRute = this.state.imageRute.split('/');
          newRute = newRute[0] + '/' + newRute[1] + '/';
          this.setState({
            imageRute: newRute,
            countOtros: 1,
          });
        }
        break;
      case 'estilo':
        if (name && name !== 'Otros') {
          this.setState({
            imageRute: this.state.imageRute + `${name}/`,
          });
        } else if (name !== 'Otros' && name === undefined) {
          // con estilo undefined
          let newRute = this.state.imageRute.split('/');
          newRute = newRute[0] + '/' + newRute[1] + '/' + newRute[2] + '/';
          this.setState({
            imageRute: newRute,
          });
        } else {
          this.setState({
            countOtros: this.state.countOtros === 1 ? 2 : 1,
          });
        }
        break;
      case 'modelo':
        if (name && name !== 'Otros') {
          this.setState({
            imageRute: this.state.imageRute + `${name}/`,
          });
        } else if (name === undefined) {
          // con modelo undefined
          let newRute = this.state.imageRute.split('/');
          if (this.state.countOtros === 1) {
            newRute = newRute[0] + '/' + newRute[1] + '/' + newRute[2] + '/';
          } else if (this.state.countOtros === 2) {
            newRute = newRute[0] + '/' + newRute[1] + '/';
          } else {
            newRute = newRute[0] + '/' + newRute[1] + '/' + newRute[2] + '/' + newRute[3] + '/';
          }
          this.setState({
            imageRute: newRute,
          });
        }
        break;
      default:
        // Do nothing
        break;
    }
    const formatElementsToCategoryView = (elements, typeName) => {
      return elements.map(({ id, imageCloud, name }) => {
        if( true )
        {
          const iRoute = this.state.imageRute.split("/");
          let route = "";
          iRoute.forEach(function(element){
            if (element !== "")
            {
              route += "%2F"+element.replaceAll(" ", "-").toUpperCase(); 
            }
          });
          imageCloud = `${this.state.url}${route}%2F${name}.png${this.state.urlPass}`;
        }
        return { id, imageCloud, nombre: name, tipo: typeName};
      });
    }
    if (category === 'familia') {
      if (this.state.brandsClass === 'active') {
        let elements = this.state.catalog.formattedCategories;
        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        return this.setState({
          elements,
          brandsClass: '',
          gendersClass: '',
          stylesClass: '',
          modelsClass: '',
          productsClass: '',
          familyName: '',
          brandsName: '',
          gendersName: '',
          stylesName: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          familyId: id,
          footer,
          loading: false,
        });
      } else {
        let elements = await qad.catalog.brand.relatedBy({ categoryId: id });

        elements = elements.map((brand) => {
          let urlImg = `${this.state.url}%2F${name}%2F${brand.name}.png${this.state.urlPass}`;
          return {
            ...brand,
            imageCloud: urlImg,
          };
        });

        console.log('ELEMENTS', elements);

        elements = formatElementsToCategoryView(elements, 'marca');

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';
        this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: '',
          stylesClass: '',
          modelsClass: '',
          productsClass: '',
          familyName: name,
          brandsName: '',
          gendersName: '',
          stylesName: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          familyId: id,
          footer,
          loading: false,
        });
        if (elements.length === 1) {
          if (
            elements[0].nombre === 'Otros' ||
            elements[0].nombre === 'otros'
          ) {
            this.getCategoryData('marca', elements[0].id, elements[0].nombre);
          }
          this.onClickElement(elements[0]);
        }
      }
    } else if (category === 'marca') {
      if (this.state.gendersClass === 'active') {
        let elements = await qad.catalog.brand.relatedBy({ categoryId: id });
        elements = formatElementsToCategoryView(elements, 'marca');

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: '',
          stylesClass: '',
          modelsClass: '',
          productsClass: '',
          brandsName: '',
          gendersName: '',
          stylesName: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          brandId: id,
          footer,
          loading: false,
        });

        if (elements.length === 1) {
          if (
            elements[0].nombre === 'Otros' ||
            elements[0].nombre === 'otros'
          )
          {
            this.getCategoryData(
              'familia',
              this.state.familyId,
              elements[0].nombre
            );
          }
          this.onClickElement(elements[0]);
        }
      } else {
        let elements;
        try {
          elements = await qad.catalog.gender.relatedBy({
            categoryId: this.state.familyId,
            brandId: id,
          });
          elements = formatElementsToCategoryView(elements, 'genero');
        } catch (error) {
          elements = [{ id: '0', nombre: 'Otros', tipo: 'genero' }];
        }

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: 'active',
          stylesClass: '',
          modelsClass: '',
          productsClass: '',
          brandsName: name,
          gendersName: '',
          stylesName: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          brandId: id,
          footer,
          loading: false,
        });

        if (elements.length === 1) {
          if (
            elements[0].nombre === 'Otros' ||
            elements[0].nombre === 'otros'
          ) {
            this.getCategoryData('genero', elements[0].id, elements[0].nombre);
          }
          this.onClickElement(elements[0]);
        }
      }
    } else if (category === 'genero') {
      if (this.state.stylesClass === 'active') {
        let elements;
        try {
          elements = await qad.catalog.gender.relatedBy({
            categoryId: this.state.familyId,
            brandId: id,
          });
          elements = formatElementsToCategoryView(elements, 'genero');
        } catch (error) {
          elements = [{ id: '0', nombre: 'Otros', tipo: 'genero' }];
        }

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: 'active',
          stylesClass: '',
          modelsClass: '',
          productsClass: '',
          gendersName: '',
          stylesName: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          genderId: id,
          footer,
          loading: false,
        });

        if (elements.length === 1) {
          if (
            elements[0].nombre === 'Otros' ||
            elements[0].nombre === 'otros'
          ) {
            this.getCategoryData(
              'marca',
              this.state.familyId,
              elements[0].nombre
            );
          }
          this.onClickElement(elements[0]);
        }
      } else {
        let elements;
        try {
          elements = await qad.catalog.style.relatedBy({
            brandId: this.state.brandId,
            categoryId: this.state.familyId,
            genderId: id,
          });
          if (elements && elements.length === 1 && !elements[0].id) {
            elements = [{ id: '0', nombre: 'Otros', tipo: 'estilo' }];
          }
          elements = formatElementsToCategoryView(elements, 'estilo');
        } catch (error) {
          elements = [{ id: '0', nombre: 'Otros', tipo: 'estilo' }];
        }

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: 'active',
          stylesClass: 'active',
          modelsClass: '',
          productsClass: '',
          gendersName: name,
          stylesName: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          genderId: id,
          footer,
          loading: false,
        });
        console.log(elements);
        if (elements.length === 1) {
          if (
            elements[0].nombre === 'Otros' ||
            elements[0].nombre === 'otros'
          )
          {
            this.getCategoryData('estilo', elements[0].id, elements[0].nombre);
          }
          this.onClickElement(elements[0]);
        }
      }
    } else if (category === 'estilo') {
      if (this.state.modelsClass === 'active') {
        let elements;
        try {
          elements = await qad.catalog.style.relatedBy({
            brandId: this.state.brandId,
            categoryId: this.state.familyId,
            genderId: id,
          });
          if (elements && elements.length === 1 && !elements[0].id) {
            elements = [{ id: '0', nombre: 'Otros', tipo: 'estilo' }];
          }
          elements = formatElementsToCategoryView(elements, 'estilo');
        } catch (error) {
          elements = [{ id: '0', nombre: 'Otros', tipo: 'estilo' }];
        }

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: 'active',
          stylesClass: 'active',
          modelsClass: '',
          productsClass: '',
          stylesName: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          styleId: id,
          footer,
          loading: false,
        });
        if (elements.length === 1) 
        {
          if (
            elements[0].nombre === 'Otros' ||
            elements[0].nombre === 'otros'
          )
          {
            this.getCategoryData(
              'genero',
              this.state.brandId,
              elements[0].nombre
            );
          }
          this.onClickElement(elements[0]);
        }
      } else {
        let elements = await qad.catalog.pattern.relatedBy({
          brandId: this.state.brandId,
          categoryId: this.state.familyId,
          genderId: this.state.genderId,
          styleId: id,
        });
        elements = formatElementsToCategoryView(elements, 'modelo');

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: 'active',
          stylesClass: 'active',
          modelsClass: 'active',
          productsClass: '',
          stylesName: name,
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          styleId: id,
          footer,
          loading: false,
        });
        console.log(elements);
        if (elements.length === 1)
        {
          if (
            elements[0].nombre === 'Otros' ||
            elements[0].nombre === 'otros'
          ) 
          {
            this.getCategoryData('modelo', elements[0].id, elements[0].nombre);
          }
          this.onClickElement(elements[0]);
        }
      }
    } 
    else if (category === 'modelo')
    {
      await this.hasPricelistReady();
      if (this.state.productsClass === 'active') {
        let elements;
        try {
          elements = await qad.catalog.pattern.relatedBy({
            brandId: this.state.brandId,
            categoryId: this.state.familyId,
            genderId: this.state.genderId,
            styleId: id,
          });
          if (elements && elements.length === 1 && !elements[0].id) {
            elements = [{ id: '0', nombre: 'Otros', tipo: 'modelo' }];
          }
          elements = formatElementsToCategoryView(elements, 'modelo');
        } catch (error) {
          elements = [{ id: '0', nombre: 'Otros', tipo: 'modelo' }];
        }

        let footer = elements.length > 18 ? 'footer-height' : 'footer-bottom';

        return this.setState({
          elements,
          brandsClass: 'active',
          gendersClass: 'active',
          stylesClass: 'active',
          modelsClass: 'active',
          productsClass: '',
          modelsName: '',
          productsName: '',
          showProduct: false,
          showPedido: false,
          modelId: id,
          footer,
          loading: false,
        });
      }
      else
      {
        let productsRelated = await qad.catalog.product.relatedBy({
          categoryId: this.state.familyId,
          brandId: this.state.brandId,
          genderId: this.state.genderId,
          styleId: this.state.styleId,
          modelId: id,
        });

        productsRelated = productsRelated.filter(
          (item) => item.isActive === 'SI'
        );

        const sizesFound = [
          ...new Set(
            productsRelated.map(({ sizeId }) => {
              return (
                this.state.catalog.sizes.find((size) => size.id === sizeId)
                  ?.name || ''
              );
            })
          ),
        ];

        const colorsFound = [
          ...new Set(
            productsRelated.map(({ colorId }) => {
              return (
                this.state.catalog.colors.find((color) => color.id === colorId)
                  ?.label || colorId
              );
            })
          ),
        ];
        productsRelated.sort( (a, b) => a.sizeOrder - b.sizeOrder );
        let elements = {
          productos: productsRelated.map(
            ({
              article,
              colorId,
              description1st,
              description2nd,
              id,
              imageCloud,
              longDescription,
              modelId,
              modelsRelated,
              sizeId,
              sizeName,
              colorName
            }) => {
              const colorFound = this.state.catalog.colors.find(
                (color) => color.id === colorId
              );
              let descripcion = `${description1st} ${description2nd}`.split(
                ' '
              );
              descripcion.splice(-1, 1);
              descripcion = descripcion.join(' ');
              //let pantone = JSON.parse(colorFound?.pantone).join(",")
              let pantone = [];
              try 
              {
                pantone = ( colorFound && colorFound?.pantone.includes("[") ) ? JSON.parse(colorFound?.pantone) : [ colorFound?.pantone ];  
              }
              catch (error)
              {
                console.log(error, colorFound?.pantone);
              }
              //console.log(pantone);
              return {
                desc_prod: longDescription,
                descripcion,
                codigo: article,
                color: colorFound?.label || colorId,
                new_med: colorFound?.name,
                pantone: pantone.join(","),
                image: null,
                imageCloud,
                priceList: this.state.catalog.priceList
                  .filter(
                    ({ productId: priceListProductId }) =>
                      priceListProductId === id
                  )
                  .map((priceList) => {
                    return {
                      ...priceList,
                      price: priceList.price,
                    };
                  }),
                prec_prod: `${10.0}`,
                prec_prod2: `${10.0}`,
                prec_yelos: `${10.0}`,
                modelo:
                  this.state.catalog.patterns.find(
                    (pattern) => pattern.id === modelId
                  )?.name || modelId,
                modeloId: modelId,
                tipo: 'articulo',
                cve_prod: id,
                cse_prod: '',
                modelosRelacionados: modelsRelated,
                existencias: this.getInventoryByProductId(id),
                talla: sizeName || 'UNITALLA',
                colorName
              };
            }
          ),
          tallas: sizesFound,
          colores: colorsFound,
        };

        // let fileService = new FileService();
        let colorSizes = []; // colores para productos con talla
        let footer;
        this.setState({ modelAux: id });
        const [firstProduct] = elements?.productos || [];
        //console.log( "firstProd", firstProduct);
        const route = this.state.imageRute.replaceAll("/","%2F").replaceAll(" ", "-");
        let imageFile = `${this.state.url}${route}%2F${firstProduct?.cve_prod}.png${this.state.urlPass}`;
        if (imageFile) {
          footer = 'footer-height';
        } else {
          footer = 'footer-bottom';
          imageFile = this.state.imageNotFound;
        }

        // Colores
        //    if (elements.colores.length === 1 && elements.colores[0] === null) {
        if (elements.colores.length === 0) {
          //    elements.colores[0] = {
          //        color: 'sc',
          //        id: 'SC'
          //    }
          elements.colores.push({
            color: 'sc',
            id: 'SC',
          });
        }

        // Tallas
        let tallas = elements.tallas.map((talla) => {
          return { label: talla, value: talla };
        });

        let tallaSeleccionada = '';

        if (tallas.length === 0) {
          tallas.push({label:"NONE", value: "NONE"});
        }
        let currentState = {
          elements,
          brandsClass: 'active',
          gendersClass: 'active',
          stylesClass: 'active',
          modelsClass: 'active',
          productsClass: 'active',
          modelsName: name,
          productsName: '',
          tallaSeleccionada,
          modelId: id,
          colorId: '',
          scroll: 0,
          showProduct: true,
          showPedido: false,
          colorHidden: true,
          product: elements.productos[0],
          productos: elements.productos,
          //tallas: tallas,
          colores: colorSizes,
          coloresServicio: elements.colores ? elements.colores : [null],
          footer,
          loading: false,
          image: imageFile,
          currentNewMed: firstProduct?.new_med,
          currentColor: firstProduct?.color
        };

        if ( !tallas[0].label) {
          tallas[0].label = 'UNITALLA';
          tallas[0].value = 'UNITALLA';
          tallaSeleccionada = 'UNITALLA';
          currentState.tallas = tallas;
          this.setState(currentState);
          return this.onChangeTallaValue(tallaSeleccionada, elements);
        } 
        else 
        {
          for (let i = 0; i < elements.colores.length; i++) {
            for (let x = 0; x < elements.productos.length; x++) {
              if (elements.productos[x].color === elements.colores[i]) {
                colorSizes.push({
                  id: elements.productos[x].color,
                  pantone: elements.productos[x].pantone,
                });
                break;
              }
            }
          }
          // ordenamiento de tallas
          currentState.tallas = tallas.sort((a, b) => a.order - b.order);
          currentState.colores = colorSizes;
        }
        
        return this.setState(currentState);
      }
    }
  };

  getInventoryByProductId = async (productId, ofState = true) => {
    let productInventory;
    if (ofState) {
      productInventory = this.state.inventories.filter(
        ({ productId: inventoryProductId }) => inventoryProductId === productId
      );
    } else {
      productInventory = await qad.catalog.inventory.findById(productId);
    }
    return Object.entries(this.state.catalog.locations_available).map(
      ([locationName, locationsGroup]) => {
        const site = [...new Set(locationsGroup.map(({ site }) => site))].join(
          ', '
        );
        const location = [
          ...new Set(locationsGroup.map(({ location }) => location)),
        ].join(', ');
        const foundProductInventories = productInventory.filter(
          ({ site: inventorySite, location: inventoryLocation }) => {
            return locationsGroup.some(
              ({ site, location }) =>
                site === inventorySite && location === inventoryLocation
            );
          }
        );

        const [firstFoundProductInventories] = foundProductInventories;
        const totalStockLocationGroup = foundProductInventories.reduce(
          (a, b) => a + parseFloat(b.stock),
          0
        );
        return {
          productId,
          site,
          location,
          locations_available_name: locationName,
          stock: totalStockLocationGroup,
          estimatedDate: firstFoundProductInventories?.estimatedDate || '',
        };
      }
    );
  };

  changeProductViewIds = async (
    idCategoria,
    nombreCategoria,
    idMarca,
    nombreMarca,
    idGenero,
    nombreGenero,
    idEstilo,
    nombreEstilo,
    modelo
  ) => {
    let promise1 = new Promise((resolve, reject) => {
      resolve(
        this.setState({
          productsClass: '',
          familyId: idCategoria,
          familyName: nombreCategoria,
          brandId: idMarca,
          brandsName: nombreMarca,
          genderId: idGenero,
          gendersName: nombreGenero,
          styleId: idEstilo || 0,
          stylesName: idEstilo ? nombreEstilo : 'OTROS',
        })
      );
    });
    return promise1;
  };
  
  insertIntoLocalStorage = async (producto) => {
    try {
      if (producto.cantidad) {
        if (producto.inventario <= 0) return this.clickButton('existencia');
        if (producto.cantidad > producto.inventario)
          return this.clickButton('stock');

        let productosLocalStorage = JSON.parse(
          localStorage.getItem('productosLocalStorage')
        );
        let productoSeleccionado;
        if ( producto.codigo ) 
        {
          productoSeleccionado = Object.assign({}, producto); 
        }
        else
        {
          for (let index = 0; index < this.state.productos.length; index++) {
          let element = this.state.productos[index];
          let talla = element['talla'];
          if (
            (element.color === producto.color || producto.color === 'SC') &&
            (talla === producto.talla || producto.talla === 'UNITALLA')
          ) {
            productoSeleccionado = Object.assign({}, element);
            console.log( "inserted", element, producto);
            productoSeleccionado.cantidad = producto.cantidad;
            productoSeleccionado.talla = producto.talla;
            if (productoSeleccionado.codigo === 'KIT PELLON') {
              productoSeleccionado.codigo = 'PELLONCHICO';
              productoSeleccionado.modelo = 'PELLONCHICO';
              productoSeleccionado.cantidadKit = 3;
            } else if (
              productoSeleccionado.codigo.includes('CAJAMOR001') ||
              productoSeleccionado.codigo.includes('CAJAMOR006') ||
              productoSeleccionado.codigo.includes('CAJAMOR007') ||
              productoSeleccionado.codigo.includes('CAJAMOR008') ||
              productoSeleccionado.codigo.includes('CAJAMOR009')
            ) {
              productoSeleccionado.cantidadKit = 200;
              //    productoSeleccionado.cantidadKit = 1;
            } else if (productoSeleccionado.codigo.includes('CAJAMOR003')) {
              productoSeleccionado.cantidadKit = 400;
              //    productoSeleccionado.cantidadKit = 1;
            }

            // if (producto.color === 'SC') {
            //     productoSeleccionado.color = 'SC'
            // }

            break;
          }
        }
        }
        if (productoSeleccionado)
        {
          let check_producto = null;
          if (productosLocalStorage)
            {
            // verificacion de producto exitente ya en lista de pedido
            check_producto = this.checarProductoPedido(
              productoSeleccionado,
              productosLocalStorage
            );
            if (check_producto) 
            {
              let modCantProd = this.modCantidadProductoPedido(
                productoSeleccionado,
                productosLocalStorage,
                producto.inventario
              );
              if (!modCantProd.mod) {
                return this.clickButton('stock_articulo');
              } else {
                productosLocalStorage = modCantProd.productos;
              }
            } else {
              productosLocalStorage.push(productoSeleccionado);
            }
          } else {
            productosLocalStorage = [productoSeleccionado];
          }
          //console.log(productosLocalStorage);
          localStorage.setItem(
            'productosLocalStorage',
            JSON.stringify(productosLocalStorage)
          );

          let { cantidad, total } = this.getProductos();
          let tallaSeleccionada;

          if (producto.talla === 'UNITALLA') {
            tallaSeleccionada = 'UNITALLA';
            this.onChangeTallaValue(tallaSeleccionada);
          } else {
            tallaSeleccionada = '';
          }
          this.setState({
            productosLocalStorage: productosLocalStorage,
            tallaSeleccionada,
            colorSeleccionado: (this.state.colorSeleccionado) ? this.state.colorSeleccionado : producto.color ,
            cantidadSeleccionada: '',
            botonBloqueado: false,
            colorHidden: false,
            existenColores: true,
            cantidad,
            total,
            colorId: (this.state.colorId) ? this.state.colorId : producto.colorId,
            colores: this.state.colores,
          });

          if (check_producto) {
            return this.clickButton('mod_articulo');
          } else {
            return this.clickButton('articulo');
          }
        } else {
          return -1;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  checarProductoPedido = (producto, productosPrev) => {
    // checar si el producto ya existe en el pedido
    let color = producto.color;
    let cse_prod = producto.cse_prod;
    let cve_prod = producto.cve_prod;
    let talla = producto.talla;

    let findArrayReg = productosPrev.filter(
      (res) =>
        res.color === color &&
        res.cse_prod === cse_prod &&
        res.cve_prod === cve_prod &&
        res.talla === talla
    );

    return findArrayReg.length === 0 ? false : true;
  };

  modCantidadProductoPedido = (producto, productosPrev, inventario) => {
    // modificacion de cantidad de un producto ya en el carrito
    let color = producto.color;
    let cse_prod = producto.cse_prod;
    let cve_prod = producto.cve_prod;
    let talla = producto.talla;
    let cantidad = producto.cantidad;

    let indexProd = productosPrev.findIndex(
      (res) =>
        res.color === color &&
        res.cse_prod === cse_prod &&
        res.cve_prod === cve_prod &&
        res.talla === talla
    );

    let cantidadFinalProd =
      parseFloat(productosPrev[indexProd].cantidad) + cantidad;

    if (cantidadFinalProd <= inventario) {
      // actualizacion de cantidad a producto existente
      productosPrev[indexProd].cantidad = cantidadFinalProd;
      return { productos: productosPrev, mod: true };
    } else {
      // mandar alerta de cantidad sobrepasada
      return { productos: productosPrev, mod: false };
    }
  };

  setPriceProductosLocalStorage = (element, index) => {
    let productsLocalStorage = JSON.parse(
      localStorage.getItem('productosLocalStorage')
    );
    productsLocalStorage[index] = Object.assign(
      productsLocalStorage[index],
      element
    );
    localStorage.setItem(
      'productosLocalStorage',
      JSON.stringify(productsLocalStorage)
    );

    let productosData = this.getProductos();

    let { productosLocalStorage, total, cantidad } = productosData;

    this.setState({
      productosLocalStorage,
      total,
      cantidad,
    });
  };

  regresarArticulos = (event) => {
    this.setState({
      footer: 'footer-bottom',
      showPedido: false,
      showKeyboard: false,
    });
  };

  onChangeComentarioPedido = (event) => {
    let comentarioPedido = event.target.value;

    return this.setState({ comentarioPedido });
  };

  onChangeDescuento = (event) => {
    let descuentoPedido = event.target.value;

    if (Number(descuentoPedido) <= 99) {
      return this.setState({ descuentoPedido });
    }

    return -1;
  };

  eliminarPartida = (partida) => {
    let productosLocal = JSON.parse(
      localStorage.getItem('productosLocalStorage')
    );
    let productosLocalFilter = [];
    let deleted = false;

    for (let index = 0; index < productosLocal.length; index++) {
      let articulo = productosLocal[index];

      if (
        articulo.codigo === partida.codigo &&
        articulo.color === partida.color &&
        articulo.talla === partida.talla &&
        articulo.cantidad === partida.cantidad
      ) {
        if (deleted) {
          productosLocalFilter.push(articulo);
        } else {
          deleted = true;
        }
      } else {
        productosLocalFilter.push(articulo);
      }
    }

    localStorage.setItem(
      'productosLocalStorage',
      JSON.stringify(productosLocalFilter)
    );

    let productosData = this.getProductos();

    let { productosLocalStorage, total, cantidad } = productosData;

    let footer =
      productosLocalFilter.length <= 0 ? 'footer-bottom' : 'footer-height';

    this.setState({
      productosLocalStorage,
      total,
      footer,
      cantidad,
    });
  };

  cambiarCantidad = async (cantidadPartida, partida) => {
    let productosLocal = JSON.parse(
      localStorage.getItem('productosLocalStorage')
    );
    let cantidadFinal = 0;
    let change = true;

    if (partida.cve_prod && partida.cse_prod && partida.color) {
      let existencia = await qad.catalog.inventory.getExistencia(
        partida.cve_prod,
        partida.cse_prod,
        partida.color,
        this.userSucursal
      );

      if (typeof existencia.existencias == 'object') {
        let targetExistencia = existencia.existencias.filter((response) => {
          if (this.userSucursal === 'MEX') {
            return response.lugar.includes('GENERAL');
          } else if (this.userSucursal === 'MTY') {
            return response.lugar.includes('MTY');
          }
          return false;
        });

        // obtencion de existencia
        if (targetExistencia.length > 0) {
          existencia = targetExistencia[0].existencia;
        } else {
          existencia = 0;
        }
      } else {
        existencia = existencia.existencias;
      }

      if (Number(existencia) >= cantidadPartida) {
        cantidadFinal = cantidadPartida;
      } else {
        cantidadFinal = partida.cantidad;
      }
    } else {
      cantidadFinal = cantidadPartida;
    }

    productosLocal = productosLocal.map((articulo) => {
      if (
        articulo.codigo === partida.codigo &&
        articulo.color === partida.color &&
        articulo.talla === partida.talla &&
        articulo.cantidad === partida.cantidad &&
        change
      ) {
        articulo.cantidad = cantidadFinal;

        change = false;
        return articulo;
      }

      return articulo;
    });

    cantidadFinal = change ? cantidadFinal.slice(0, -1) : cantidadFinal;

    localStorage.setItem(
      'productosLocalStorage',
      JSON.stringify(productosLocal)
    );

    let productosData = this.getProductos();

    let { productosLocalStorage, total, cantidad } = productosData;

    this.setState({
      productosLocalStorage,
      total,
      cantidad,
    });
    return cantidadFinal;
  };

  setPriceByProduct = async ( price, product) => {
    //console.log("setPriceByProduct", price, product);
    localStorage.setItem("priceFor"+product.cve_prod, price);
  };

  onSelectEnvio = (event) => {
    let envio = event.target.value;

    this.setState({
      envioSeleccionado: envio,
    });
  };

  agregarEnvio = (envioId) => {
    if (envioId !== 0 && envioId !== undefined && envioId !== '0') {
      let envioSeleccionado = {};

      for (let index = 0; index < this.state.envios.length; index++) {
        const element = this.state.envios[index];
        if (element.id === envioId) {
          envioSeleccionado = element;
          break;
        }
      }

      let productosLocalStorage = JSON.parse(
        localStorage.getItem('productosLocalStorage')
      );

      envioSeleccionado.cantidad = 1;
      envioSeleccionado.cantidadview = 1;
      envioSeleccionado.descripcion = envioSeleccionado.desc_prod;
      envioSeleccionado.codigo = envioSeleccionado.cve_prod;

      productosLocalStorage.push(envioSeleccionado);

      localStorage.setItem(
        'productosLocalStorage',
        JSON.stringify(productosLocalStorage)
      );

      let productosData = this.getProductos();

      let { total, cantidad } = productosData;

      this.clickButton('envio');

      return this.setState({
        productosLocalStorage,
        total,
        cantidad,
      });
    }
  };

  setNuevoAgente = (nuevoAgente) => {
    this.setState({
      newAgente: nuevoAgente,
    });
  };

  /**
   * Format order.
   * @param {Object[]} items
   * @param {Object} customer
   * @param {Object} salesPerson
   * @param {Object} [options={}]
   * @param {string} [options.remarks='']
   * @return {Object}
   */
  formatOrder = (items, customer, salesPerson, options = {}) =>  {
    const { remarks = '' } = options;
    const { site: salesPersonSite, salesPersonId: salesPersonId } = salesPerson;
    const { id: customerId, priceLists: priceLists } = customer;
    let internalError = false;
    let errorLine = {};
    const lines = items.map((item) => {
      const {
        cantidad,
        cve_prod: productId,
        prec_set: prec_prod,
        priceList
      } = item;
      if ( priceList.length < 1 )
      {
        internalError = true;
        errorLine  = item;
        return {
          customerId,
          productId,
          quantity: cantidad,
          price: prec_prod
        }
      }
      let price = prec_prod;
      if ( !prec_prod )
      {
        const priceList = priceList.find( ( e )  => e.id === priceLists );
        if ( priceList )
        {
          price = priceList.price;
        }
      }

      return {
        customerId,
        productId,
        quantity: `${cantidad}`,
        price: `${price}`
      };
    });
    return {
      customerId,
      shippingAddressId: customerId, // TODO: Update shippingAddressId
      siteId: salesPersonSite,
      remarks,
      salesPersonId: salesPersonId || 'VSEA',
      source: SOURCE_KEY_DEFAULT,
      appId: 'N/A',
      lines,
      error: internalError,
      errorLine: errorLine
    };
  };

  colocarPedido = async (articulos, withRemission = true) => {
    if (!this.state.cliente) {
      Swal.fire({
          icon: 'error',
          title: `Verificar cliente`,
          html: `<div>
                  <p>Primero seleccione el cliente</p> 
                </div>`,
      });
      return -1;
    }

    this.setState({ loading: true });

    const user = JSON.parse(localStorage.getItem('user'));
    for (const articulo of articulos) 
    {
      // Skip product class service
      if (articulo.cse_prod === 'SERVICIOS' ) {
        articulo.cantidadview = articulo.cantidad;
        continue;
      }
      if ( !withRemission )
      {
        continue;
      }
      const existenciasSucursal = articulo.existencias.filter(
        ({ site }) => site === user.sucursal
      );
      //console.log(existenciasSucursal, articulo.existencias, user);
      const sucursalName = user.sucursal?user.sucursal:'';
      let article = "("+articulo.cve_prod+" - "+articulo.codigo+") - "+articulo.desc_prod;
      if (articulo.color)
      {
        article += " - "+articulo.color;
      }
      if (articulo.talla)
      {
        article += " ("+articulo.talla+")";
      }
      if (existenciasSucursal.length === 0)
      {
        Swal.fire({
          icon: 'error',
          title: `Sin existencia en sucursal(${sucursalName}) para el usuario`,
          html: `<div>
                            <p>El siguiente articulo no cuenta con existencias:</p>
                            <strong>${article}</strong>
                        </div>`,
        }).then(() => {
          this.setState({ loading: false });
        });
        return;
      }
      // modificacion de cantidad en caso de existir kits - PELLONES || CAJA MOR
      articulo.cantidadview = articulo.cantidad; // SAR 20220519
      if (articulo.cantidadKit !== undefined) {
        articulo.cantidad = String(
          parseFloat(articulo.cantidad * articulo.cantidadKit)
        );
      }

      const totalExistencias = existenciasSucursal.reduce(
        (a, b) => a + b.stock,
        0
      );
      if (articulo.cantidad > totalExistencias) {
        Swal.fire({
          icon: 'error',
          title: 'Sin existencia',
          html: `<div>
                            <p>El siguiente articulo no cuenta con existencias:</p>
                            <strong>${article}</strong>
                            <br>
                            <i>Existencia actual: ${totalExistencias}</i>
                        </div>`,
        }).then(() => {
          this.setState({ loading: false });
        });
        return;
      }
      console.log(articulo);
      if (!parseFloat(articulo.prec_prod) ) {
        Swal.fire({
          icon: 'error',
          title: 'No tiene precio asignado',
          html: `<div>
            <p>El siguiente articulo no cuenta con precio asignado:</p>
            <strong>${article}</strong>
            <br>
            <i>Eliminar la linea y volver a agregarla</i>
          </div>`,
        }).then(() => {
          this.setState({ loading: false });
        });
        return;
      }
    }

    const customer = this.state.catalog.customers.find((cliente) => {
      return cliente.id === this.state.cliente.value;
    });
    if (!customer) {
      Swal.fire({
          icon: 'error',
          title: 'Seleccionar Cliente',
          html: `<div>Cliente no Valido</div>`,
        }).then(() => {
          this.setState({ loading: false });
        });
      return -1;
    }
    console.log(customer);

    const formattedOrder = await this.formatOrder(articulos, customer, user, {
      remarks: this.state.comentarioPedido, 
    });
    if ( formattedOrder.error )
    {
      Swal.fire({
          icon: 'error',
          title: 'No tiene precio asignado',
          html: `<div>
            <p>El siguiente articulo no cuenta con precio asignado:</p>
            <strong>${formattedOrder.errorLine.cve_prod} - ${formattedOrder.errorLine.desc_prod}</strong>
            <br>
            <i>Eliminar la linea y volver a agregarla</i>
          </div>`,
        }).then(() => {
          this.setState({ loading: false });
      });
        return -1;
    }
    else
    {
      delete formattedOrder.error;
      delete formattedOrder.errorLine;
    }
    try {
      const orderCreated = await qad.catalog.salesOrder.create(formattedOrder);
      let orderRemision = null;
      if ( orderCreated && !orderCreated.salesOrderId )
      {
        this.setState({ loading: false });
        Swal.fire({
          icon: 'error',
          title: 'No se pudo crear el pedido',
          html: `<div>Intente nuevamente en un momento</div>`,
        });
        return;
        //throw Error( "No se pudo crear el Pedido " );
      }

      if ( withRemission )
      {
        orderRemision = await qad.catalog.salesOrder.toRemision(
          orderCreated.salesOrderId
        );
      }

      localStorage.setItem('productosLocalStorage', JSON.stringify([]));
      
      let date = new Date();
        let formatDate =
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : '0' + (date.getMonth() + 1)) +
          '/' +
          (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
          '/' +
          date.getFullYear();
        var formatTime = new Date()
          .toTimeString()
          .replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
      
      this.setState(
        {
          print: true,
          showKeyboard: false,
          loading: false,
          numeroPedido: orderCreated.salesOrderId,
          numeroRemision: orderRemision?.salesOrdeId || '' ,
          user,
          formatDate,
          formatTime,
        },
        async function () {
          printJS({
            printable: 'printContainer',
            type: 'html',
            targetStyles: ['*'],
          });

          let elements = this.state.catalog.formattedCategories;
          this.setState({
            productosLocalStorage: [],
            total: 0,
            cantidad: 0,
            print: false,
            comentarioPedido: '',
            descuentoPedido: '',
            cliente: '',
            numeroPedido: orderCreated.salesOrderId,
            footer: 'footer-bottom',
            loading: false,
            newAgente: null, // reinicio de parametros
            elements,
            brandsClass: '',
            gendersClass: '',
            stylesClass: '',
            modelsClass: '',
            productsClass: '',
            familyName: '',
            brandsName: '',
            gendersName: '',
            stylesName: '',
            modelsName: '',
            productsName: '',
            showProduct: false,
            showPedido: false,
            imageRute: '',
            familyId: 0,
            brandId: 0,
            genderId: 0,
            styleId: 0,
            modelId: 0,
            productId: 0,
            existencia: 0,
          });
          this.showSendMail();
        }
      );
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: err.message,
        html: `<div>${err.message}</div>`,
      }).then(() => {
        this.setState({ loading: false });
      });
      return this.clickButton('error');
    }
  };

  clickButton = (buttonText) => {
    let buttons = document.getElementsByTagName('button');

    for (var i = 0; i < buttons.length; i++) {
      if (buttons[i].innerHTML === buttonText) {
        buttons[i].click();
      }
    }
  };

  setActiveInput = (input, typeOfKeyboard, component, data) => {
    let value = '';
    let keyboarDimensionClass = `keyboard-${component}`;
    let layout;
    let name;

    if (typeOfKeyboard === 'number') {
      layout = this.keyboard.layouts.numericLayout;
      name = 'number';
    } else {
      layout = this.keyboard.layouts.spanishLayout;
      name = 'spanish';
    }

    if (component === 'pedido' || component === 'producto') {
      value = data.cantidad;
    }

    if (
      component === 'descuento' ||
      component === 'comentarios' ||
      component === 'cliente'
    ) {
      value = data;
    }

    return this.setState({
      keyboard: {
        display: this.keyboard.display,
        component,
        layout,
        value,
        name,
        data,
      },
      showKeyboard: true,
      keyboarDimensionClass,
    });
  };

  scrolloToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  scrolloToTop = () => {
    window.scrollTo(0, 0);
  };

  keyboardPress = async (data) => {
    let keyboard = Object.assign({}, this.state.keyboard);
    let value;

    if (data === '{bksp}') {
      value = this.state.keyboard.value;

      if (this.state.keyboard.value.length >= 1) {
        value = this.state.keyboard.value.slice(0, -1);
      }
    } else if (data === '{clear}') {
      value = '1';
    } else if (data === '{clear2}') {
      value = '';
    } else if (data === '{space}') {
      value = `${this.state.keyboard.value} `;
    } else if (data === '{enter2}') {
      value = `${this.state.keyboard.value} `;
    } else if (data === '{enter}') {
      if (
        this.state.keyboard.value === '0' ||
        this.state.keyboard.value === ''
      ) {
        if (keyboard.component === 'pedido') {
          this.cambiarCantidad('1', this.state.keyboard.data);
        } else if (keyboard.component === 'producto') {
          this.setState({ cantidadSeleccionada: '1' });
        }
      }

      return this.setState({
        keyboard: {
          name: '',
          layout: {},
          display: {},
          data: {},
          value: '',
          component: '',
        },
        showKeyboard: false,
      });
    } else {
      value = this.state.keyboard.value + '' + data;
    }

    if (keyboard.component === 'pedido') {
      value = await this.cambiarCantidad(value, this.state.keyboard.data);
      keyboard.data.cantidad = value;
    }

    if (keyboard.component === 'producto') {
      this.setState({ cantidadSeleccionada: value });
    }

    if (keyboard.component === 'descuento' && Number(value) <= 99) {
      this.setState({ descuentoPedido: value });
    }

    if (keyboard.component === 'comentarios') {
      this.setState({ comentarioPedido: value });
    }

    if (keyboard.component === 'cliente') {
      keyboard.value = value;

      this.setState(
        {
          cliente: value,
          keyboard,
        },
        function () {
          let select = document.getElementById('autocomplete-cliente');
          select.value = value;
          select.click();
          return 1;
        }
      );

      return 1;
    }

    keyboard.value = value;

    return this.setState({
      keyboard,
    });
  };

  seleccionarCliente = async (cliente) => {
    Swal.fire({
      title: "Cargando Cliente",
      showCloseButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onOpen: () => {
        Swal.showLoading();
      }
    });
    if ( cliente === null )
    {
      Swal.close();
      return;
    }
    
    let clienteSeleccionado = await qad.catalog.customer.findById(
      cliente.value
    );
    Swal.close();
    if ( !clienteSeleccionado )
    {
      Swal.fire({
          icon: 'error',
          title: `Seleccion no permitida`,
          html: `<div>
                    <p>No se puede seleccionar el cliente, se encuentra inactivo</p>            
                </div>`,
        });
      return;
    }
    this.setState({clienteCompleto: clienteSeleccionado});
    let descuentoPedido =
      clienteSeleccionado.desc_cte && clienteSeleccionado.desc_cte !== '0'
        ? clienteSeleccionado.desc_cte
        : '';
    this.setState({
      clienteSeleccionado,
      descuentoPedido,
    });

    this.setState({
      cliente,
      showKeyboard: false,
    });


    let productosLocalStorage = JSON.parse(
      localStorage.getItem('productosLocalStorage')
    );

    // Reset prec_set and priceListIdSet
    const formattedProductosLocalStorage = productosLocalStorage.map(
      (producto) => {
        const priceList = producto.priceList.find(
          ({ id }) =>
            id === clienteSeleccionado.pricesList
        );
        if ( priceList )
        {
          producto.prec_set = parseFloat(priceList.price);
          producto.prec_prod = parseFloat(priceList.price).toFixed(2);
          producto.priceListIdSet = priceList.id;
        }
        return {
          ...producto
        };
      }
    );

    localStorage.setItem(
      'productosLocalStorage',
      JSON.stringify(formattedProductosLocalStorage)
    );

    let productosData = this.getProductos();

    let { total, cantidad } = productosData;

    return this.setState({
      productosLocalStorage: formattedProductosLocalStorage,
      total,
      cantidad,
    });
  };

  onChangeSelectedCliente = async (event) => {
    let value = event.target.value;

    this.setState(
      {
        cliente: value,
      },
      function () {
        let select = document.getElementById('autocomplete-cliente');
        select.value = value;
        select.focus();
      }
    );
  };
  modalRemisionOpen = () => {
    this.setState({
      modalRemisionIsOpen: true,
    });
  };
  modalRemisionClose = () => {
    this.setState({
      modalRemisionIsOpen: false,
    });
  };
  modalPedidosOpen = () => {
    this.setState({
      modalPedidosIsOpen: true,
    });
  };
  modalPedidosClose = () => {
    this.setState({
      modalPedidosIsOpen: false,
    });
  };
  modalExistencesClose = () => {
    this.setState({
      modalExistencesIsOpen: false,
    });
  };
  modalExistencesOpen = () => {
    this.setState({
      modalExistencesIsOpen: true,
    });
  };
  modalArticulosOpen = () => {
    this.setState({
      modalArticulosIsOpen: true,
    });
  };
  modalArticulosClose = () => {
    this.setState({
      modalArticulosIsOpen: false,
    });
  };
  modalInvoicesOpen = () => {
    this.setState({
      modalInvoicesIsOpen: true,
    });
  };
  modalInvoicesClose = () => {
    this.setState({
      modalInvoicesIsOpen: false,
    });
  };
  modalNewCustomerOpen = () => {
    this.setState({
      modalNewCustomerIsOpen: true,
    });
  };
  modalOCOpen = () => {
    this.setState({
      modalOCIsOpen: true,
    });
  };
  modalOCClose = () => {
    this.setState({
      modalOCIsOpen: false,
    });
  };
  modalNewCustomerClose = () => {
    this.setState({
      modalNewCustomerIsOpen: false,
    });
  };
  modalReporteOpen = () => {
    this.setState({
      modalReporteIsOpen: true,
    });
  };
  modalReporteClose = () => {
    this.setState({
      modalReporteIsOpen: false,
    });
  };
  modalReporte2Open = () => {
    this.setState({
      modalReporte2IsOpen: true,
    });
  };
  modalReporte2Close = () => {
    this.setState({
      modalReporte2IsOpen: false,
    });
  };
  modalCancelOpen = ()=> {
    this.setState({
      modalCancelIsOpen: true,  
    })
  };
  modalCancelClose = ()=>{
    this.setState({
      modalCancelIsOpen: false,
    })
  };
  modalCheckinOpen = ()=> {
    this.setState({
      modalCheckinIsOpen: true,  
    })
   };
  modalCheckinClose = ()=>{
    this.setState({
      modalCheckinIsOpen: false,
    })
  };

  
  
  modalFaqOpen = () => {
    this.setState({
      modalFaqIsOpen: true,
    });
  };
  modalFaqClose = () => {
    this.setState({
      modalFaqIsOpen: false,
    });
  };
}

export { AppContainer as default };
