import React from 'react';
import './modal-cancel-report-filtros.css';
import { DatePicker, Row, Col, Button} from 'react-materialize';

function ReporteFiltros(props){

    return (<React.Fragment>
            <h4 className="m3">Reporte de pedidos Cancelados</h4>
            <Row xl={12} l={12} m={12} s={12}>
                <Col>
                    <Button
                    node="button"
                    waves="light"
                    onClick={() => {
                        props.onBuscar();
                    }} >Buscar</Button>
                </Col>
                <Col>
                    <Button
                        node="button"
                        waves="light"
                        disabled={props.disableToDelete}
                        onClick={() => {
                            props.toDelete();
                        }}
                    >
                        Guardar
                    </Button>
                    </Col>
            </Row>
        </React.Fragment>)
}
export {ReporteFiltros as default}