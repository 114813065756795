import { Button, Col, Container, Row, TextInput, Icon } from 'react-materialize';
import Modal from './modal';
import React from 'react';
import { ErpConnect as qad } from '../../../services/index.js';
import LoadingView from '../loading/loading.view';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';

class ModalCheckin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(), 
      checkinsByStatus: {},
      loading: false,
      updateMiliseconds : 1000,
      statuses: []
    };
  }

  componentDidMount() {
    this.mountedTimer();
  }

  handleCloseModal = () => {
    this.props.onClose();
    this.setState({
        updateMiliseconds : 1000
      });
    clearInterval(this.timerID);
    this.mountedTimer();
  };

  async tick() {
    clearInterval(this.timerID);
    try {
      if ( this.state.statuses.length <= 0 )
      {
        const statuses = await qad.catalog.website.getStatuses();
        this.setState({ statuses: statuses })
      }
      qad.catalog.website.getCheckinsByStatus().then( (checkinsByStatus) => {
        this.setState({ checkinsByStatus: checkinsByStatus[0], loading: false });
        this.mountedTimer();
      });
      this.setState({
        date: new Date(),
        loading : false,
        updateMiliseconds : 1000*60
      });
    }
    catch(err)
    {
      console.log(err);
      this.setState({
        date: new Date(),
        loading : true,
        updateMiliseconds : 1000
      });
      this.mountedTimer();
    }
  }

  mountedTimer()
  {
    this.timerID = setInterval(
      async () => {
        if (this.props.isOpen)
        {
          await this.tick();
        }
        //console.log(new Date(), this.state.updateMiliseconds);
      },
      this.state.updateMiliseconds
    );
  }

  async setAgent(row, index, agent)
  {
    let value = null;
    if ( agent )
    {
      value = agent.value;
    }
    let currentStatus = this.state.checkinsByStatus;
    currentStatus[row.status][index]["usr_at_stock"] = value;
    this.setState({
      checkinsByStatus: currentStatus
    });
  }

  async nextStep(row, index, nextStatus)
  {
    if (nextStatus === 'order_created' && ( !row.erp_order_id || row.erp_order_id.length < 6 ) )
    {
      Swal.fire({
        title: "Tienes que agregar pedido al turno para poder continuar"
      });
      return;
    }
    if (nextStatus === 'at_stock' && ( !row["usr_at_stock"] ) )
    {
      Swal.fire({
        title: "Tienes que asignar un agente al turno para poder continuar"
      });
      return;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      const result = await qad.catalog.website.changeCheckinStatus({
        id: row.id,
        status: nextStatus,
        salesPersonId: ( !row["usr_"+nextStatus] ) ? user.salesPersonId : row["usr_"+nextStatus],
        salesOrderId: row.erp_order_id ? row.erp_order_id : '' 
      });
      if ( result[0].message )
      {
        Swal.fire({
          title: result[0].message
        });
        let currentStatus = this.state.checkinsByStatus;
        currentStatus[row.status][index]["hide_action"] = true;
        this.setState({
          checkinsByStatus: currentStatus
        });
        this.tick();
      }
    }
    catch(err)
    {
      Swal.fire({
        title: 'Error intente nuevamente'
      });
    }
  }

  setOrder(row, index, value)
  {
    if ( !value )
    {
      value = '';
    }
    let currentStatus = this.state.checkinsByStatus;
    console.log(currentStatus, row);
    currentStatus[row.status][index]["erp_order_id"] = value;
    this.setState({
      checkinsByStatus: currentStatus
    });
  }

  promiseOptions = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterOptions(inputValue));
    });
  }

  filterOptions = (inputValue, slice = 0) => {
    return this.agents.filter((i) => {
        return i.label.toLowerCase().includes(inputValue.toLowerCase())
    });
  }

  render() {
    let agentsByKey = {};
    this.agents = this.props.catalog.agents.map((agent) => {
      if ( !agentsByKey[agent.id] )
      {
        agentsByKey[agent.id] = agent;
      }
      return { value: agent.id, label: agent.name };
    });
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.handleCloseModal}
        >
          <Container className="remove-padding">
            <Row>
              <Col s={12}>
                <h3 className="status-checkin"> Hora: 
                  <span className="clock">
                    { (this.state.date.getHours() > 9 ) ? this.state.date.getHours(): "0"+this.state.date.getHours()}:{(this.state.date.getMinutes() > 9 ) ? this.state.date.getMinutes(): "0"+this.state.date.getMinutes()}
                  </span>
                </h3>
                <h3 className="m3">Lista de clientes en fila </h3>
                <Button onClick={() => this.tick() }> Actualizar Ahora</Button>
                <div className="separator"/>
                <Row>
                  <Col l={6} s={12}>
                    <Row>
                      <Col s={5}>
                        <div className="status-title-turn"> 
                          Turnos
                        </div>
                      </Col>
                      <Col s={3}>
                        <div className="status-title-status"> 
                          Estatus
                        </div>
                      </Col>
                      <Col s={2}>
                        <div className="status-title-order"> 
                          Pedido
                        </div>
                      </Col>
                      <Col s={2}>
                        <div className="status-title-next"> 
                          Continuar
                        </div>
                      </Col>
                    </Row>    
                  </Col>
                  <Col className="hide-small" l={6} s={12}>
                    <Row>
                      <Col s={5}>
                        <div className="status-title-turn"> 
                          Turnos
                        </div>
                      </Col>
                      <Col s={3}>
                        <div className="status-title-status"> 
                          Estatus
                        </div>
                      </Col>
                      <Col s={2}>
                        <div className="status-title-order"> 
                          Pedido
                        </div>
                      </Col>
                      <Col s={2}>
                        <div className="status-title-next"> 
                          Continuar
                        </div>
                      </Col>
                    </Row>    
                  </Col>
                </Row>
                {
                  ( !this.state.loading )
                  ?
                    <Row>
                      {
                        this.state.statuses.map( (status, sIdx) => {
                          return (
                                  ( !this.state.checkinsByStatus[status["status"]] )
                                  ?
                                    null
                                  :
                                    this.state.checkinsByStatus[status["status"]].map( (row, index) => {
                                        if ( !row["erp_order_id"] )
                                        {
                                          row["erp_order_id"] = '';
                                        }
                                        if ( !row["hide_action"] )
                                        {
                                          row["hide_action"] = false;
                                        }
                                        let currentTime = 0;
                                        const today = Date.now();
                                        if ( row[status["status"]+"_at"] )
                                        {
                                          const customerTime = Date.parse(row[status["status"]+"_at"]);
                                          currentTime = parseInt( Math.abs((today - customerTime)/1000/60) );
                                        }
                                        return (
                                            <Col key={row.id} className="column-separator" l={6} s={12}>
                                              <Row>
                                                <Col s={5}>
                                                  <Row>
                                                    <Col s={4} >
                                                      <div className={"turn-circle turn-"+( row["turn"] > 9 ? row["turn"].toString().slice(-1) : row["turn"] ) }> 
                                                        <div>
                                                          {row["name"].split(' ').map( (word) => word.substring(0,1).toUpperCase() ).slice(0,2).join('')}
                                                        </div>
                                                      </div>
                                                    </Col>
                                                    <Col s={8} >
                                                      <Row>
                                                        <div className="turn-name"> 
                                                          <span>
                                                            {row["name"]}
                                                          </span>
                                                        </div>
                                                      </Row>
                                                      <Row>
                                                        <div className="turn-time"> 
                                                          <span>
                                                            <Icon className="darker">timer</Icon>&nbsp;
                                                            {currentTime} min
                                                          </span>
                                                        </div>
                                                      </Row>
                                                      <Row>
                                                        <div className="turn-number"> 
                                                          Turno:&nbsp;
                                                          <span>
                                                            {row["turn"]}
                                                          </span>
                                                        </div>
                                                      </Row>
                                                      <Row>
                                                        {
                                                         (status["status"] === "payed")
                                                         ?
                                                          <div className="turn-sales-person"> 
                                                            <AsyncSelect
                                                              id=""
                                                              cacheOptions
                                                              placeholder="Seleccionar"
                                                              isClearable
                                                              onChange={(value) => {this.setAgent(row, index, value)}} 
                                                              defaultOptions={this.agents} 
                                                              loadOptions={this.promiseOptions}/>
                                                          </div>
                                                         :
                                                          <div className="turn-sales-person"> 
                                                            Atiende:&nbsp;
                                                            <span>
                                                              { ( !row["usr_"+status["status"]] ) ? 'Sin Atender' :(agentsByKey[row["usr_"+status["status"]]]) ? agentsByKey[row["usr_"+status["status"]]]["name"]: row["usr_"+status["status"]]}
                                                            </span>
                                                          </div>
                                                        }
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col s={3}>
                                                  <div className="turn-status" style={{backgroundColor: status["bgcolor_status"]}}> 
                                                    {status["label"]}
                                                  </div>
                                                </Col>
                                                <Col s={2}>
                                                  {
                                                      ( row.erp_order_id && row.status !== "creating_order" )
                                                      ?
                                                        (
                                                          <div className="turn-order" style={{backgroundColor: status["bgcolor_order"]}}> 
                                                            <div>
                                                              <b>{row.erp_order_id.toUpperCase()}</b>
                                                            </div>
                                                          </div>
                                                        )
                                                      :
                                                        (row.status === "creating_order")
                                                        ?
                                                          (
                                                            <TextInput
                                                              id={"nameCustomer_"+row.id}
                                                              s={12}
                                                              value={row["erp_order_id"].toUpperCase()}
                                                              label={"Pedido"}
                                                              onChange={(e)=> this.setOrder( row, index, e.target.value )}/>
                                                          )
                                                        :
                                                          null
                                                    }
                                                </Col>
                                                <Col s={2}>
                                                  <div className="status-title-next"> 
                                                    <Button disabled={row['hide_action']} id={"nextStep_"+row.id} onClick={(value) => this.nextStep( row, index, status["next_status"])}>
                                                      <Icon>send</Icon>
                                                    </Button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          );
                                    })
                              
                            );
                        })
                      }
                    </Row>
                  :
                    <LoadingView/>
                }
              </Col>
            </Row>
          </Container>
        </Modal>
      </React.Fragment>
    );
  }

}
export { ModalCheckin as default };
