import { Button, Col, Container, Row, TextInput } from 'react-materialize';
import LoadingView from '../loading/loading.view';
import M from 'materialize-css';
import Modal from './modal';
import ModalPedidoItem from './modal-pedidos.view';
import ModalPedidoEdit from './modal-edit-pedidos.view';
import ModalPedidoAdd from './modal-add-pedidos.view';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad, NavigationService } from '../../../services';
import PedidoReprintView from './../modal-reporte/pedido-reprint';
import printJS from 'print-js';
// servicios

class ModalPedido extends React.Component {
  state = {
    loading: false,
    numPedido: '',
    itemsPedido: [],
    itemsPedidoModificar: [],
    btnBuscarEstado: false,
    propsPrint:{
      articulos: []
    },
    catalogs: [],
    lastSearch: '',
    inEdition: false,
    insertNew: false,
    newArticle: [],
    categories: []
  };

  async componentDidMount() {
  }

  handleChange = (e) => {
    this.setState({
      numPedido: e.target.value,
    });
  };

  formatResultPedido = (resultPedidos) => {
    return resultPedidos.map((pedido) => {
      return {
        ...pedido,
        Pedido: '315062',
        Vendedor: '84',
        Cliente: '40380',
        'Fecha de pedido': '2022-09-10',
        'Estatus de pedido': 'ACTIVO',
        Total: '1798.46',
        'Comentarios de pedido':
          'PV - domicilio fiscal Dirección, Guadalupe, col. R',
        'Descuento general': '0.000000',
      };
    });
  };
  handleSearchPedido = async () => {
    if ( !this.state.numPedido )
    {
      return;
    }
    let numPedido = this.state.numPedido.trim();
    if (this.state.lastSearch === numPedido) 
    {
      M.toast({
        html: 'Es el mismo pedido',
        classes: 'message-success',
      });
      return;
    }
    this.setState({
      loading: true,
      lastSearch: numPedido,
      inEdition: false,
      insertNew: false,
    });
    try {
      let result = await qad.catalog.salesOrder.findById(numPedido);
      if (!result) {
        M.toast({ html: 'No se encontro numero de pedido' });
        this.setState({
          itemsPedido: [],
          itemsPedidoModificar: [],
          lastSearch: '',
          inEdition: false,
          insertNew: false,
        });
      } else {
        this.setState({
          itemsPedido: [result],
          itemsPedidoModificar: [result],
          lastSearch: '',
          inEdition: false,
          insertNew: false
        });
      }
    } catch (error) {
      this.setState({
        itemsPedido: [],
        itemsPedidoModificar: [],
        lastSearch: '',
        inEdition: false,
        insertNew: false,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      numPedido: null,
      itemsPedido: [],
      itemsPedidoModificar: [],
      lastSearch: '',
      inEdition: false,

    });
    this.props.onClose();
  };

  // muestra pedido
  showPedido = () => {
    if (this.state.itemsPedido) {
      return (
        <ModalPedidoItem
          items={this.state.itemsPedido}
          onCancel={this.handleCancelarPedido}
          onGenerar={this.handleGenerarPedido}
          print={this.print}
          handleClickCopy={this.handleClickCopy}
          showSendMail={this.showSendMail}
          edit={this.edit}
          inEdition={this.state.inEdition}
          makeRemission={this.props.makeRemission}
        />
      );
    } else {
      return null;
    }
  };

  showSendMail = () => {
    Swal.fire({
        title: "A qué correo quieres enviarlo?",
        showCancelButton: true,
        input: "text",
        inputLabel: "Ingresa tu E-mail",
        inputPlaceholder: "Ingresa tu E-mail",
        confirmButtonText: "Enviar",
        cancelButtonText: `Cancelar`,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async (login) => {
          try 
          {
            Swal.disableButtons();
            const mails = login.split(',');
            const regex = RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
            const validatedMails = mails.map((mail) => {
              return regex.exec(mail) != null
            });
            if ( validatedMails.indexOf(false) !== -1 )
            {
              Swal.enableButtons();
              Swal.showValidationMessage(`E-mail invalido`);
            }
            else
            {
              const response = await qad.catalog.website.sendMail(login, this.state.numPedido.trim());
              Swal.enableButtons();
              return response;
            }
          } catch (error) {
            //Habilitar cuando se quite el HTTP
            Swal.enableButtons();
            Swal.showValidationMessage(`Request failed: ${error}`);
            return {"sucess":true};
          }
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let timerInterval;
          Swal.fire({
            title: "Se envio el mail correctamente!",
            html: "Cierre automatico en <b></b> milisegundos.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          });
        }
        else
        {
          M.toast({
            html: 'Error al enviar el mail intente nuevamente',
            classes: 'message-error',
          });
        }
      });
  }

  print = async () => {
    const order = this.state.itemsPedido[0];
    this.setState(
      {
        reportePrint: true,
        propsPrint: {
          numeroPedido: order.salesOrderId,
          remision: order.remisionId,
          cliente: order.customer,
          vendedor: order.salesPerson,
          comentarios: order.remarks,
          direccion: order.address,
          fecha: order.orderDate,
          status: order.status,
          subtotal: order.subTotal,
          descuento: order.discount ? order.discount : 0 ,
          iva: order.IVA,
          total: order.total,
          articulos: order.detail,
          colores: this.props.catalog.colors,
          usuario: JSON.parse(localStorage.getItem('user')).name
        },
      },
      async function () {
        printJS({
          printable: 'reprintContainer',
          type: 'html',
          targetStyles: ['*'],
        });

        this.setState({
          reportePrint: false,
        });
      }
    );
  }

  handleClickCopy = async () => {
    try {
      const link = qad.catalog.customer.getWhatsappLink();
      window.open("http://wa.me/?text=Hola%20te%20envio%20tu%20pedido%20"+link+this.state.numPedido.trim() , "_blank")
      await navigator.clipboard.writeText(link+this.state.numPedido.trim());
      M.toast({
        html: 'Se copio el link',
        classes: 'message-success',
      });
    } 
    catch (e) 
    {
      M.toast({
        html: e.message,
        classes: 'message-error',
      });
      //throw e;
    }
  };

  showEdit = () =>
  {
    return (
      <ModalPedidoEdit 
        catalog={this.props.catalog}
        items={this.state.itemsPedidoModificar}
        addNew={this.addNew}
        changeValue={this.changeValue}
        getOptions={this.getOptions}
        send={this.send}
        products={this.products}
        filterOptions={this.filterOptions}
        promiseOptions={this.promiseOptions}
        changeProduct={this.changeProduct}
      />
    );
  }

  changeProduct = async (item, line) => {
    //console.log("changeProductSync",item, line);
    let itemModificado = this.state.itemsPedidoModificar[0];
    this.changeValue(line, 'disabled', true);
    Promise.all([this.getItemForCatalogs(item)]).then( ( resolved ) => {
      let catalogs = this.state.catalogs;
      //console.log("changeProductAsync", resolved);
      resolved.forEach((itemR, i)=>{
        catalogs[item.value] = itemR;
        itemR.relatedProducts.forEach((id, idx) => {
          if ( catalogs[id] === undefined )
          {
            catalogs[id] = { ...itemR };
          }
        });
      });
      itemModificado.detail[line]["productId"] = item.value;
      itemModificado.detail[line]["product"] = item.code.toUpperCase();
      itemModificado.detail[line]["sizeId"] = item.sizeId;
      itemModificado.detail[line]["colorId"] = item.colorId;
      this.setState({
        catalogs,
        itemsPedidoModificar : [itemModificado]
      });
      this.changeValue(line, 'sizeId', item.sizeId);
      this.changeValue(line, 'disabled', false); 
    });
  }

  filterOptions = (inputValue, slice = 0) => {
    if ( slice > 0 )
    {
      this.filtred = this.products.slice(0, slice).filter((i) => {
        const ands = inputValue.split("&");
        if ( ands.length > 1 )
        {
          let matchWords = [];
          ands.forEach(function(value){
            matchWords.push(
              i.label.toLowerCase().includes(value.toLowerCase())
              || i.model.toLowerCase().includes(value.toLowerCase())
              || i.color.toLowerCase().includes(value.toLowerCase())
              || i.brand.toLowerCase().includes(value.toLowerCase())
              || i.code.toLowerCase().includes(value.toLowerCase())
            );
          });
          return matchWords.indexOf(false) === -1 ; 
        }
        else
        {
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
            || i.model.toLowerCase().includes(inputValue.toLowerCase())
            || i.color.toLowerCase().includes(inputValue.toLowerCase())
            || i.brand.toLowerCase().includes(inputValue.toLowerCase())
            || i.code.toLowerCase().includes(inputValue.toLowerCase());
        }
      });
    }
    else
    {

      this.filtred = this.products.filter((i) => {
          const ands = inputValue.split("&");
          if ( ands.length > 1 )
          {
            let matchWords = [];
            ands.forEach(function(value){
              matchWords.push(
                i.label.toLowerCase().includes(value.toLowerCase())
                || i.model.toLowerCase().includes(value.toLowerCase())
                || i.color.toLowerCase().includes(value.toLowerCase())
                || i.brand.toLowerCase().includes(value.toLowerCase())
                || i.code.toLowerCase().includes(value.toLowerCase())
              );
            });
            return matchWords.indexOf(false) === -1  ; 
          }
          else
          {
            return i.label.toLowerCase().includes(inputValue.toLowerCase())
              || i.model.toLowerCase().includes(inputValue.toLowerCase())
              || i.color.toLowerCase().includes(inputValue.toLowerCase())
              || i.brand.toLowerCase().includes(inputValue.toLowerCase())
              || i.code.toLowerCase().includes(inputValue.toLowerCase());
          }
        }
      );
    }
    return this.filtred;
  }

  promiseOptions = (inputValue) => {
    if (inputValue.length > 3 )
    {
      return new Promise ((resolve) => {
        resolve(this.filterOptions(inputValue));
      });
    }
    else
    {
      return new Promise(resolve => {
        resolve(this.filterOptions(inputValue, 500))
      })
    }
  }

  showAddNew = () =>
  {
    return (
      <ModalPedidoAdd
        newArticle={this.state.newArticle}
        getCategoryLevelElements={this.getCategoryLevelElements}
        getCategoryLevelName={this.getCategoryLevelName}
        getCategoryLevelId={this.getCategoryLevelId}
        isLoadingCategory={this.state.isLoadingCategory}
      />
    );
  }

  send = async ( ) => {
    Swal.fire({
        title: 'Modificando',
        html: 'Procesando modificaciones..',
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    //console.log("start-process");
    //console.log( this.state.itemsPedidoModificar[0] );
    if( this.state.itemsPedidoModificar[0].remisionId )
    {
      const result = await qad.catalog.salesOrder.cancelRemision(this.state.itemsPedidoModificar[0].salesOrderId);
      //console.log(result);
    }
    const update = await qad.catalog.salesOrder.update({
      update : [
          {
            salesOrderId : this.state.itemsPedidoModificar[0].salesOrderId,
            lines : this.state.itemsPedidoModificar[0].detail.map( item => {
                return {
                  salesOrderId: this.state.numPedido,
                  line: item.line,
                  productId: item.productId,
                  quantity: item.qtyOrd,
                  price: item.price,
                  crud: item.status
                }
              })
          }
      ]
    });
    let create = null;
    if ( update && update.salesOrderId )
    {
      //console.log(update);
      create = await qad.catalog.salesOrder.toRemision(this.state.itemsPedidoModificar[0].salesOrderId);
      this.setState({
        btnBuscarEstado: false,
        numPedido: null,
        itemsPedido: [],
        itemsPedidoModificar: [],
        lastSearch: '',
        inEdition: false
      });
    }
    if ( !create || !create.salesOrdeId )
    {
      M.toast({
        html: 'Pedido no se modifico correctamente',
        classes: 'message-danger',
      });
    }
    else
    {
      Swal.close();
      M.toast({
        html: 'Pedido correctamente modificado',
        classes: 'message-success',
      });
    }
  }

  getCategoryLevelElements = ( category, index ) => {
    if ( !this.state.categories[category.category] )
    {
      if ( !this.state.newArticle[0].items[index] )
      {
        return [];
      }
    }
    return this.state.categories[category.category];
  }

  getCategoryLevelId = ( idx ) => 
  {
    if ( this.state.newArticle[0].items[idx] )
    {
      return this.state.newArticle[0].items[idx].id;
    }
    return 0;
  }

  getCategoryLevelName = ( idx ) => 
  {
    if ( this.state.newArticle[0].items[idx] )
    {
      return " - " + this.state.newArticle[0].items[idx].name;
    }
    return "";
  }

  getOptions = ( productId, catalog ) => {
    if ( productId )
    {
      return this.state.catalogs[productId][catalog];
    }
    return [];
  }

  edit = async () => {
    this.setState({
      loading: true
    });
    if ( !this.state.inEdition )
    {
      const currentPromises = this.state.itemsPedido[0].detail.map( (item, idx) => {
        return this.getItemForCatalogs(item);
      });
      Promise.all(currentPromises).then( ( resolved ) => {
        let catalogs = {};
        resolved.forEach(( item, i)=>{
          catalogs[item.productId] = item;
          item.relatedProducts.forEach((id, idx) => {
            if ( catalogs[id] === undefined )
            {
              catalogs[id] = { ...item };
            }
          });
        });
        this.setState({
          catalogs,
          inEdition: !this.state.inEdition,
          loading: false
        });  
      });
    }
    else
    {
      this.setState({
          inEdition: !this.state.inEdition,
          loading: false
        });
    }
  }

  getItemForCatalogs = (item) => {
    return new Promise( async (resolve) => {
          let sizeColor = {
            productId: item.productId,
            colors: {},
            sizes : {},
            colorOptions: [],
            sizeOptions: [],
            priceOptions: [],
            relatedProducts: [],
            existences: []
          }
          if ( item.size || item.color )
          {
            const relatedProducts = await qad.catalog.product.relatedBy( { modelId: item.modelId } );
            relatedProducts.forEach( ( product, i ) => {
              sizeColor.relatedProducts.push(product.id);
              if ( product.colorId )
              {
                if ( product.colorLabel )
                {
                  if ( !sizeColor.colors[product.colorId] )
                  {
                    sizeColor.colorOptions.push( { "value": product.colorId, "label": product.colorLabel, color: product.colorPantoneHexa.split(',')[0]  } );
                  }
                }
                if ( !sizeColor.colors[product.colorId] )
                {
                  sizeColor.colors[product.colorId] = {};
                }
                sizeColor.colors[product.colorId][product.sizeId] = { id: product.id, name: product.longDescription.toUpperCase() };
              }
              if ( product.sizeId )
              {
                if ( product.sizeName )
                {
                  if ( !sizeColor.sizes[product.sizeId] )
                  {
                    sizeColor.sizeOptions.push( { "value": product.sizeId, "label": product.sizeName  } );
                  }
                }
                if ( !sizeColor.sizes[product.sizeId] )
                {
                  sizeColor.sizes[product.sizeId] = {};
                }
                sizeColor.sizes[product.sizeId][product.colorId] = { id: product.id, name: product.longDescription.toUpperCase() };
              }
            });
          }
          if ( item.productId )
          {
            const priceListOptions = this.props.catalog.priceList.filter((list) =>  list.productId == item.productId  );
            sizeColor.priceOptions = priceListOptions.map( (item ) => { return {"value" : parseFloat(item.price), "label" : parseFloat(item.price).toFixed(2) + "("+item.id+")"} } );
            const existences = await qad.catalog.inventory.findById(item.productId);
            sizeColor.existences = existences;
          }
          resolve( sizeColor );
        });
  }

  addNew = async () => {
    let itemModificado = this.state.itemsPedidoModificar[0];
    itemModificado.detail.push({
      line: itemModificado.detail.length+1,
      productId : null,
      qtyOrd: 1,
      sizeId:null,
      colorId: null,
      status: 'C',
      price: 0
    });
    this.setState({
      itemsPedidoModificar : [itemModificado]
    });
  }

  changeValue = async ( lineIdx, field, value ) => {
    let itemModificado = this.state.itemsPedidoModificar[0];
    let catalogs = this.state.catalogs;
    if ( field === "qtyOrd" )
    {
      const intVal = Number.isInteger(parseInt(value)) ? parseInt(value) : 0; 
      //console.log(value, intVal);
      if ( intVal < 1 ) 
      {
        M.toast({
          html: 'No puede tener menos de 1 la cantidad',
          classes: 'message-error',
        });
        return;
      }
        
    }
    if ( field === "sizeId" || field === "colorId" ) 
    {
      itemModificado.detail[lineIdx]["disabled"] = true;
      this.setState({
        itemsPedidoModificar : [itemModificado],
      })
      let product = {
        id: 0,
        name: "Ninguno"
      }

      if ( field === "sizeId" )
      {
        if ( itemModificado.detail[lineIdx]["colorId"] )
        {
          product = this.state.catalogs[itemModificado.detail[lineIdx]["productId"]]["sizes"][value][itemModificado.detail[lineIdx]["colorId"]];
        }
      }
      else
      {
        if ( itemModificado.detail[lineIdx]["sizeId"] )
        {
          product = this.state.catalogs[itemModificado.detail[lineIdx]["productId"]]["colors"][value][itemModificado.detail[lineIdx]["sizeId"]];
        }
      }
      const filtred = itemModificado.detail.filter( item => item.productId === product.id );
      if ( filtred.length > 0 )
      {
        itemModificado.detail[lineIdx]["disabled"] = false;
        this.setState({
          itemsPedidoModificar : [itemModificado],
        });
        M.toast({
          html: 'Ese producto ya esta seleccionado en la lista',
          classes: 'message-error',
        });
        return;
      }
      if( product.id != 0 )
      {
        itemModificado.detail[lineIdx]["productId"] = product.id;
        itemModificado.detail[lineIdx]["product"] = product.name;
        const priceListOptions = await qad.catalog.priceList.findByProductId(product.id);
        catalogs[product.id].priceOptions = priceListOptions.map( (item ) => { return {"value" : parseFloat(item.price), "label" : parseFloat(item.price).toFixed(2) + "("+item.id+")"} } );
        //console.log("price", catalogs, catalogs[product.id].priceOptions.length);
        if ( catalogs[product.id].priceOptions.length > 0 ) {
          itemModificado.detail[lineIdx]["price"] = catalogs[product.id].priceOptions[0].value;
        }
        const existences = await qad.catalog.inventory.findById(product.id);
        catalogs[product.id].existences = existences;
      }
      itemModificado.detail[lineIdx]["disabled"] = false;
    }
    if ( value === 'D' && itemModificado.detail[lineIdx]['status'] === 'C' )
    {
      itemModificado.detail[lineIdx]['deleted'] = true;
    }
    else
    {
      if ( field === "qtyOrd" )
      {
        itemModificado.detail[lineIdx][field] = parseInt(value);
      }
      else
      {
        itemModificado.detail[lineIdx][field] = value;
      }
      if ( field !== 'status' )
      {
        if ( itemModificado.detail[lineIdx]['status'] !== 'C' )
        {
          itemModificado.detail[lineIdx]['status'] = 'U';
        }
      }
      else
      {
        if ( value === 'D' )
        {
          itemModificado.detail[lineIdx]['deleted'] = true;
        }
        else
        {
          itemModificado.detail[lineIdx]['deleted'] = false;
        }
      }
    }
    //console.log(itemModificado);
    this.setState({
      itemsPedidoModificar : [itemModificado],
      catalogs
    })
  }

  render() {
    this.products = this.props.catalog.products.map( (el)=> {
      return {
        value: el.id,
        productId: el.id,
        label: el.article + " - " + el.longDescription + "( "+el.colorLabel+" )",
        model: el.modelName,
        color: el.colorLabel,
        colorId: el.colorId, 
        brand: el.brandName,
        sizeId: el.sizeId,
        code: el.article,
        modelId: el.modelId
      }
    });
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalPedidosIsOpen}
          onClose={this.handleCloseModal}
          makeRemission={this.props.makeRemission}
        >
          <Container>
            <Row>
              <Col s={12} m={12} l={12} xl={12}>
                <h3 className="m3">Pedidos</h3>
                <TextInput
                  id="numPedidoInput"
                  s={12}
                  m={12}
                  l={12}
                  xl={12}
                  value={this.state.numPedido || ''}
                  onChange={this.handleChange}
                  label="Numero de pedido"
                />
                <Button
                  className="m3"
                  node="button"
                  waves="light"
                  disabled={this.state.btnBuscarEstado}
                  onClick={this.handleSearchPedido}
                >
                  Buscar pedido
                </Button>
              </Col>
            </Row>
            <br></br>
            <br></br>
            {this.state.loading ? <LoadingView /> : this.showPedido()}
            <div style={{display:"none"}} >
              <PedidoReprintView onProps={this.state.propsPrint}/>
            </div>
            { this.state.inEdition ? this.showEdit() : null }
            { this.state.insertNew ? this.showAddNew() : null }
          </Container>
        </Modal>
      </React.Fragment>
    );
  }

  handleCancelarPedido = async () => {
    let result;
    try {
      this.setState({
        btnBuscarEstado: true,
      });

      // modal loading
      Swal.fire({
        title: 'Cancelando',
        html: 'Procesando cancelaci&oacuten..',
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if ( !this.state.numPedido ) {
        throw Error();
      }
      if (this.state.itemsPedido.length > 0 && this.state.itemsPedido[0].remisionId)
      {
        const user = JSON.parse(localStorage.getItem('user'));
        const salesPersonId = user.salesPersonId;
        result = await qad.catalog.salesOrder.cancelRemision(this.state.numPedido, salesPersonId);
      }
      else
      {
        M.toast({
          html: 'No tiene remisión para cancelar',
          classes: 'message-error',
        });
      }
    } catch (error) {
      M.toast({
        html: 'Ocurrio un error al cancelar',
        classes: 'message-error',
      });
    } finally {
      this.setState({
        btnBuscarEstado: false,
        numPedido: null
      });
      Swal.close();
      return result;
    }
  };

}
export { ModalPedido as default };
