import { formatResponse, get, post, put, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class SalesPersonService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'salesPerson';
  }

  async all() {
    const action = 'read';
    const apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '' },
    });
    return formatResponse(getResponse);
  }

  async create(data) {
    const action = 'create';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const postResponse = await post(this.client, endpoint, formattedData);
    const [salesPersonCreated] = formatResponse(postResponse);
    return salesPersonCreated;
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id },
    });
    const [salesPerson] = formatResponse(getResponse);
    return salesPerson;
  }

  async update(id, data) {
    const action = 'update';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const putResponse = await put(this.client, endpoint, formattedData, {
      params: { id },
    });
    const [salesPersonUpdated] = formatResponse(putResponse);
    return salesPersonUpdated;
  }

  async login(username, password) {
    const action = 'login';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [{ username, password }],
    };
    const postResponse = await post(this.client, endpoint, formattedData);
    const [login] = formatResponse(postResponse);
    return login;
  }
  
}

export { SalesPersonService as default };
