/* eslint-disable */

import 'react-simple-keyboard/build/css/index.css';
import './app.css';

import { Button, Col, Modal, Row, Toast } from 'react-materialize';

import Category from '../category/category.view';
import LoadingView from '../loading/loading.view';
import NavigationView from '../navigation/navigation.view';
import PedidoPrintView from '../pedido-print/pedido-print.view';
import PedidoView from '../pedido/pedido.view';
import ProductView from '../product/product.view';
import React, { useEffect } from 'react';
import SideBarView from '../sidebar/sidebar.view';


// import Keyboard from 'react-simple-keyboard'

const AppView = (props) => {
  useEffect(() => {
    const { catalog, clientes, clientesServicio } = props;
    if (catalog && clientes && clientesServicio.length > 0) {
    //console.log('Loaded catalogs', { catalog, clientes, clientesServicio });
    }
  }, [props.catalog, props.clientes, props.clientesServicio]);

  return (


    
    <div className="app">
      <div
        className={
          props.print ||
          props.modalRemisionIsOpen ||
          props.modalPedidosIsOpen ||
          props.modalArticulosIsOpen ||
          props.modalExistencesIsOpen ||
          props.modalReporteIsOpen ||
          props.modalReporte2IsOpen ||
          props.modalOCByBrandIsOpen ||
          props.modalInvoicesIsOpen ||
          props.modalNewCustomerIsOpen ||
          props.modalFaqIsOpen ||
          props.modalCancelIsOpen ||
          props.modalCheckinIsOpen ||
          props.modalOCIsOpen
            ? 'hidden'
            : 'button-action-containers'
        }
      >
        <Button
          floating
          large
          waves="light"
          className="help_button"
          onClick={props.scrolloToTop}
          icon="keyboard_arrow_up"
        />
        <Button
          floating
          large
          waves="light"
          className="help_button"
          onClick={props.scrolloToBottom}
          icon="keyboard_arrow_down"
        />
        <Button
          floating
          large
          waves="light"
          className="help_button"
          onClick={props.openPedido}
          icon="shopping_cart"
        />
        <div style={{"opacity":0}}>
          <Button
            floating
            large
            waves="light"
            className="help_button"
            onClick={props.openPedido}
            icon='pin_drop'
            disabled={true}
          />
        </div>
        <Button
          floating
          large
          waves="light"
          className="help_button"
          onClick={props.cerrarSesion}
          icon="exit_to_app"
        />
      </div>
      <Row>
        <Col
          s={10}
          m={10}
          l={12}
          xl={12}
          offset="s2 m2"
          className={props.print ? 'hidden' : ''}
        >
          <div className="breadcrum">
            <ul
              className="nav nav-tabs process-model more-icon-preocess"
              role="tablist"
            >
              <NavigationView
                navigations={props.navigations}
                familyId={props.familyId}
                brandId={props.brandId}
                genderId={props.genderId}
                styleId={props.styleId}
                modelId={props.modelId}
                brandsClass={props.brandsClass}
                gendersClass={props.gendersClass}
                stylesClass={props.stylesClass}
                modelsClass={props.modelsClass}
                productsClass={props.productsClass}
                brandsName={props.brandsName}
                gendersName={props.gendersName}
                stylesName={props.stylesName}
                modelsName={props.modelsName}
                productsName={props.productsName}
                showPedido={props.showPedido}
                hiddenPedido={props.hiddenPedido}
                getCategoryData={props.getCategoryData}
              />

              <div className="select-elements">
                {props.familyName ? (
                  <div className="select-elements-item">
                    {' - ' + props.familyName.toUpperCase() + ' - '}
                  </div>
                ) : (
                  <div />
                )}
                {props.brandsName ? (
                  <div className="select-elements-item">
                    {props.brandsName.toUpperCase() + ' - '}
                  </div>
                ) : (
                  <div />
                )}
                {props.gendersName ? (
                  <div className="select-elements-item">
                    {props.gendersName.toUpperCase() + ' - '}
                  </div>
                ) : (
                  <div />
                )}
                {props.stylesName ? (
                  <div className="select-elements-item">
                    {props.stylesName.toUpperCase() + ' - '}
                  </div>
                ) : (
                  <div />
                )}
                {props.modelsName ? (
                  <div className="select-elements-item">
                    {props.modelsName.toUpperCase() + ' - '}
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </ul>
          </div>
          
        </Col>
        <Row>
          <Col
            s={1}
            m={1}
            l={1}
            xl={1}
            className={
              props.geoUrlIsOpen||
              props.print ||
              props.modalRemisionIsOpen ||
              props.modalPedidosIsOpen ||
              props.modalExistencesIsOpen ||
              props.modalArticulosIsOpen ||
              props.modalReporteIsOpen ||
              props.modalReporte2IsOpen ||
              props.modalOCByBrandIsOpen ||
              props.modalInvoicesIsOpen ||
              props.modalNewCustomerIsOpen ||
              props.modalFaqIsOpen ||
              props.modalCancelIsOpen ||
              props.modalCheckinIsOpen ||
              props.modalOCIsOpen 
                ? 'hidden'
                : ''
            }
          >
            <SideBarView
              {...props}
            />
          </Col>
          <Col s={10} m={10} l={10} xl={10}>
            <div className="body">
              {props.loading ? (
                <LoadingView />
              ) : props.showPedido ? (
                <div>
                  <div
                    className={
                      props.geoUrlIsOpen||
                      props.print ||
                      props.modalRemisionIsOpen ||
                      props.modalPedidosIsOpen ||
                      props.modalArticulosIsOpen ||
                      props.modalExistencesIsOpen ||
                      props.modalReporteIsOpen ||
                      props.modalReporte2IsOpen ||
                      props.modalOCByBrandIsOpen ||
                      props.modalInvoicesIsOpen ||
                      props.modalNewCustomerIsOpen ||
                      props.modalFaqIsOpen ||
                      props.modalCancelIsOpen||
                      props.modalCheckinIsOpen ||
                      props.modalOCIsOpen 
                        ? 'hidden'
                        : ''
                    }
                  >
                    <PedidoView
                      {...props}
                      catalog={props.catalog}
                      total={props.total}
                      cantidad={props.cantidad}
                      envios={props.envios}
                      cliente={props.cliente}
                      clienteSeleccionado={props.clienteSeleccionado}
                      clientes={props.catalog.customers}
                      newAgente={props.newAgente}
                      setNuevoAgente={props.setNuevoAgente}
                      comentarioPedido={props.comentarioPedido}
                      descuentoPedido={props.descuentoPedido}
                      envioSeleccionado={props.envioSeleccionado}
                      productosLocalStorage={props.productosLocalStorage}
                      setPriceProductosLocalStorage={
                        props.setPriceProductosLocalStorage
                      }
                      eliminarPartida={props.eliminarPartida}
                      cambiarCantidad={props.cambiarCantidad}
                      regresarArticulos={props.regresarArticulos}
                      agregarEnvio={props.agregarEnvio}
                      seleccionarCliente={props.seleccionarCliente}
                      onSelectAgente={props.onSelectAgente}
                      onChangeSelectedCliente={props.onChangeSelectedCliente}
                      onChangeComentarioPedido={props.onChangeComentarioPedido}
                      onChangeDescuento={props.onChangeDescuento}
                      onSelectEnvio={props.onSelectEnvio}
                      colocarPedido={props.colocarPedido}
                      setActiveInput={props.setActiveInput}
                      openPedido={props.openPedido}
                      opengps={props.opengps}
                      setPriceByProduct={props.setPriceByProduct}
                    />
                  </div>
                  <div
                    style={{ width: '100% !important' }}
                    className={props.print ? '' : 'hidden'}
                  >
                    <PedidoPrintView {...props} />
                  </div>
                </div>
              ) : props.showProduct &&
                  !props.modalRemisionIsOpen &&
                  !props.modalPedidosIsOpen &&
                  !props.modalArticulosIsOpen &&
                  !props.modalExistencesIsOpen &&
                  !props.modalReporteIsOpen &&
                  !props.modalReporte2IsOpen &&
                  !props.modalOCByBrandIsOpen &&
                  !props.modalInvoicesIsOpen &&
                  !props.modalNewCustomerIsOpen &&
                  !props.modalFaqIsOpen && 
                  !props.modalCancelIsOpen &&
                  !props.modalCheckinIsOpen &&
                  !props.modalOCIsOpen
                 ? (
                <ProductView
                  catalog={props.catalog}
                  product={props.product}
                  productos={props.productos}
                  pantones={props.pantones}
                  locations={props.locations}
                  locationsAvailable={props.locationsAvailable}
                  inventories={props.inventories}
                  coloresServicio={props.coloresServicio}
                  image={props.image}
                  tallas={props.tallas}
                  tallaSeleccionada={props.tallaSeleccionada}
                  colorSeleccionado={props.colorSeleccionado}
                  cantidadSeleccionada={props.cantidadSeleccionada}
                  botonBloqueado={props.botonBloqueado}
                  existenColores={props.existenColores}
                  colorId={props.colorId}
                  colores={props.colores}
                  existencia={props.existencia}
                  existencia2={props.existencia2}
                  existencia3={props.existencia3}
                  existencia4={props.existencia4}
                  exceptColorNewMedList={props.exceptColorNewMedList}
                  colorHidden={props.colorHidden}
                  onChangeTalla={props.onChangeTalla}
                  onChangeColor={props.onChangeColor}
                  onChangeColorTallas={props.onChangeColorTallas}
                  getTallasExistencias={props.getTallasExistencias}
                  onChangeCantidad={props.onChangeCantidad}
                  setActiveInput={props.setActiveInput}
                  insertIntoLocalStorage={props.insertIntoLocalStorage}
                  getArticulosRelacionados={props.getArticulosRelacionados}
                  getMarcasRelacionadas={props.getMarcasRelacionadas}
                  onClickElement={props.onClickElement}
                  changeProductClassEmpty={props.changeProductClassEmpty}
                  changeProductViewIds={props.changeProductViewIds}
                  brandId={props.brandId}
                  imageRute={props.imageRute}
                  changeImageRute={props.changeImageRute}
                  getPriceByPriceListKey={props.getPriceByPriceListKey}
                  url={props.url}
                  urlPass={props.urlPassSquare}
                  urlfaq={props.urlPassSquare}
                  urlfaq1={props.urlPassSquare}
                />
              ) : (
                <Category
                  imageRute={props.imageRute}
                  elements={props.elements}
                  onClickElement={props.onClickElement}
                  modalRemisionIsOpen={props.modalRemisionIsOpen}
                  modalPedidosIsOpen={props.modalPedidosIsOpen}
                  openModalPedidos={props.openModalPedidos}
                  closeModalRemision={props.closeModalRemision}
                  closeModalPedidos={props.closeModalPedidos}
                  modalArticulosIsOpen={props.modalArticulosIsOpen}
                  openModalArticulos={props.openModalArticulos}
                  closeModalArticulos={props.closeModalArticulos}
                />
              )}
            </div>
          </Col>
        </Row>
      </Row>
      <div className="hidden">
        <Toast
          options={{
            html: `Se agrego el articulo al pedido !`,
            classes: 'message',
          }}
        >
          articulo
        </Toast>
        <Toast
          options={{
            html: `Se actualizo cantidad de articulo en el pedido !`,
            classes: 'message',
          }}
        >
          mod_articulo
        </Toast>
        <Toast
          options={{
            html: `El articulo no se encuentra en existencia !`,
            classes: 'message-error',
          }}
        >
          existencia
        </Toast>
        <Toast
          options={{
            html: `No hay inventario suficiente para tu pedido, favor de pasar al mostrador para completar tu pedido !`,
            classes: 'message-error',
          }}
        >
          stock
        </Toast>
        <Toast
          options={{
            html: `No hay inventario suficiente para tu pedido !`,
            classes: 'message-error',
          }}
        >
          stock_articulo
        </Toast>
        <Toast
          options={{
            displayLength: 20000,
            html: `Se generó correctamente el pedido con número: ${props.numeroPedido} !`,
            classes: 'message',
          }}
        >
          pedido
        </Toast>
        <Toast
          options={{
            html: `Se agrego el envio al pedido !`,
            classes: 'message',
          }}
        >
          envio
        </Toast>
        <Toast
          options={{
            html: `Ocurrio un error, intentalo nuevamente !`,
            classes: 'message-error',
          }}
        >
          error
        </Toast>
      </div>
    </div>
  );
  
};

export { AppView as default };
